import { apiUrl } from '../../constant';
import { BulkUploadModulesService, GenericBulkUploadService, GetBuklUploadCsvFileMasterDetailsService } from '../../services/uploads.service'
import { uploadConstant } from '../constants/uploads.constant'
import axios from "axios";

export const getBulkUploadModules = () => {
    return (dispatch) => {
        BulkUploadModulesService().then((result) => {
            dispatch(getBulkUploadModulesSuccess(result.data.EntityList))
        }).catch((error) => {
            dispatch(getBulkUploadModulesSuccess([]));
        })
    }
}

export const getBulkUploadModulesSuccess = response => ({
    type: uploadConstant.GET_BULK_UPLOAD_MODULES,
    payload: response
});

export const genericBulkUpload = (data) => {
    return () => {
        return GenericBulkUploadService(data);
    };
};

export const getBuklUploadCsvFileMasterDetails = (data) => {
    return (dispatch) => {
        dispatch(getBuklUploadCsvFileMasterDetailsPending());
        GetBuklUploadCsvFileMasterDetailsService(data).then((result) => {
            dispatch(getBuklUploadCsvFileMasterDetailsSuccess(result.data))
        }).catch((error) => {
            dispatch(getBuklUploadCsvFileMasterDetailsFailed());
        })
    }
}

export const getBuklUploadCsvFileMasterDetailsSuccess = (response) => ({
    type: uploadConstant.GET_BULK_UPLOAD_CSV_FILE_MASTER_DETAILS_SUCCESS,
    payload: response
});

export const getBuklUploadCsvFileMasterDetailsFailed = () => ({
    type: uploadConstant.GET_BULK_UPLOAD_CSV_FILE_MASTER_DETAILS_FAILED,
});

export const getBuklUploadCsvFileMasterDetailsPending = () => ({
    type: uploadConstant.GET_BULK_UPLOAD_CSV_FILE_MASTER_DETAILS_PENDING,
});

export const bulkUploadSampleTemplate = (payload) => {
    return axios.get(`${apiUrl}/BulkUploadSampleTemplate?integrationId=${payload?.integrationId}&integrationName=${payload?.integrationName}`);
};