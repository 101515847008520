import axios from "axios";
import { marketPlaceUrl } from "../../constant";
import { batchConstant } from "../constants/batch.constant";
import { fetchAllMarketPlacesService } from "../../services/inventory.service";
import { InventoryConstant } from "../constants/inventory.constant";
import { getSelectList } from "../../config/utils";

export const marketPlaceListing = (companySellerId) => {
    return axios.get(
        `${marketPlaceUrl}/api/marketplaces?company_seller_id=${companySellerId}`,
        {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        }
    );
};

export const getLoginUrl = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(axios.post(`${marketPlaceUrl}/api/login_url`, data));
    });
};

export const validateAuthCode = (data) => {
    return new Promise((res, rej) => {
        return res(axios.post(`${marketPlaceUrl}/api/auth_url`, data));
    });
};

export const loadOrders = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(axios.post(`${marketPlaceUrl}/api/load_order`, data));
    });
};

export const marketPlaceIntegrationListing = (data) => {
    return axios.post(`${marketPlaceUrl}/api/integration_list`, data);
};

export const deleteIntegration = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(
            axios.post(`${marketPlaceUrl}/api/integration_update`, data)
        );
    });
};


export const getMarketplaceCountriesList = () => {
    return axios.post(`${marketPlaceUrl}/api/get_amazon_marketplace_list_dev`);
};

export const getTrackingDetails = (data) => {
    return axios.post(`${marketPlaceUrl}/api/roms-get-tracking-details_dev`, data);
};

const getFilter = (response) => ({
    type: batchConstant.GET_FILTER_STATUS,
    payload: response,
});

const marketPlaceIntegrationListingPending = () => ({
    type: batchConstant.GET_INTEGRATION_LIST_PENDING,
});

export const filterMarketPlace = (data) => {
    return (dispatch) => {
        dispatch(marketPlaceIntegrationListingPending());
        marketPlaceIntegrationListing(data).then((result) => {
            if (result.data) {
                dispatch(getFilter(result.data));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
};

export const marketPlaceBackDetails = (data) => ({
    type: batchConstant.MARKET_PLACE_BACK_DETAILS,
    payload: data,
});

const fetchAllMarketPlacesSuccess = (response) => ({
    type: InventoryConstant.FETCH_ALL_MARKETPLACES,
    payload: response,
});

export const fetchAllMarketPlaces = (data) => {
    return (dispatch) => {
        fetchAllMarketPlacesService(data).then((result) => {
            if (result.data) {
                dispatch(fetchAllMarketPlacesSuccess(getSelectList(result.data.EntityList, 'id', 'name')));
            }
        }).catch((err) => {
            console.log(err)
        });
    };
};