import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiUrl, outboundColumns } from "../../constant";
import "./createIntegration.css";
import { useDispatch, useSelector } from "react-redux";
import IntegrationTable from "./IntegrationTable";
import { integrationDataPayload, mergeColumns, saveModelObject, updateSelectedList } from "../../config/utils";
import { checkIntegrationName, getCsvColumnList, resetCsvColumnList } from "../../store/actions";
import LoaderSpinner from "../common/Loader";
import _ from "lodash";
import { Tooltip as WmsTooltip } from "react-tooltip";
import { getBulkUploadModules } from "../../store/actions/uploads.action";

export const CreateIntegration = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tableRows, setTableRows] = useState([]);
  const employeeAddress = useSelector(
    (state) => state?.shipment?.employeeAddress
  );
  //State to store the values
  const [dropDownValue, setDropDownValue] = useState([]);
  const [saveModel, setSaveModel] = useState(JSON.parse(JSON.stringify(saveModelObject)));
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [csvColumnListData, setCsvColumnListData] = useState([]);
  const createdBy = useSelector((state) => state.auth.user);
  const csvColumnList = useSelector((state) => state.batchReducer.csvColumnList);
  const csvColumnListStatus = useSelector((state) => state.batchReducer.csvColumnListStatus);
  const bulkUploadModulesList = useSelector((state) => state?.uploadsReducer?.bulkUploadModulesList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (csvColumnList && csvColumnList.length) {
      setCsvColumnListData(csvColumnList);
    }
  }, [csvColumnList])

  useEffect(() => {
    if (!bulkUploadModulesList.length) {
      dispatch(getBulkUploadModules());
    }
  }, []);

  useEffect(() => {
    if (saveModel.bulkUploadModuleId > 0) {
      dispatch(getCsvColumnList(saveModel.bulkUploadModuleId));
    }
  }, [saveModel.bulkUploadModuleId]);

  useEffect(() => {
    return () => {
      dispatch(resetCsvColumnList([]));
    };
  }, []);

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const itemArray = [];
        const itemArray1 = [];
        itemArray1.push({ name: "--Please Select--", isDisabled: false, mapId: 0 });
        rowsArray.push(Object.values(results.meta.fields));
        for (let i = 0; i < rowsArray[0].length; i++) {
          itemArray.push({ name: rowsArray[0][i] });
          itemArray1.push({ name: rowsArray[0][i], isDisabled: false, mapId: i + 1 });
        }
        setDropDownValue(itemArray);
        setTableRows(itemArray1);
        let backUpSaveModel = { ...saveModel };
        backUpSaveModel.csvuploadcolumnsid = 0;
        setSaveModel(backUpSaveModel);
        const clearedSaveModel = { ...saveModel };
        clearedSaveModel.csvintegrationcolumnsmapping.forEach((item) => {
          for (const key in item) {
            item[key] = ""; // Clearing all values
          }
        });
        setSaveModel(clearedSaveModel);
      },
    });
  };

  /**
   * 
   * @param {Number} integrationID 
   */
  const getCsvIntegrationById = (integrationID) => {
    let url = `${apiUrl}/api/GetCsvIntegrationById?id=${integrationID}`;
    axios.get(url).then((res) => {
      if (res.data.Entity != null) {
        const csvuploadcolumnsid = res.data.Entity.fileColumnsMaster_FileId;
        getupdateCsvIntegrationById(csvuploadcolumnsid, res.data);
      }
    });
  };

  useEffect(() => {
    const defaultSaveModel = { ...saveModel };
    defaultSaveModel.sellerName = employeeAddress?.name;
    setSaveModel(defaultSaveModel);
  }, [employeeAddress]);

  useEffect(() => {
    if (id) {
      if (parseInt(id.split('-')[2]) > 0) {
        getCsvIntegrationById(parseInt(id.split('-')[2]));
      }
    }
  }, [id, csvColumnList]);

  /**
   * 
   * @param {Number} csvuploadcolumnsid 
   * @param {Array} dataResponseValue 
   */

  const getupdateCsvIntegrationById = (
    csvuploadcolumnsid,
    dataResponseValue
  ) => {
    const itemArray1 = [];
    itemArray1.push({ name: "--Please Select--", isDisabled: false });
    const getValuesFromDB = [];
    let url = `${apiUrl}/api/CSVUploadIntegrationColumns?uniqueId=${csvuploadcolumnsid}`;
    axios.get(url).then((response) => {
      let selectedData = dataResponseValue.Entity.csvintegrationcolumnsmapping;
      let createData = updateSelectedList(csvColumnList, selectedData);
      setCsvColumnListData(createData);

      const result = Object.values(response.data.EntityList);
      // eslint-disable-next-line array-callback-return
      result.map((list) => {
        if (list.Name !== "--Please Select") {
          itemArray1.push({ name: list.Name, id: list.Id, mapId: list.Id });
          return list;
        }
      });

      let dataOjb = mergeColumns(dataResponseValue.Entity.csvintegrationcolumnsmappingbyname);
      for (let key in dataOjb[0]) {
        // Update the corresponding property in the 'obj' array
        if (saveModel.csvintegrationcolumnsmapping[0].hasOwnProperty(key.toLowerCase())) {
          saveModel.csvintegrationcolumnsmapping[0][key.toLowerCase()] = dataOjb[0][key];
        }
      }

      saveModel.csvintegrationcolumnsmapping[0].integrationid = dataResponseValue.Entity.id;
      dataResponseValue.Entity.csvintegrationcolumnsmapping = [...saveModel.csvintegrationcolumnsmapping];

      const dataReponse = Object.values(dataResponseValue.Entity);
      const resultKeys = Object.keys(dataResponseValue.Entity);
      const resultState = { ...saveModel };
      resultKeys.map((item, index) => {
        if (item != "csvintegrationcolumnsmapping") {
          resultState[item] = dataReponse[index];
          return item;
        } else if (item == "csvintegrationcolumnsmapping") {
          resultState.csvintegrationcolumnsmapping.map((itemj, indexj) => {
            Object.keys(resultState.csvintegrationcolumnsmapping[0]).map(
              (key) => {
                resultState.csvintegrationcolumnsmapping[0][key] =
                  dataReponse[12][0][key];
                let checkVal = dataReponse[12][0][key];
                if (
                  checkVal !== null &&
                  checkVal !== "--Please Select--" &&
                  checkVal !== 0 &&
                  checkVal !== "" &&
                  checkVal !== undefined
                ) {
                  getValuesFromDB.push(checkVal);
                }
                return key;
              }
            );
          });
        }
      });
      resultState.createdBy = createdBy?.Value?.employeeId;
      resultState.sellerId = createdBy?.Value?.sellerId;
      resultState.companyId = createdBy?.Value?.companyId;
      setSaveModel(resultState);
      // setCsvColumnListData(csvintegrationcolumnsmapping)
      itemArray1.forEach((element) => {
        if (getValuesFromDB.includes(element.name)) {
          element.isDisabled = true;
        }
      });
      setTableRows(itemArray1);
    });
  };

  useEffect(() => {
    if (saveModel.name) {
      setError(false);
    }
  }, [saveModel.name])

  /**
   * 
   * @param {HTMLEvent} e 
   * @returns 
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!saveModel.name) {
      setError(true);
      return null;
    }
    if (!error) {
      setLoader(true);
      // For new CSV integration 
      if (saveModel?.id === 0) {
        let arrayOfValue = [...dropDownValue];
        let response = await Promise.resolve(dispatch(checkIntegrationName(saveModel.name)));
        if (response?.data && response.data.Status === 200 && response?.data.Entity.isValid) { // Check dublicate CSV integration
          await axios
            .post(`${apiUrl}/api/AddUpdateCSVUploadIntegration`, arrayOfValue)
            .then((response) => {
              if (response.data.Entity != null) {
                let letSaveModel = { ...saveModel };
                letSaveModel.fileColumnsMaster_FileId =
                  response.data.Entity.fileid;
                letSaveModel.createdBy = createdBy?.Value?.employeeId;
                letSaveModel.modifiedBy = 0;
                letSaveModel.csvintegrationcolumnsmapping = integrationDataPayload(tableRows, csvColumnListData);
                axios
                  .post(`${apiUrl}/api/AddUpdateIntegration`, letSaveModel)
                  .then((rep) => {
                    if (rep.data.Status === 200) {
                      toast.success("Saved Successfully");
                      handleNaviation();
                    } else {
                      toast.error(rep.data?.Message?.AppStatusDescription);
                      // navigate("/createcsv");
                    }
                  });
              } else {
                toast.error(response.data?.Message?.AppStatusDescription)
              }
            });
        } else {
          toast.error(response?.data.Message.AppStatusCode);
        }
      } else {
        let letSaveModel = { ...saveModel };
        letSaveModel.createdBy = createdBy?.Value?.employeeId;
        if (dropDownValue.length) {
          let arrayOfValue = [...dropDownValue];
          let csvResponse = await axios.post(`${apiUrl}/api/AddUpdateCSVUploadIntegration`, arrayOfValue);
          if (csvResponse.data.Entity != null) {
            letSaveModel.fileColumnsMaster_FileId = csvResponse.data.Entity.fileid;
          }
        }
        letSaveModel.modifiedBy = 0;
        letSaveModel.csvintegrationcolumnsmapping = integrationDataPayload(tableRows, csvColumnListData);
        const rep = await axios.post(`${apiUrl}/api/AddUpdateIntegration`, letSaveModel);
        if (rep?.data.Message.AppStatusCode === "RC_RSF") {
          toast.success(rep?.data.Message.AppStatusDescription);
          handleNaviation();
        } else {
          toast.error(rep?.data.Message.AppStatusDescription);
        }
      }
      setLoader(false);
    }
  }

  /**
   * 
   * @param {HTML event} e 
   * @param {Number} index 
   */
  const handleChangeSave = (e, index) => {
    const fieldName = e.target.name;
    let csvColumnListClone = [...csvColumnListData];
    const value =
      e.target.type === "checkbox"
        ? e.target.checked
        : e.target.value === "--Please Select--"
          ? ""
          : e.target.value;
    let saveModelState = { ...saveModel };
    if (e.target.name === "csvintegrationcolumnsmapping") {
      let splitName = csvColumnListClone[index].Name || "";
      let insideObject = saveModelState.csvintegrationcolumnsmapping;
      insideObject[0][splitName] = e.target.value === "--Please Select--" ? "" : e.target.value;
      csvColumnListClone[index].mapId = Number(e.target.value);
      setCsvColumnListData(csvColumnListClone);
      setSaveModel(saveModelState);
    } else {
      if (e.target.name === "isincludepackageslipinemail") {
        saveModelState[fieldName] = value;
        if (value === false) {
          saveModelState["includepackageslipinemaildesc"] = "";
        }
      } else {
        saveModelState[fieldName] = value;
      }
      setSaveModel(saveModelState);
    }
  };

  const handleNaviation = () => {
    let url = location?.state?.url;
    if (url) {
      navigate(url);
    } else {
      navigate("/createcsv")
    }
  };

  /**
   * @param {HTML Event} event 
   */
  const handleChangeModule = (event) => {
    const { value } = event.target;
    setSaveModel({ ...saveModel, bulkUploadModuleId: Number(value) });
    dispatch(getCsvColumnList(value));
  };

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          {id === '0' ? "Create Integration" : "Edit Integration"}
          <div className="top_right_button_container">
            <button
              onClick={handleSubmit}
              className="btn cus-seconday-bg-btn top-btn handleSubmitbtn"
              disabled={
                Object.keys(_.pickBy(saveModel.csvintegrationcolumnsmapping[0]))
                  .length === 0 || loader
              }
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn cus-primary-transparent-btn mr-10"
              onClick={handleNaviation}
            >
              Back
            </button>
          </div>
        </div>
        <div className="main_content">
          <div className="rowIntegration row">
            <div className="columnGeneral">
              <Col>
                <Card>
                  <Card.Title className="headingCsv">
                    General Settings
                  </Card.Title>
                  <div className="row fieldAndLabel csvInte fontFix">
                    <div className="col-12 mb-3">
                      <p className="mb-1">Module <span className='require-field'>*</span></p>
                      <select
                        name="module"
                        value={saveModel.bulkUploadModuleId}
                        className="form-control"
                        onChange={handleChangeModule}
                        disabled={id > 0}
                      >
                        <option value={0}>Select Module</option>
                        {bulkUploadModulesList.map((item) => (
                          <option key={item.id} value={item.id}>{item.moduleName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 mb-3">
                      <p className="mb-1">Integration Name <span className='require-field'>*</span></p>
                      <input
                        required
                        label="Required Field"
                        error={!!error}
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={handleChangeSave}
                        value={saveModel.name}
                      />
                      <div className="errorMessage">
                        {error && "This is required"}
                      </div>
                    </div>
                    <div className="col-12">
                      <p className="mb-2">Creator Name</p>
                      <input
                        type="text"
                        name="sellerName"
                        className="form-control"
                        onChange={handleChangeSave}
                        value={saveModel.sellerName}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row fieldAndLabel">
                    <div className="col col-12">
                      <input
                        type="checkbox"
                        checked={saveModel.isactiveintegration}
                        name="isactiveintegration"
                        onChange={handleChangeSave}
                        value={saveModel.isactiveintegration}
                      />
                      <text className="otext"> Active </text>
                    </div>
                  </div>

                  <hr className="hr"></hr>
                  <Card.Title className="headingCsv">CSV Settings</Card.Title>
                  <div className="mb-15">
                    <div>Header Row</div>
                    <input
                      type="checkbox"
                      checked={saveModel.isheaderrowtoimport}
                      name="isheaderrowtoimport"
                      onChange={handleChangeSave}
                      value={saveModel.isheaderrowtoimport}
                      className="checkBoxField"
                    />
                    <text className="atext">Skip Header row on import</text>
                  </div>

                  <div className="mb-15">
                    <div>Blank Order ID</div>
                    <input
                      type="checkbox"
                      checked={saveModel.isautgenerateorderid}
                      name="isautgenerateorderid"
                      onChange={handleChangeSave}
                      value={saveModel.isautgenerateorderid}
                      className="checkBoxField"
                    />
                    <text className="atext">
                      Auto generate unique order ID if not provided
                    </text>
                  </div>

                  <div className="mb-15 fontFix">
                    <div>Zone Calculator</div>
                    <input
                      type="checkbox"
                      checked={saveModel.iszonecalculator}
                      name="iszonecalculator"
                      onChange={handleChangeSave}
                      value={saveModel.iszonecalculator}
                      className="checkBoxField"
                    />
                    <text className="atext">Enable Zone Calculation</text>

                    <i class="ml-2 fa fa-info-circle" aria-hidden="true" id="zoneCalculation"></i>
                    <WmsTooltip
                      anchorId={'zoneCalculation'}
                      content="In case of an unmentioned sender address, the system will calculate the Zone based on the default address set for the logged-in user."
                      place="top"
                      className="zoneCalculation"
                    />
                  </div>

                  <label
                    htmlFor="actual-btn"
                    className="btn cus-seconday-bg-btn"
                  >
                    <i class="fa fa-cloud-upload mr-2" aria-hidden="true"></i>{" "}
                    CSV Template
                  </label>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    id="actual-btn"
                    onChange={changeHandler}
                    hidden
                  />
                </Card>
              </Col>
            </div>
            <div className="columnMapping">
              <Col>
                <Card className="cards intcard">
                  <Card.Title className="headingCsv">
                    Column Mapping{" "}
                    {csvColumnListStatus === "pending" && <LoaderSpinner />}
                    {saveModel.bulkUploadModuleId > 0 && saveModel.bulkUploadModuleId && <p className="csvInfo">Highlighted columns are mandatory in CSV file.</p>}
                  </Card.Title>
                  <div>
                    <Row md={2} style={{ flexDirection: "row" }}>
                      <Col style={{ display: "flex", flexDirection: "column" }} className="mb-w16 col-6">
                        {csvColumnList?.length > 0 &&
                          csvColumnList.map((item) => (
                            <div className={`lborder rounded-0 ${outboundColumns.includes(item.Name) ? 'mandotoryCol' : 'test'}`} key={item.id}>
                              {item.Name}
                            </div>
                          ))}
                      </Col>
                      {csvColumnListStatus === "success" && (
                        <IntegrationTable
                          tableRows={tableRows}
                          csvColumnList={csvColumnListData}
                          handleChangeSave={handleChangeSave}
                          saveModel={saveModel}
                          setSaveModel={setSaveModel}
                        />
                      )}
                    </Row>
                  </div>
                </Card>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </main >
  );

}