import { Modal } from 'react-bootstrap';
import React from 'react';
import { useState } from 'react';
import { Button } from "bootstrap-4-react/lib/components";
import { isValidEmail } from '../../config/utils';
import { saveMailLabelForm } from '../../store/actions/history';
import { toast } from "react-toastify";

const DraftEmail = (props) => {
    const { modalClass, modalShow, setModalShow, selectedRecord, dispatch } = props;
    const [formData, setFormData] = useState({
        emails: selectedRecord.SenderEmail,
        subject: `Shipping Label #${selectedRecord?.OrderNumber}`,
        body: `You have generated a shipping label for the order: ${selectedRecord?.OrderNumber}. PFA the pdf.`
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const handleEmailFormChange = (e) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    }

    const handleFormValidations = () => {
        let isValid = true;
        let error = {};
        if (!formData.emails) {
            isValid = false;
            error.emails = 'Email is required';
        }
        if (formData.emails) {
            if (!formData.emails.match(isValidEmail)) {
                isValid = false;
                error.emails = 'Enter valid address';
            }
        }
        if (!formData.subject) {
            isValid = false;
            error.subject = 'Subject is required';
        }
        if (!formData.body) {
            isValid = false;
            error.body = 'Body is required';
        }
        setErrors(error);
        return isValid;
    };

    const saveEmailForm = async () => {
        if (handleFormValidations()) {
            let data = {
                "toEmail": formData.emails,
                "subject": formData.subject,
                "body": formData.body,
                "shipmentLabel": selectedRecord.ShipmentLabelName,
            }
            setIsLoading(true);
            let saveRes = await dispatch(saveMailLabelForm(data));
            if (saveRes && saveRes.data && saveRes.data.Status === 200) {
                toast.success(saveRes.data.Entity);
                setModalShow(false);
                setIsLoading(false);
            }
        };
    }

    return (
        <Modal
            className={modalClass || ''}
            size="lg"
            show={modalShow}
        >
            <Modal.Header>
                <div className="modal-main-div">
                    <div className="modal-m-text">Email Label</div>
                </div>
                <div className="modalDeleteWrapper">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setModalShow(false)}></button>
                </div>

            </Modal.Header>
            <Modal.Body>
                <div className='saveForm'>
                    <div className='emailForm'>
                        <div className='row'>
                            <div className='col-12'>
                                <label for="toEmail">To Email</label>
                                <input onChange={(e) => handleEmailFormChange(e)} name='emails' id="toEmail" type={'text'} value={formData.emails} className='form-control' />
                                <p className='error'>{errors.email}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <label for="subject">Subject</label>
                                <input onChange={(e) => handleEmailFormChange(e)} name='subject' id="subject" type={'text'} value={formData.subject} className='form-control' />
                                <p className='error'>{errors.subject}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <label for="body">Body</label>
                                <textarea onChange={(e) => handleEmailFormChange(e)} name='body' id="body" rows={8} value={formData.body} className='form-control emailBodyField' />
                                <p className='error'>{errors.body}</p>
                            </div>
                        </div>
                    </div>
                    <div className='formActionsEmailForm'>
                        <Button disabled={isLoading} className="cus-seconday-bg-btn" onClick={() => setModalShow(false)}>Cancel</Button>
                        <Button disabled={isLoading} id="saveEmailForm" className='cus-seconday-bg-btn' onClick={() => saveEmailForm()}>Send Email</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DraftEmail;