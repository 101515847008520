import React, { useEffect, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getDailyProductCountList, getProductYearsList, setProductSelectedYear } from '../../../store/actions/incoming';
import LoaderSpinner from '../../common/Loader';
import PackageCard from './../PackageCard';
import { Button } from "bootstrap-4-react/lib/components";
import { setLocalStorageItem } from '../../../services/StorageService';
import { checkPermission, currentYear } from '../../../config/utils';
import { featureSubmodulePermission, permssionCreate, products, returnsManagement } from '../../../constant';

const IncomingProducts = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showSearch, setShowSearch] = useState(false);
    const [searchTxt, setSearchTxt] = useState("");
    const userDetails = useSelector((state) => state.auth.user);
    const productYears = useSelector((state) => state.IncomingProductReducer?.productYears);
    const productDetails = useSelector((state) => state?.IncomingProductReducer?.productDetails);
    const productDetailsStatus = useSelector((state) => state?.IncomingProductReducer?.productDetailsStatus);
    const productYearsStatus = useSelector((state) => state?.IncomingProductReducer?.productYearsStatus);
    const selectedProductYear = useSelector((state) => state.IncomingReducer?.selectedProductYear);
    const productYearBackIndexDetails = useSelector((state) => state.IncomingReducer?.productYearBackIndexDetails);
    const [selectedyear, setSelectedyear] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch(getProductYearsList(userDetails?.Value.companySellerId, userDetails?.Value?.employeeId));
    }, [userDetails]);

    const handleBlur = (e) => {
        const { value } = e.target;
        setSearchTxt(value.trim());
    };
    const handleEnterClick = (e) => {
        if (e.key === "Enter") {
            handleSearchFilter(e, "enter");
        }
    };
    useEffect(() => {
        if (productYearsStatus === 'success' && selectedProductYear && productYears && productYears.length) {
            setSelectedyear(selectedProductYear);
        } else {
            if (productYears && productYears.length) {
                let year = productYears.includes(currentYear) ? currentYear : productYears[0]
                setSelectedyear(year);
                dispatch(getDailyProductCountList(year, userDetails?.Value.companySellerId, userDetails?.Value?.employeeId));
            } else if (productYearsStatus === 'success' && !productYears?.length) {
                setLoader(false);
            }
        }
    }, [productYears, productYearsStatus, selectedProductYear]);

    const handleSearchFilter = (e, type) => {
        if (type === "clear") {
            setSearchTxt("");
            return;
        }
        navigate(`/incoming-products/fd-${new Date().toISOString()}-3pl`, {
            state: {
                searchTxt,
            },
        });

    };
    useEffect(() => {
        if (productDetailsStatus !== 'success') {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [productDetailsStatus]);

    const handleCreateProduct = (e) => {
        navigate(`/incoming-product/create-product/${0}`,
            { state: { view: false, isCreated: true } })
    }

    const handleYearChange = (e) => {
        const { value } = e.target;
        setSelectedyear(value);
        setLocalStorageItem('incomingYear', value);
        dispatch(setProductSelectedYear(value));
        dispatch(getDailyProductCountList(value, userDetails?.Value.companySellerId, userDetails?.Value?.employeeId));
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    Products
                    <div className="top_right_button_container">
                        <div className='selectYear top-btn'>
                            {productYears && productYears.length > 0 &&
                                <Form.Select size="sm" className='form-control' value={selectedyear} onChange={handleYearChange}>
                                    {productYears && productYears.length && productYears.map((item, index) => (
                                        <option value={item} key={item}>{item}</option>
                                    ))}
                                </Form.Select>
                            }
                        </div>
                        <button disabled={productYears && !productYears?.length} className="btn cus-seconday-bg-btn top-btn" onClick={() => setShowSearch(!showSearch)}>
                            <i className="fa fa-search" aria-hidden="true"></i> Search
                        </button>
                        {checkPermission(returnsManagement, products, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                            <Button className="cus-seconday-bg-btn top-btn" onClick={() => handleCreateProduct()}>
                                <i className="fa fa-plus" aria-hidden="true"></i> Create Product
                            </Button>
                        )}
                    </div>
                </div>
                <div
                    className={
                        showSearch ? 'filterContainer show searchBox' : 'filterContainer searchBox'
                    }
                >
                    <div className="searchInputBox">
                        <input
                            value={searchTxt}
                            onBlur={handleBlur}
                            onKeyDown={handleEnterClick}
                            onChange={(e) => setSearchTxt(e.target.value)}
                            className="form-control"
                            placeholder="Search Item Name, Warehouse, SKU"
                        />
                    </div>
                    <button
                        className="btn cus-seconday-bg-btn marginLeft5 searchBtn"
                        onClick={(e) => handleSearchFilter(e, "search")}
                    >
                        <i className="fa fa-search" aria-hidden="true"></i> Search
                    </button>
                    <button
                        disabled={!searchTxt}
                        className="btn cus-primary-transparent-btn clearbtn"
                        onClick={(e) => handleSearchFilter(e, "clear")}
                    >
                        Clear Search
                    </button>
                </div>
                {loader && <LoaderSpinner />}
                <div className="main_content cus-page-content-sec">
                    {productYearsStatus === 'success' && !productYears?.length ? (
                        <p>No Record Found</p>
                    ) : (
                        <Accordion className='incomingAccordion' defaultActiveKey={[productYearBackIndexDetails || 0]}>
                            {productDetails?.map((item, index) => (
                                <Accordion.Item eventKey={index} key={item.month}>
                                    <Accordion.Header>
                                        {item.month}
                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='packages row'>
                                            {item.data.map((data, i) => (
                                                <div className='col-lg-3 col-md-6' key={data.date}>
                                                    <PackageCard type={"productList"} index={index} data={data} {...props} dispatch={dispatch} />
                                                </div>
                                            ))}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    )}
                </div>
            </div>
        </main>
    );
}

export default IncomingProducts;