import React from 'react';

const BarcodeBtn = (props) => {
    const { handleOpenScan, fieldName } = props;
    return (
        <>
            <div className='mobDevices'>
                <button className='btn qrBtn' onClick={(e) => handleOpenScan(e, fieldName)}>
                    <i className="fa fa-camera" aria-hidden="true"></i>
                </button>
            </div>
        </>
    );
}

export default BarcodeBtn;