import axios from "axios";
import { shipmentService } from "../../services";
import { shipmentConstant } from "../constants";
import { apiUrl } from "../../constant";

export const getEmployeeAddressById = (id, addressType) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getEmployeeAddressById(id, addressType).then(
                (result) => {
                    let empAddress = result.data.Entity;
                    dispatch(getEmployeeAddressByIdSuccess(empAddress));
                    return resolve(empAddress);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const getSellerAddressById = (sellerId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getSellerAddressByIdService(sellerId).then(
                (result) => {
                    let empAddress = result.data.Entity;
                    dispatch(getEmployeeAddressByIdSuccess(empAddress));
                    return resolve(empAddress);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const resetServices = () => {
    return {
        type: shipmentConstant.RESET_CARRIERS,
    };
};

export const getAllServices = (isInternational, isMilitary) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getAllServices(isInternational, isMilitary).then(
                (result) => {
                    let services = result.data.EntityList;
                    dispatch(getAllServicesSuccess(services));
                    return resolve(services);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const getAllPackagesByServiceId = (serviceId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getAllPackagesByServiceId(serviceId).then(
                (result) => {
                    let services = result.data.EntityList;
                    dispatch(getAllPackagesByServiceIdSuccess(services));
                    return resolve(services);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const getAllCountries = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getAllCountries().then(
                (result) => {
                    let countries = result.data.EntityList;
                    dispatch(getAllCountriesSuccess(countries));
                    return resolve(countries);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const createShipment = (shipment) => {
    return () => {
        return shipmentService.createShipment(shipment);
    };
};

export const getEmployeeAddressByIdSuccess = (response) => ({
    type: shipmentConstant.GET_EMPLOYEE_ADDRESS_BY_ID_SUCCESS,
    payload: response,
});

export const getAllServicesSuccess = (response) => ({
    type: shipmentConstant.GET_ALL_SERVICES_SUCCESS,
    payload: response,
});

export const getAllPackagesByServiceIdSuccess = (response) => ({
    type: shipmentConstant.GET_ALL_PACKAGES_BY_SERVICE_ID_SUCCESS,
    payload: response,
});

export const getAllCountriesSuccess = (response) => ({
    type: shipmentConstant.GET_ALL_COUNTRIES_SUCCESS,
    payload: response,
});

export const editShipment = (response) => ({
    type: shipmentConstant.EDIT_SHIPMENT_SUCCESS,
    payload: response,
});

export const getStatesSuccess = (response) => ({
    type: shipmentConstant.GET_STATES_SUCCESS,
    payload: response,
});

export const getStates = (countryId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getAllUnitesStates(countryId).then(
                (result) => {
                    let states = result.data.EntityList;
                    dispatch(getStatesSuccess(states));
                    return resolve(states);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const validateAddress = (data) => {
    return () => {
        return shipmentService.postValidateAddress(data);
    };
};

export const getActiveCSVList = (response) => ({
    type: shipmentConstant.GET_ACTIVE_CSV_LIST,
    payload: response,
});

export const getCsvActiveIntegration = (createdBy) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.csvActiveIntegration(createdBy).then(
                (result) => {
                    let list = result.data.EntityList;
                    dispatch(getActiveCSVList(list));
                    return resolve(list);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const uploadCsv = (data) => {
    return () => {
        return shipmentService.uploadCsvFile(data);
    };
};

export const updateSkipDuplicate = (data) => {
    return () => {
        return shipmentService.updateSkipDuplicateService(data);
    };
};

export const deleteOrder = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(shipmentService.deleteOrderService(data));
    });
};

export const deleteFileMaster = (orderId, data) => {
    return () => {
        return shipmentService.deleteFileMasterService(orderId, data);
    };
};
export const deleteIntegration = (orderId, data) => {
    return () => {
        return shipmentService.deleteIntegrationService(orderId, data);
    };
};

const loadShipmentsAction = (response) => ({
    type: shipmentConstant.GET_SHIPMENT_LIST,
    payload: response,
});

export const loadShipments = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getShipmentsService(data).then(
                (result) => {
                    let list = result.data.EntityList;
                    dispatch(loadShipmentsAction(list));
                    return resolve(list);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const loadShipmentsBatch = (data) => {
    return () => {
        return shipmentService.getShipmentsService(data);
    };
};

export const saveShipmentDraft = (data) => {
    return () => {
        return shipmentService.saveShipmentsService(data);
    };
};

export const updateAddress = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(shipmentService.updateAddressService(data));
    });
};

export const updateBulkOrder = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(shipmentService.updateBulkOrderService(data));
    });
};

export const getShipmentById = (data) => {
    return axios.get(`${apiUrl}/api/Shipment/LoadShipmentById?${data}`);
};

export const getZipZonerates = (data, userId) => {
    return () => {
        return shipmentService.getZipZoneratesService(data, userId);
    };
};

export const getZipZoneRatesByServiceType = (data) => {
    return () => {
        return shipmentService.getZipZoneRateByServiceTypeService(data);
    };
};

export const deleteShipment = (data) => {
    return () => {
        return shipmentService.deleteShipmentService(data);
    };
};

export const getAllCarrierSuccess = (response) => ({
    type: shipmentConstant.GET_ALL_CARRIER_SUCCESS,
    payload: response,
});

export const getAllCarrierServices = (sortData) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.fetchAllCarriers(sortData).then(
                (result) => {
                    let services = result.data.EntityList;
                    dispatch(getAllCarrierSuccess(services));
                    return resolve(services);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

/** Fetch services */

export const getAllCarrierServicesSuccess = (response) => ({
    type: shipmentConstant.GET_ALL_CARRIER_SERVICES_SUCCESS,
    payload: response,
});

export const getAllCarrierServicesList = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.fetchAllCarrierServices().then(
                (result) => {
                    let services = result.data.EntityList;
                    dispatch(getAllCarrierServicesSuccess(services));
                    return resolve(services);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const updateCarrierStatus = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(shipmentService.updateCarrierStatusService(data));
    });
};

export const getAllSellerCarriersSuccess = (response) => ({
    type: shipmentConstant.GET_ALL_SELLER_CARRIER_SUCCESS,
    payload: response,
});

export const getAllSellerCarriersPending = () => ({
    type: shipmentConstant.GET_ALL_SELLER_CARRIER_PENDING,
});

export const getAllSellerCarriersFailed = () => ({
    type: shipmentConstant.GET_ALL_SELLER_CARRIER_FAILED,
});

export const getAllSellerCarriers = (sortData) => {
    return (dispatch) => {
        dispatch(getAllSellerCarriersPending());
        shipmentService.getSellerCarrierListService(sortData).then((result) => {
            dispatch(getAllSellerCarriersSuccess(result.data));
            }).catch(() => {
            dispatch(getAllSellerCarriersFailed());
        });
    };
};

//get sellers

export const getSellerByCompanySuccess = (response) => ({
    type: shipmentConstant.GET_ALL_COMPANY_SELLER_SUCCESS,
    payload: response,
});

export const getSellerCarriersForCompany = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getSellerCarriersForCompanyService(data).then(
                (result) => {
                    let carriers = result.data.EntityList;
                    dispatch(getSellerByCompanySuccess(carriers));
                    return resolve(carriers);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const updateSellerCarriers = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(shipmentService.updateSellerCarriersService(data));
    });
};

export const fetchAddressFromZipCode = (data) => {
    return () => {
        return shipmentService.getAddressFromZipCodeService(data);
    };
};

export const resetSellerByCompany = (response) => ({
    type: shipmentConstant.RESET_COMPANY_SELLER_LIST,
    payload: response,
});

export const getOrderByIntegrationId = (data) => {
    return () => {
        return shipmentService.getOrderByIntegrationIdService(data);
    };
};

export const getPendingOrders = (data) => {
    return () => {
        return shipmentService.getPendingOrdersService(data);
    };
};

export const signatureListSuccess = (response) => ({
    type: shipmentConstant.GET_SIGNATURE_LIST,
    payload: response,
});

export const getSignatureOptionList = (serviceId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getSignatureListService(serviceId).then(
                (result) => {
                    let list = result.data.EntityList;
                    dispatch(signatureListSuccess(list));
                    return resolve(list);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const contentTypeListSuccess = (response) => ({
    type: shipmentConstant.GET_CONTENT_TYPE_LIST,
    payload: response,
});

export const getContentTypeListService = (carriercompanyid) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getContentTypeListService(carriercompanyid).then(
                (result) => {
                    let list = result.data.EntityList;
                    dispatch(contentTypeListSuccess(list));
                    return resolve(list);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    };
};

export const getDimensionUnitsSuccess = (response) => ({
    type: shipmentConstant.GET_DIMENSION_UNIT_LIST,
    payload: response,
});

export const getDimensionUnitList = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getDimensionUnitService().then((res) => {
                dispatch(getDimensionUnitsSuccess(res.data.EntityList));
                resolve(res);
            }).catch((err) => {
                reject(err);
                console.log(err);
            });
        })
    };
};

export const getWeightUnitsSuccess = (response) => ({
    type: shipmentConstant.GET_WEIGHT_UNIT_LIST,
    payload: response,
});

export const getWeightUnitList = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return shipmentService.getWeightUnitService().then((res) => {
                dispatch(getWeightUnitsSuccess(res.data.EntityList));
                resolve(res);
            }).catch((err) => {
                reject(err);
                console.log(err);
            });
        })
    };
};
export const csvUploadListBackDetails = (data) => ({
    type: shipmentConstant.CSV_UPLOAD_LIST_BACK_DETAILS,
    payload: data,
})