import React from 'react';
import Table from "react-bootstrap/Table";
import { renderTableRow } from '../../config/utils';
import ActionItems from './showActions';
import moment from 'moment';

const DataTable = (props) => {
    const {
        loader,
        headers,
        tableData,
        tableDataStatus,
        filterData,
        handleSortingColumn,
        actionType,
        handleAction,
        handleUploadDialog
    } = props;

    const handleStatusClass = (item) => {
        return item.Status === "Error" ? "errorStatus" : "activeStatus"
    }

    return (
        <div className='incomingTbl'>
            <Table
                className={`table-height cus-left-right-col-layout ${loader ? "disableTble" : ""}`}
            >
                <thead className="tbl-btch">
                    <tr className="table-rw-batch">
                        {headers.map((item, index) => (
                            <th key={item.key} width={item?.width}>
                                {item.isSorting ? (
                                    <div
                                        className={
                                            filterData?.sortColumn === item.key
                                                ? "active"
                                                : "sortColumn"
                                        }
                                        onClick={() => handleSortingColumn(item.key)}
                                    >
                                        {item.label}
                                        <i
                                            className={
                                                filterData?.sortColumn === item.key &&
                                                    filterData?.sortOrder === "ASCENDING"
                                                    ? "fa fa-arrow-up"
                                                    : "fa fa-arrow-down"
                                            }
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                ) : (
                                    <>{item.label}</>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="tbl-bt-body">
                    {tableData && tableData.map((item, listIndex) => (
                        <tr key={item.listIndex}>
                            {headers.map((column) => {
                                let cellContent;

                                if (column.key === "Status") {
                                    cellContent = (
                                        <>
                                            <label
                                                className={handleStatusClass(item)}
                                            >
                                                {item.Status}
                                            </label>
                                            {item && item.Status === "Downloaded" ? (
                                                <a
                                                    rel="noreferrer"
                                                    href={item.DownloadCsvURL}
                                                >
                                                    <i
                                                        className="fa fa-cloud-download fa-lg"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            ) : null}
                                        </>
                                    );
                                } else if (column.isDate) {
                                    cellContent = <>
                                        {moment(item[column.key]).format(
                                            "MM-DD-YYYY | h:mm:ss A"
                                        )}</>;
                                } else if (column.key) {
                                    cellContent = <>{item[column.key]}</>;
                                } else {
                                    if (actionType === 2) {
                                        cellContent = (
                                            <>
                                                <ActionItems type={'View'} item={item} handleAction={() => handleAction(item, 'view', false)} />
                                                <ActionItems type={'Edit'} item={item} handleAction={() => handleAction(item, 'edit', false)} />
                                                <ActionItems type={'History'} item={item} handleAction={() => handleAction(item, 'history', false)} />
                                            </>
                                        );
                                    } else if (actionType === 4) {
                                        cellContent = (
                                            <i className="fa fa-refresh link" aria-hidden="true" onClick={handleUploadDialog}></i>
                                        );
                                    }
                                }

                                return <td key={column.id}>{cellContent}</td>;
                            })}
                        </tr>
                    ))}
                    {tableDataStatus === "SUCCESS" &&
                        tableData.length === 0 && (
                            <>{renderTableRow(headers.length, "No Result Found...")}</>
                        )}
                </tbody>
            </Table>
        </div>
    );
}

export default DataTable;
