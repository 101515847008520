import axios from "axios";
import { apiUrl } from "../constant";

export const BulkUploadModulesService = () => {
    return axios.get(`${apiUrl}/BulkUploadModules`);
};

export const GenericBulkUploadService = (payload) => {
    return axios.post(`${apiUrl}/GenericBulkUpload`, payload);
};

export const GetBuklUploadCsvFileMasterDetailsService = (data) => {
    return axios.post(`${apiUrl}/GetBulkUploadCsvFileMasterDetails`, data);
};