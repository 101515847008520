import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getAllCountries, getStates } from "../../store/actions";

const FilterCompany = (props) => {
  const dispatch = useDispatch();
  const [countryList, setcountryList] = useState();
  const [stateList, setstateList] = useState();
  const countries = useSelector((state) => state.shipment.countries);
  const USSates = useSelector((state) => state.shipment.states);
  const [formData, setFormData] = useState({ country: props.filterData.country, state: props.filterData.state });

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getStates(218));
  }, []);

  const TopUpForm = (item, name) => {
    if (name === 'country') {
      setFormData({ ...formData, state: [], [name]: item });
    } else {
      setFormData({ ...formData, [name]: item });
    }
  };

  useEffect(() => {
    if (countries && countries?.length) {
      let data = countries.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setcountryList(data);
    } else {
      setcountryList([]);
    }
  }, [countries]);

  useEffect(() => {
    if (USSates && USSates?.length) {
      let data = USSates.map((item) => {
        return {
          label: item.StateName,
          value: item.StateCode,
        };
      });
      setstateList(data);
    } else {
      setstateList([]);
    }
  }, [USSates]);

  const handleApplyFilter = () => {
    const resp = {
      ...props.filterData,
      country: formData?.country?.value,
      state: formData.state
    };
    props?.setfilterData(resp);
    return resp
  };

  const handleReset = () => {
    setFormData({
      ...formData,
      country: "",
      state: '',
    });
  };

  return (
    <div className={props.filterData.showFilter ? 'filterContainer filterContainerZIndex show historyFilter zIndex2' : 'filterContainer historyFilter'}>
      <div className='row mb10'>
        <div className='col-sm-12 col-lg-3 mobMb-10'>
          <div className='row'>
            <div className='col-12'>
              <label className='labelHead'>Country</label>
            </div>
            <div className='col-12'>
              <Select
                closeMenuOnSelect={true}
                options={countryList}
                classNamePrefix="list"
                id='countryIdList'
                name="countryIdList"
                value={formData?.country}
                className="activeSellerListInput"
                onChange={(value) => TopUpForm(value, "country")}
              />
            </div>
          </div>
        </div>
        {formData && formData?.country && formData?.country?.value === 218 ? (
          <div className='col-sm-12 col-lg-3 mobMb-10'>
            <div className='row'>
              <div className='col-12'>
                <label className='labelHead'>State</label>
              </div>
              <div className='col-12'>
                <Select
                  isMulti
                  closeMenuOnSelect={true}
                  options={stateList}
                  classNamePrefix="list"
                  id='stateIdList'
                  className="activeSellerListInput"
                  name="stateIdList"
                  value={formData?.state}
                  onChange={(value) => TopUpForm(value, "state")}
                  placeholder="Select state"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='col-sm-12 col-lg-3 mobMb-10'>
            <div className='row'>
              <div className='col-12'>
                <label className='labelHead'>State</label>
              </div>
              <div className='col-12'>
                <input
                  onChange={(e) => TopUpForm(e.target.value, "state")}
                  className="form-control availableBalanceInput"
                  value={
                    typeof formData?.state === "object"
                      ? formData?.state.label
                      : formData?.state
                  }
                  placeholder="Enter state"
                  type="text"
                />
              </div>
            </div>
          </div>
        )}
        <div className='col-sm-12 col-lg-3 mobMb-10'>
          <div className='row'>
            <div className='col-12'>
              <label className='labelHead hidLbl'>1</label>
            </div>
            <div className='col-12 flexEnd'>
              <button
                className="btn cus-primary-transparent-btn top-btn"
                onClick={handleReset}
              >
                Clear Filter
              </button>
              <button
                className="cus-seconday-bg-btn btn filterBtn"
                onClick={handleApplyFilter}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterCompany;