import { Button } from "bootstrap-4-react/lib/components";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { getInboundDefaultValues } from "../../store/actions/inventory.action";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivePrintListAction,
  getBarCodeData,
  getBarcodeSize,
  handleAutoPrintAction,
  updateBarcodePrintingSize,
  getAllBarCodeType,
} from "../../store/actions/barcode.action";
import { downloadPDF, useTextToSpeech } from "../../config/utils";
import LoaderSpinner from "../common/Loader";
import { toast } from "react-toastify";
import { useRef } from 'react';

const BarCodeGeneration = () => {
  const barCodeRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setErrors] = useState({});
  const [pdfBase64, setPdfBase64] = useState("");
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    oldBarCodeValue: "",
    source: "",
    width: "",
    height: "",
    fileAction: 2,
    sellerCode: "",
  });
  const [typedata, setTypeData] = useState("");
  const { barcodeSizeList, barcodeTypeList } = useSelector(
    (state) => state.barcodeReducer
  );
  const { handleSpeak } = useTextToSpeech();
  const [isdefaultBarcodeValue, setIsdefaultBarcodeValue] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [barcodeTYpe, setBarcodeType] = useState([]);

  const fetchData = async () => {
    await getInboundDefaultValues().then((res) => {
      if (res.data.Status === 200) {
        setFormData({ ...formData, sellerCode: res.data.Entity.SellerCode });
      }
    });
    dispatch(getBarcodeSize({}));
    setIsDataLoaded(true);
  };
  useEffect(() => {
    fetchData();
    dispatch(getAllBarCodeType());
  }, []);

  useEffect(() => {
    setBarcodeType(barcodeTypeList);
  }, [barcodeTypeList]);

  useEffect(() => {
    if (isDataLoaded) {
      const selectedBarcodeTypeId = barcodeTypeList.find(
        (obj) => obj.id === barcodeSizeList.BarCodePrintType
      );
      setFormData({
        ...formData,
        width: barcodeSizeList.Width,
        height: barcodeSizeList.Height,
        fileAction: barcodeSizeList.FileAction,
        Id: barcodeSizeList.Id,
      });
      setTypeData(selectedBarcodeTypeId?.name);
    }
  }, [barcodeSizeList]);

  const validateAddress = () => {
    let isValid = true;
    let errorData = {};
    if (formData.oldBarCodeValue.trim() === "") {
      isValid = false;
      errorData.oldBarCodeValue = "This is required";
    }
    if (!formData.width) {
      isValid = false;
      errorData.width = "This is required";
    }
    if (!formData.height) {
      isValid = false;
      errorData.height = "This is required";
    }
    setErrors(errorData);
    return isValid;
  };

  const handleGenerate = async () => {
    if (validateAddress()) {
      setLoader(true);
      const selectedBarcodeType = barcodeTypeList.find(
        (obj) => obj.name === typedata
      );
      let fetchBarCodeRes = await Promise.resolve(
        dispatch(
          getBarCodeData({
            ...formData,
            oldBarCodeValue: formData.oldBarCodeValue.trim(),
            barCodeType: selectedBarcodeType.id,
          })
        )
      );
      setLoader(false);
      if (fetchBarCodeRes?.data?.Result?.Status === 200) {
        if (isdefaultBarcodeValue === true) {
          dispatch(
            updateBarcodePrintingSize({
              ...formData,
              Width: formData.width,
              Height: formData.height,
              FileAction: formData.fileAction,
              BarCodePrintType: selectedBarcodeType.id,
            })
          );
        }
        handleGenerateSuccess(fetchBarCodeRes?.data?.Result?.Entity);
      } else {
        setPdfBase64("");
        toast.error(
          fetchBarCodeRes?.data?.Result?.Message?.AppStatusDescription
        );
        handleSpeak(
          fetchBarCodeRes?.data?.Result?.Message?.AppStatusDescription
        );
      }
      barCodeRef.current.focus();
      setFormData({ ...formData, oldBarCodeValue: '' });
    } else {
      let errorMessage = "Validation failed";
      if (formData.oldBarCodeValue === "") errorMessage += " oldBarCodeValue";
      if (!formData.width) errorMessage += " width";
      if (!formData.height) errorMessage += " height";
      handleSpeak(errorMessage);
    }
  };

  const handleAutoPrint = (base64Pdf) => {
    getActivePrintListAction().then((res) => {
      let printerList = res?.data?.data;
      if (printerList?.length) {
        let findActive = printerList.filter((printer) => printer.isDefault);
        if (findActive.length) {
          let data = {
            PrinterName: findActive[0].printerName,
            FileBase64: base64Pdf,
            Height: Number(formData.height),
            Width: Number(formData.width),
          };
          handleAutoPrintAction(data).then((res) => {
            if (formData.fileAction === 2) {
              handleSpeak("Print successfully");
            } else {
              handleSpeak("Save and print Successfully");
            }
          });
        }
      } else {
        toast.error("Something went wrong")
      }
    });
  };

  const handleGenerateSuccess = (base64Pdf) => {
    if (formData.fileAction === 1) {
      downloadPDF(base64Pdf);
      setPdfBase64(base64Pdf);
      handleSpeak("Saved as pdf successfully");
    }
    if (formData.fileAction === 2) {
      handleAutoPrint(base64Pdf);
    }
    if (formData.fileAction === 3) {
      downloadPDF(base64Pdf);
      handleAutoPrint(base64Pdf);
    }
  };

  const handleChange = (e, type = "") => {
    let { value, name } = e.target;
    setFormData({ ...formData, [name]: type ? Number(value) || null : value });
    if (name === "height" || name === "width") {
      setTypeData("Custom");
    }
    setErrors({ ...error, [name]: "" });
  };

  const handleBlurInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trim() });
  };

  const handleEnterClick = (e) => {
    if (e.key === "Enter") {
      handleGenerate();
    }
  };

  const handleBarcodeWidthHeight = (e, type = "") => {
    let name = e.target.value;
    const selectedBarcodeType = barcodeTypeList.find(
      (type) => type.name === name
    );
    setTypeData(name);
    if (selectedBarcodeType) {
      setFormData({
        ...formData,
        width: selectedBarcodeType.width,
        height: selectedBarcodeType.height,
      });
    }
    setErrors({ ...error, [name]: "" });
  };

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec custitle">
          <div>Barcode Generation</div>
          <div className="top_right_button_container">
            <Button
              className="cus-seconday-bg-btn top-btn"
              id="savesr"
              onClick={handleGenerate}
              disabled={loader}
            >
              Generate
            </Button>
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/barcode");
              }}
              disabled={loader}
            >
              Back
            </Button>
          </div>
        </div>
        {loader && <LoaderSpinner />}
        <div className="main_content cus-page-content-sec incomingTbl">
          <div className="top_right_button_container cus-content-only-sec">
            <div className="incoming_wrapper addressDetailWrapper fontFix">
              <div className="row">
                <div className="col-lg-4 col-sm-12 col-md-12 mobMb-10">
                  <label>Seller Code</label>
                  <input
                    value={formData?.sellerCode}
                    className="form-control"
                    type="text"
                    disabled
                  />
                </div>

                <div className="col-lg-3 col-lg-4 mobMb-10">
                  <div className="row">
                    <div className="col-4">
                      <label>
                        Width <span className="error">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="width"
                        value={formData.width}
                        min={0}
                        onChange={(e) => handleChange(e, "num")}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                      <p className="error dialogError">{error.width}</p>
                    </div>
                    <div className="col-1 alignEnd alignCenter">X</div>
                    <div className="col-4">
                      <label>
                        Height <span className="error">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="height"
                        value={formData.height}
                        min={0}
                        onChange={(e) => handleChange(e, "num")}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                      <p className="error dialogError">{error.height}</p>
                    </div>
                    <div className="col-2 alignEnd">Inches</div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-md-12 mobMb-10">
                  <label>Type</label>
                  <select
                    className="selectInput form-control"
                    value={typedata}
                    onChange={(e) => handleBarcodeWidthHeight(e)}
                  >
                    {barcodeTYpe.map((option) => (
                      <option
                        value={option.name}
                        key={option.id}
                        disabled={option.isDisabled}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-4 col-sm-12 col-md-12 mobMb-10">
                  <fieldset
                    id="fileAction"
                    className="codeOptions codeOptions-mob-res"
                    onChange={(e) => handleChange(e, "num")}
                  >
                    <div className="col col-sm-12 col-md-12 mobMb-10">
                      <input
                        type="radio"
                        value={1}
                        checked={Number(formData.fileAction) === 1}
                        name="fileAction"
                        id="savePdf"
                      />{" "}
                      <label for="savePdf">Save as Pdf</label>
                    </div>
                    <div className="col col-sm-12 col-md-12 mobMb-10">
                      <input
                        type="radio"
                        value={2}
                        checked={Number(formData.fileAction) === 2}
                        name="fileAction"
                        id="print"
                      />{" "}
                      <label for="print">Print</label>
                    </div>
                    <div className="col col-sm-12 col-md-12 mobMb-10">

                      <input
                        type="radio"
                        value={3}
                        checked={Number(formData.fileAction) === 3}
                        name="fileAction"
                        id="savePrint"
                      />{" "}
                      <label for="savePrint">Save and Print</label>
                    </div>
                  </fieldset>
                  <div className="col col-sm-12 col-md-12 mobMb-10">
                    <input
                      type="checkbox"
                      checked={isdefaultBarcodeValue}
                      name="isdefaultBarcodeValue"
                      onChange={() => {
                        setIsdefaultBarcodeValue((prevState) => !prevState);
                      }}
                      value={isdefaultBarcodeValue}
                      className="checkBoxField"
                    />
                    <label for="setDefault">Set As Default Settings</label>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-md-12 mobMb-10">
                  <label>Source Code </label>
                  <input
                    value={formData.source}
                    onChange={handleChange}
                    className="form-control availableBalanceInput"
                    type="text"
                    name="source"
                  />
                </div>

                <div className="col-lg-4 col-sm-12 col-md-12 mobMb-10">
                  <label>
                    Old Barcode <span className="require-field">*</span>
                  </label>
                  <input
                    ref={barCodeRef}
                    value={formData.oldBarCodeValue}
                    onChange={handleChange}
                    className="form-control"
                    type="text"
                    name="oldBarCodeValue"
                    onBlur={handleBlurInput}
                    onKeyDown={handleEnterClick}
                  />
                  <p className="error dialogError">{error.oldBarCodeValue}</p>
                </div>
              </div>
              <div className="mt-5 alignCenter">
                {pdfBase64 && (
                  <iframe
                    src={`data:application/pdf;base64,${pdfBase64}#view=fit`}
                    height="475px"
                    width="100%"
                    title="PDF"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default BarCodeGeneration;
