export const phoneNum = '';
export const signatureList = [
    { value: 1, name: "No Signature required" },
    { value: 2, name: "Signature required" },
    { value: 3, name: "Adult Signature required" },
];

export const dangerousGoods = [
    { value: 1, name: "Primary Contained" },
    { value: 2, name: "Primary Packed" },
    { value: 3, name: "Primary" },
    { value: 4, name: "Secondary Contained" },
    { value: 5, name: "Secondary Packed" },
    { value: 6, name: "Secondary" },
    { value: 7, name: "ORMD" },
    { value: 8, name: "Limited Quantity" },
    { value: 9, name: "Lithium" },
];

export const validUsZip = new RegExp("^\\d{5}(-\\d{4})?$");
export const validateMultipleEmails = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{10,}$/;
export const passwordMinRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,15}$/;
export const SHIPVISION_AI_CONFIG = 'shipvisionai.com';

const currentUrl = window.location.href;
let findApiUrl;

if (currentUrl) {
    if (currentUrl.includes('wms-dev')) {
        findApiUrl = 'https://wms-api.compunnel.com';
    } else if (currentUrl.includes('wms-qa')) {
        findApiUrl = 'https://wmsqa-api.compunnel.com';
    } else if (currentUrl.includes('3plvision')) {
        findApiUrl = 'https://prod-api.3plvision.com';
    } else if (currentUrl.includes('dev.shipvisionai.com')) {
        findApiUrl = 'https://dev-api.shipvisionai.com';
    } else if (currentUrl.includes('qa.shipvisionai.com')) {
        findApiUrl = 'https://qa-api.shipvisionai.com';
    } else if (currentUrl.includes('uat.shipvisionai.com')) {
        findApiUrl = 'https://uat-api.shipvisionai.com';
    } else if (currentUrl.includes('portal.shipvisionai.com')) {
        findApiUrl = 'https://prod-api.shipvisionai.com';
    } else if (currentUrl.includes('wms-uat')) {
        findApiUrl = 'https://wmsuat-api.compunnel.com';
    } else {
        findApiUrl = 'https://wms-api.compunnel.com';
    }
} else {
    findApiUrl = 'https://wms-api.compunnel.com';
}

export const apiUrl = findApiUrl;

let marketPlaceEndPoint;

if (currentUrl) {
    if (currentUrl.includes('3plvision') || currentUrl.includes('shipvisionai')) {
        marketPlaceEndPoint = 'https://bcar2yflal.execute-api.us-east-1.amazonaws.com'
    } else {
        marketPlaceEndPoint = 'https://5u1phqappa.execute-api.us-east-1.amazonaws.com'
    }
}

export const marketPlaceUrl = marketPlaceEndPoint;

export const paymentUrl = currentUrl &&
    (currentUrl.includes('3plvision') || currentUrl.includes('shipvisionai') || currentUrl.includes('wms-uat')) ? 'https://accept.authorize.net/payment/payment'
    : 'https://test.authorize.net/payment/payment';

let sampleTemplateForRateCard;
if (currentUrl) {
    if (currentUrl.includes('wms-dev')) {
        sampleTemplateForRateCard = 'https://wms-api.compunnel.com/upload/SellerRateCardTemplate.csv';
    } else if (currentUrl.includes('wms-qa')) {
        sampleTemplateForRateCard = 'https://wmsqa-api.compunnel.com/upload/SellerRateCardTemplate.csv';
    } else if (currentUrl.includes('wms-uat')) {
        sampleTemplateForRateCard = 'https://wmsuat-api.compunnel.com/upload/SellerRateCardTemplate.csv';
    } else if (currentUrl.includes('3plvision')) {
        sampleTemplateForRateCard = 'https://prod-api.3plvision.com/upload/SellerRateCardTemplate.csv';
    } else if (currentUrl.includes('shipvisionai')) {
        sampleTemplateForRateCard = 'https://prod-api.3plvision.com/upload/SellerRateCardTemplate.csv';
    } else {
        sampleTemplateForRateCard = 'https://wms-api.compunnel.com/upload/SellerRateCardTemplate.csv';
    }
} else {
    sampleTemplateForRateCard = 'https://wms-api.compunnel.com/upload/SellerRateCardTemplate.csv';
}

export const sampleTemplateRateCard = sampleTemplateForRateCard;

export const unitList = [
    { value: "INCH", name: "in / lb / oz", id: 1 },
    { value: "CMS", name: "cm / kg / g", id: 2 },
];

export const numOfPage = [30, 50, 100, 200, 500, 1000, 2000];

export const allowedCSVExtension = ['csv'];
export const allowedPDFExtension = ['pdf'];
export const allowedExtensions = ['jpg', 'jpeg', 'png'];
export const allowedDocExtensions = ['xls', 'xlsx', 'csv', 'pdf', 'doc', 'jpg', 'jpeg', 'png', 'svg'];
export const videoallowedExtensions = ["mp4", "avi", "mov"]
export const validEmailRe = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const numOnly = /[^0-9]/g;
export const trackingNum = /^[a-zA-Z0-9 -]*$/;

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const zipCodeRegex = /^\d{5}(-\d{4})?$/;
export const alphabeticKeys = /^[0-9-]$/;
export const alphabeticKeysReg = /^[A-Za-z]$/;

export const oneOunce = 0.453592;

export const userRoles = {
    admin: 1,
    seller: 2,
    superAdmin: 3,
    warehouseManager: 5,
    warehouseWorker: 6,
};

export const numOfSellerToDisplay = 2;

export const commodityObj = {
    includeCommercial: false,
    invoiceType: 0,
    taxIdVat: '',
    packageMarks: '',
    senderTaxIdVat: '',
    issuerCountryId: 0,
    issuerCountry: '',
    commodityList: [
        { unitValue: null, quantity: null, weight: null, ounces: null, description: '', hts: '', countryOriginId: 0, countryOrigin: '' },
    ]
}

export const shipmentStatusList = [
    { name: 'Shipped', id: 2 },
    { name: 'Booked', id: 1 },
];

export const commonAddressObj = {
    "id": 0,
    "name": "",
    "company": "",
    "addressLine1": "",
    "addressLine2": "",
    "addressLine3": "",
    "city": "",
    "state": "",
    "zip": "",
    "countryId": 218,
    "phone": "+1",
    "isSender": true,
    "isReceiver": true,
    "companySellerId": 0,
    "userId": 0
}

export const consoleObject = {
    "id": 0,
    "consignmentNumber": "",
    "consignmentTypeId": 1,
    "trackingNumber": "",
    "subTrackingNumber": "",
    "length": '',
    "width": '',
    attachments: [],
    "height": '',
    "dimensionUnitId": 1,
    "weight": '',
    "weightUnitId": 1,
    "countryOfOrigin": "",
    "countryOfOriginId": 0,
    "carrierTypeId": "",
    "comments": "",
    "consignmentStatusId": 1,
};
export const outboundObject = {
    "consignmentId": 0,
    "outboundNumber": "",
    "consignmentNumber": "",
    "outboundStatusId": 1,
    "consignmentTypeId": 0,
    "quantity": 0,
    "subQuantity": 0,
    "subQuantityMessage": "",
    "tertiarySubQuantity": 0,
    "items": [],
    "carrierTypeId": 0,
    "serviceTypeId": 0,
    "packageTypeId": 0,
    "trackingNumber": "",
    "subTrackingNumber": "",
    "isGenerateLabel": true,
    "addressFirst": "",
    "addressSecond": "",
    "addressThird": "",
    "city": "",
    "state": "",
    "country_Id": 218,
    "zipCode": "",
    "phoneNumber": "+1",
    "company": "",
    "email": "",
    "comments": "",
    "shippingLabel": "",
    "carrierCompanyId": 0
}

export const filterPayload = {
    sortKey: '',
    sortOrder: '',
    pageNumber: '1',
    pageIndex: '1',
    pageSize: '30',
    min_order_quantity: '',
    max_order_quantity: '',
    date_min: '',
    date_max: '',
    integration_name_filter: '',
    type_filter: '',
    active_filter: '',
    action_type: '',
    CompanySellerId: '',
    integration_id: '',
    showFilter: false,
}

export const elementObj = {
    "attachments": [],
    "id": 0,
    "palleteId": 0,
    "boxId": 0,
    "itemId": 0,
    "externalReference": "",
    "name": "",
    "description": "",
    "quantity": '',
    "trackingNumber": null,
    "subTrackingNumber": null,
    "dimensionUnitId": 1,
    "weightUnitId": 1,
    "consignmentTypeId": 1,
    "comments": "",
    "productSKU": "",
    "shippingStatusId": 0,
    "expirationDate": "",
    "consignmentElements": [],
}

export const elementItemObj = {
    "attachments": [],
    "id": 0,
    "palleteId": 0,
    "boxId": 0,
    "itemId": 0,
    "externalReference": "",
    "name": "",
    "description": "",
    "quantity": '',
    "trackingNumber": null,
    "subTrackingNumber": null,
    "dimensionUnitId": 1,
    "weightUnitId": 1,
    "consignmentTypeId": 1,
    "comments": "",
    "productSKU": "",
    "shippingStatusId": 0,
    "expirationDate": "2024-02-03T04:20:04.373",
    "consignmentElements": [{
        consignmentElements: []
    }],
}

export const shippingStatusList = [
    { value: 1, name: "Stored At WareHouse" },
    { value: 2, name: "Shipped Out" },
];

export const shippingStatusListconn = [
    { value: 0, name: "Select Status" },
    { value: 1, name: "Stored At WareHouse" },
    { value: 2, name: "Shipped Out" },
];

export const connectionOption = [
    { value: 1, name: "Stored At WareHouse" },
    { value: 2, name: "Shipped Out" },
];

export const deleteIcon = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-trash"
        viewBox="0 0 16 16"
        color="red"
        cursor="pointer"
    >
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
        <path
            fill-rule="evenodd"
            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
        />
    </svg>
}

export const barcodeList = [
    {
        name: "Barcode Mapping",
        icon: "fa fa-file-code-o",
        color: "#0000ff",
        path: "/barcode/mappings",
        permissionFor: "barcodeMapping",
        subModule: 23
    },
    {
        name: "Barcode Upload",
        icon: "fa fa-code",
        color: "#4BBBD3",
        path: "/barcode/barcode-upload",
        permissionFor: "barcodeUpload",
        subModule: 24
    },
    {
        name: "Barcode Settings",
        icon: "fa fa-qrcode",
        color: "#D04545",
        path: "/barcode-settings",
        permissionFor: "barcodeSettings",
        subModule: 25,
    },
    {
        name: "New Barcode Generation",
        icon: "fa fa-file-excel-o",
        color: "#0000ff69",
        path: "/generate-barcode",
        permissionFor: "newBarcodeGeneration",
        subModule: 26,
    },
    {
        name: "Adhoc Barcode Generation",
        icon: "fa fa-file-code-o",
        color: "#0000ff",
        path: "/barcode/adhoc-barcode-generation",
        permissionFor: "adhocBarcodeGeneration",
        subModule: 27,
    },
];

export const RateCardColumns = [
    { label: 'Company Name', key: 'companyName' },
    { label: 'Seller Name', key: 'sellerName' },
    { label: 'Valid From', key: 'validFrom' },
    { label: 'Valid To', key: 'validTo' },
    { label: 'Actions', key: '' }
];

export const LedgerUnbilled = [
    { label: 'Service Type', key: 'serviceType', id: 1 },
    { label: 'Transaction Type', key: 'transactionType', id: 2 },
    { label: 'Status', key: 'status', id: 3 },
    { label: 'Date', key: 'date', id: 4 },
    { label: 'Amount', key: 'amount', id: 5 }
];

export const UploadListColumns = [
    { label: 'Name', key: 'IntegrationName', isSorting: true },
    { label: 'File Name', key: 'FileName', isSorting: true },
    { label: 'Order Submitted', key: 'OrderSubmitted', isSorting: true },
    { label: 'Orders Accepted', key: 'OrdersAccepted', isSorting: true },
    { label: 'Rows Submitted', key: 'RowsSubmitted', isSorting: true },
    { label: 'Status', key: 'Status', isSorting: false },
    { label: 'Date', key: 'UploadedDate', isSorting: true, isDate: true },
];

export const transactionType = [
    {
        label: "Credit",
        value: 4,
        permission: 'all'
    },
    {
        label: "Debit",
        value: 5,
        permission: 'all'
    },
    {
        label: "Credit - VIP Recharge",
        value: 6,
        permission: 'superAdmin'
    },
    {
        label: "Credit - Easy Post Recharge",
        value: 7,
        permission: 'superAdmin'
    },
];

export const outboundColumns = ['OutboundId(To_be_left_blank)', 'Row(To_be_left_blank)', 'Status(To_be_left_blank)']

/*Module */
export const orderManagement = 1;
export const addressBook = 2;
export const returnsManagement = 3;
export const accountsManagement = 4;
export const inventoryManagement = 5;
export const settings = 6;
export const barCode = 7;
export const FEATURE_WALLETBALANCEDISPLAY = 8;
export const FEATURE_UPLOADS = 10;

/* Sub modules */
export const shipmentModule = 1;
export const batchModule = 2;
export const historyModule = 3;
export const scanFormModule = 4;
export const addressModule = 5;
export const packages = 6;
export const products = 7;
export const SUBF_COMPANY = 8;
export const SUBF_SELLER = 9;
export const SUBF_USER = 10;
export const SUBF_LISTING = 11;
export const SUBF_INBOUND = 12;
export const SUBF_OUTBOUND = 13;

export const SUBF_CSVMAPPING = 14;
export const SUBF_FUNDMANAGEMENT = 15;
export const SUBF_RATECARD = 16;
export const SUBF_CSVUPLOAD = 17;
export const SUBF_APIKEYGENERATION = 18;
export const SUBF_SERVICECONFIGIURATION = 19;
export const SUBF_MARKETPLACEINTEGRATION = 20;
export const SUBF_MARGINCONFIGURATION = 21;
export const SUBF_CONFIGURATION = 22;

export const SUBF_BARCODEMAPPING = 23;
export const SUBF_BARCODEUPLOAD = 24;
export const SUBF_BARCODESETTINGS = 25;
export const SUBF_NEWBARCODEGENERATION = 26;
export const SUBF_ADHOCBARCODEGENERATION = 27;
export const SUBF_WALLETBALANCEDISPLAY = 28;
export const SUBF_USERSPERMISSIONS = 29;
export const SUBF_PRODUCTLISTING = 30;

/* Module level */
export const featureModule = 1; //for main module like orders management, Inventory Management
export const featureSubmodule = 2;
export const featureSubmodulePermission = 3;

/* Feature */
export const permssionCreate = 1;
export const permssionView = 2;
export const permssionEdit = 3;
export const permssionDelete = 4;
export const permssionBulkUpload = 5;
export const permssionWalletRecharge = 6;
export const permssionTopUp = 7;
export const permssionAdjustment = 8;
export const permssionViewHistory = 9;
export const permssionThreshold = 10;
export const permssionProcessingFee = 11;
export const permssionGenerate = 12;
export const permssionAPIKeyActive = 13;
export const baseRateCardView = 14;
export const baseRateCardEdit = 15;
export const baseRateCardAdd = 16;
export const sellerRateCardView = 17;
export const sellerRateCardEdit = 18;
export const sellerRateCardAdd = 19;
export const permissionCarrierView = 20;
export const permissionCarrierEdit = 21;
export const permissionSellerCarrierView = 22;
export const permissionSellerCarrierEdit = 23;
export const permissionSellerCarrierServicesView = 24;
export const permissionSellerCarrierServicesEdit = 25;
export const permissionCompanyAssociatedSeller = 27;
export const permissionAssociatedUser = 28;
export const permissionMarkedPickedOutbound = 29;
export const permissionPrintLabel = 30;
export const permissionCreateScanForm = 31;
export const permissionAssociatedWarehouse = 32;