import _ from 'lodash';
import React, { useState } from 'react';
import AsyncTypeAheadComponent from '../common/AsyncSearch';
import { searchProductBySku } from '../../store/actions/inventory.action';
import { getSelectList } from '../../config/utils';

const ItemsOutbound = (props) => {
    const {
        setShowProductListingBox,
        loader,
        formData,
        isEditable,
        handleBlurInput,
        weightUnitListing,
        dimensionUnitListing,
        setFormData,
        dispatch
    } = props;

    const [skuListoptions, setSkuListoptions] = useState([]);

    const handleRemoveItem = (e, index) => {
        let formdataObj = { ...formData };
        if (formdataObj.items[index].id !== 0) {
            formdataObj.items[index].isDeleted = true;
        } else {
            formdataObj.items.splice(index, 1);
        }
        setFormData(formdataObj);
    }

    const handleItemChange = (e) => {
        const { name, value } = e.target;
        let dataObj = _.cloneDeep(formData);
        dataObj.items[e.target?.getAttribute('data-index')][name] = value;
        dataObj.items[e.target?.getAttribute('data-index')].error = '';
        setFormData(dataObj);
    };

    const searchBySku = async (value, index) => {
        let data = {
            "pageIndex": 1,
            "pageSize": 30,
            "sortColumn": "",
            "sortOrder": "DESCENDING",
            "searchKeyword": value,
            "sellerIdList": [],
            "companyIdList": []
        }
        let response = await dispatch(searchProductBySku(data));
        if (response?.data?.Status === 200) {
            let formList = [...formData.items];
            let searchList = [...response.data.Entity.listInventoryEntity] || [];
            const result = searchList.filter(c => !formList.find(s => s.productSku === c.productSku));
            setSkuListoptions(getSelectList(result, 'productSku', 'id'));
        }
    }

    const handleSelect = (item, index) => {
        let formDataClone = _.cloneDeep(formData);
        formDataClone.items[index].productSKU = item.productSku;
        formDataClone.items[index].id = 0;
        formDataClone.items[index].productId = item.id;
        formDataClone.items[index].availableQuantity = item.quantity;
        formDataClone.items[index].name = item.productName;
        formDataClone.items[index].weight = item.weight;
        formDataClone.items[index].length = item.length;
        formDataClone.items[index].width = item.width;
        formDataClone.items[index].dimensionUnit = item.dimensionUnit;
        formDataClone.items[index].height = item.height;
        formDataClone.items[index].weightUnitId = item.unit;
        setFormData(formDataClone);
    }

    const handleBlur = (event, index, item) => {
        let formDataClone = _.cloneDeep(formData);
        if (formDataClone.items[index].productId === item.productId) {
            formDataClone.items[index].productSKU = item.productSku;
            formDataClone.items[index].label = item.productSku;
            setFormData(formDataClone);
        };
    }

    return (
        <>
            <div className="row mb-3 dimesntionsBlock">
                <div className="col-12 p-0"><b className='subHeadingInner'>Items/Products</b>
                    <button
                        className="ml-3 btn cus-primary-transparent-btn slcttxt"
                        onClick={() => {
                            setShowProductListingBox(true);
                        }}
                        style={{ paddingLeft: "0", paddingRight: "0" }}
                        type="text"
                        disabled={
                            loader || formData.consignmentId || !isEditable
                        }
                    >
                        Add from existing Product List <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </div>

                <div className='items-wrapper'>
                    {formData?.items && formData?.items.length > 0 && formData?.items?.map((item, index) => (
                        <div className='col-12 mb-2 itemView' key={item.id}>
                            {!item.isDeleted && (
                                <>
                                    <div className="row">
                                        <div className='col-lg-2 col-md-6 col-sm-12 mobMb-10'>
                                            <label className="input-label-txt">Name</label>
                                            <input
                                                className={`form-control numInput`}
                                                type="text"
                                                name="name"
                                                value={item.name}
                                                data-index={index}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className='col-lg-2 col-md-6 col-sm-12 mobMb-10'>
                                            <label className="input-label-txt">SKU</label>
                                            <AsyncTypeAheadComponent
                                                handleSearch={(val) => searchBySku(val, index)}
                                                isLoading={loader}
                                                key={item.productId}
                                                id={item.productId}
                                                options={skuListoptions}
                                                defaultInputValue={item.productSKU}
                                                handleSelect={(item) => handleSelect(item, index)}
                                                isDisabled={!isEditable}
                                                handleBlur={(event) => handleBlur(event, index, item)}
                                            />
                                        </div>
                                        <div className='col-lg-2 col-md-6 col-sm-12 mobMb-10'>
                                            <label className="input-label-txt">Available Qty</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                value={item.availableQuantity}
                                                name="availableQuantity"
                                                min={0}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                data-index={index}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className='col-lg-2 col-md-6 col-sm-12 mobMb-10'>
                                            <label className="input-label-txt">Ordered Qty</label>
                                            <input
                                                className={`form-control numInput ${item.error && "errorField"}`}
                                                type={"number"}
                                                name="orderedQuantity"
                                                value={item.orderedQuantity}
                                                min={0}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={handleItemChange}
                                                disabled={!isEditable}
                                                data-index={index}
                                            />
                                            <div className="error">{item.error}</div>
                                        </div>

                                        <div className="col-lg-2 col-md-6 col-sm-12 mobMb-10">
                                            <label className="input-label-txt">Weight</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                name="weight"
                                                min={0}
                                                value={item.weight}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                data-index={index}
                                                onChange={handleItemChange}
                                                disabled={!isEditable}
                                            />
                                        </div>

                                        <div className="col-lg-2 col-md-6 col-sm-12 mobMb-10">
                                            <label className="input-label-txt">Weight Unit</label>
                                            <select
                                                className={`form-control countrySelect`}
                                                onChange={handleItemChange}
                                                value={item.weightUnitId}
                                                name={"weightUnitId"}
                                                disabled={!isEditable}
                                                onBlur={handleBlurInput}
                                                data-index={index}
                                            >
                                                {weightUnitListing?.length > 0 &&
                                                    weightUnitListing?.map((weight) => (
                                                        <option key={weight.id} value={weight.id}>
                                                            {weight.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className="col-lg-2 col-md-6 col-sm-12 mobMb-10">
                                            <label className="input-label-txt">Length</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                name="length"
                                                value={item.length}
                                                onChange={handleItemChange}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                disabled={!isEditable}
                                                min={0}
                                                data-index={index}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-12 mobMb-10">
                                            <label className="input-label-txt">Width</label>
                                            <input
                                                className={`form-control numInput widthInput`}
                                                type={"number"}
                                                name="width"
                                                value={item.width}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={handleItemChange}
                                                disabled={!isEditable}
                                                min={0}
                                                onBlur={handleBlurInput}
                                                data-index={index}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-12 mobMb-10">
                                            <label className="input-label-txt">Height</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                name="height"
                                                value={item.height}
                                                min={0}
                                                data-index={index}
                                                disabled={!isEditable}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={handleItemChange}
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <label className="input-label-txt">Dimension Unit</label>
                                            <select
                                                className={`form-control countrySelect`}
                                                onChange={handleItemChange}
                                                value={item.dimensionUnitId}
                                                name={"dimensionUnitId"}
                                                disabled={!isEditable}
                                                data-index={index}
                                            >
                                                {dimensionUnitListing?.length > 0 &&
                                                    dimensionUnitListing?.map((dimension) => (
                                                        <option key={dimension.id} value={dimension.id}>
                                                            {dimension.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className='col-3'>
                                            <button disabled={!isEditable} onClick={(e) => handleRemoveItem(e, index)} className="ml-3 mt-4 btn cus-seconday-bg-btn round red"><i class="fa fa-minus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ItemsOutbound;