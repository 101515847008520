import React, { useEffect, useState } from 'react';
import LoaderSpinner from '../common/Loader';
import { marketPlaceListing } from '../../store/actions/marketplace.action';
import MarketPlaceListing from './MarketPlaceListing';
import { Button } from "bootstrap-4-react/lib/components";
import { useNavigate } from 'react-router-dom';

const MarketPlace = () => {
    const [listData, setListData] = useState([]);
    const [loader, setLoader] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        fetchMarketPlaceListing();
    }, []);

    const fetchMarketPlaceListing = async () => {
        setLoader(true);
        let resData = await Promise.resolve(marketPlaceListing(0));
        if (resData && resData.data.Status === 200) {
            setListData(resData.data.EntityList);
            setLoader(false);
        }
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">Marketplace
                    <div className="top_right_button_container">
                        <div className="top_right_button_container">
                            <Button
                                className="cus-primary-transparent-btn"
                                onClick={() => navigate('/marketplace-integration')}
                            >Back</Button>
                        </div>
                    </div>
                </div>
                {loader && <LoaderSpinner />}

                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className='incoming_wrapper main_content_table addressDetailWrapper'>
                            <MarketPlaceListing listData={listData} />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default MarketPlace;