import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CompanyManagement from "../components/AddCompany";
import { SellerManagement } from "../components/AddSeller";
import { UserManagement } from "../components/UserManagement/UserTable";
import AddressBook from "../components/AddressBook";
import AddressDetail from "../components/AddressBook/AddressDetail";
import KeyGeneration from "../components/APIKeyGeneration";
import { BatchShipping } from "../components/batch/batch";
import { CreateCsv } from "../components/createcsv/createcsv";
import { CreateIntegration } from "../components/createIntegration/createIntegration";
import { EditCompany } from "../components/EditCompany";
import { EditSeller } from "../components/EditSeller";
import ForgotPassword from "../components/ForgotPassword/forgotpassword";
import { Fundmanagement } from "../components/fundManagement/fundmanagement";
import { FundmanagementHistory } from "../components/fundManagement/fundManagementhistory";
import TrackHistory from "../components/History";
import IncomingPackages from "../components/incoming";
import EditPackages from "../components/incoming/EditPackage";
import PackageList from "../components/incoming/PackageList";
import Login from "../components/login/login";
import Main from "../components/main/Main";
import ManageCarriers from "../components/ManageCarriers";
import EditConfiguration from "../components/ManageCarriers/EditConfiguration";
import { CSVList } from "../components/mapping/csvList";
import MarketPlace from "../components/MarketPlace";
import HandleRedirection from "../components/MarketPlace/HandleRedirection";
import IntegrationConfigure from "../components/MarketPlace/IntegrationConfigure";
import Navbar from "../components/navbar/Navbar";
import CardDetails from "../components/RateCard/RateCardDetails";
import RateCard from "../components/RateCard/table";
import ResetPassword from "../components/ResetPassword/resetPassword";
import { EditUserManagement } from "../components/UserManagement/EditUserManagement";
import ScanForm from "../components/ScanForm";
import { Setup } from "../components/setup/setup";
import { Shipment } from "../components/shipment/shipment";
import IncomingProducts from "../components/incoming/product";
import Sidebar from "../components/sidebar/Sidebar";
import EditProduct from "../components/incoming/product/EditProduct";
import ProductList from "../components/incoming/product/ProductList";
import MarketplaceIntegration from "../components/MarketplaceIntegration";
import Recharge from "../components/modal/RechargeWallet/Recharge";
import MarginConfiguration from "../components/MarginConfiguration";
import CarrierListing from "../components/MarginConfiguration/CarrierListing";
import SellerCarrierList from "../components/ManageCarriers/SellerCarrierList";
import { useSelector } from "react-redux";
import Inventory from "../components/Inventory/Product";
import ProductInventory from "../components/Inventory/Product/Product";
import InboundComponent from "../components/Inventory/Inbound";
import ConsoleComponent from "../components/Inventory/Console/console";
import Configuration from "../components/Configuration";
import ProductHistory from "../components/Inventory/Product/ProductHistory";
import OutboundComponent from "../components/Outbound";
import { Barcode } from "../components/barCode/barcode";
import BarCodeGeneration from "../components/BarcodeGeneration";
import BarCodeMappings from "../components/barCode/Mapping";
import CreateBarCodeMapping from "../components/barCode/Mapping/createMapping";
import OutboundAddComponent from "../components/Outbound/outbound";
import BarCodeList from "../components/barCode/UploadBarcode";
import BarCodeSettings from "../components/barCode/BarcodeSettings";
import { MarketPlaceHistory } from "../components/MarketplaceIntegration/MarketPlaceHistory";
import AdhocBarcodeGeneration from "../components/barCode/AdhocGeneration";
import BarcodeUploadedList from "../components/barCode/UploadBarcode/barcodeListing";
import Refunds from "../components/Refunds";
import React from "react";
import { checkPermission } from "./utils";
import { accountsManagement, addressBook, barCode, batchModule, FEATURE_UPLOADS, featureModule, featureSubmodule, historyModule, inventoryManagement, orderManagement, packages, products, returnsManagement, scanFormModule, settings, shipmentModule, SUBF_ADHOCBARCODEGENERATION, SUBF_APIKEYGENERATION, SUBF_BARCODEMAPPING, SUBF_BARCODESETTINGS, SUBF_BARCODEUPLOAD, SUBF_COMPANY, SUBF_CONFIGURATION, SUBF_CSVMAPPING, SUBF_CSVUPLOAD, SUBF_FUNDMANAGEMENT, SUBF_INBOUND, SUBF_LISTING, SUBF_MARGINCONFIGURATION, SUBF_MARKETPLACEINTEGRATION, SUBF_NEWBARCODEGENERATION, SUBF_OUTBOUND, SUBF_RATECARD, SUBF_SELLER, SUBF_SERVICECONFIGIURATION, SUBF_USER, SUBF_USERSPERMISSIONS } from "../constant";
import Permissions from "../components/Permissions";
import Accounts from "../components/Accounts";
import Ledger from "../components/Accounts/Ledger";
import ServiceRateCard from "../components/Accounts/EditServiceRateCard";
import MultiCsvUploads from "../components/Uploads/MultiCsvUploads";

const PrivateRoute = ({ children, requiredPermissions, userPermissions, module, moduleType, subModule, ...rest }) => {
    if (localStorage.getItem("userDetails") === null) return <Navigate to="/" />
    if (rest?.employeePermissions?.length) {
        if (localStorage.getItem("userDetails") === null || !checkPermission(module, subModule, '', moduleType, rest.employeePermissions)) {
            return <Navigate to="/" />
        } else {
            return React.cloneElement(children, { ...rest });
        }
    } else {
        return null
    }
};

export default function AppRoutes(props) {
    const { currentUser, openSidebar, closeSidebar } = props;
    const userDetails = useSelector((state) => state.auth.user);

    return (
        <BrowserRouter>
            {currentUser ? (
                <Navbar openSidebar={openSidebar} {...props} />
            ) : null}
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                <Route
                    path="rateCardDetails/rate-:rowId-3pl"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_RATECARD} moduleType={featureSubmodule} {...props}>
                            <CardDetails />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="setting"
                    element={
                        <PrivateRoute module={settings} subModule={''} moduleType={featureModule} {...props}>
                            <Setup />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="service-rate-card"
                    element={
                        <PrivateRoute module={settings} subModule={''} moduleType={featureModule} {...props}>
                            <Accounts />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="service-rate-card/rate-card-:id"
                    element={
                        <PrivateRoute module={settings} subModule={''} moduleType={featureModule} {...props}>
                            <ServiceRateCard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="ledger"
                    element={
                        <PrivateRoute module={settings} subModule={''} moduleType={featureModule} {...props}>
                            <Ledger />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="barcode"
                    element={
                        <PrivateRoute module={barCode} subModule={''} moduleType={featureModule} {...props}>
                            <Barcode />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="generate-barcode"
                    element={
                        <PrivateRoute module={barCode} subModule={SUBF_NEWBARCODEGENERATION} moduleType={featureSubmodule} {...props}>
                            <BarCodeGeneration />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="barcode-settings"
                    element={
                        <PrivateRoute module={barCode} subModule={SUBF_BARCODESETTINGS} moduleType={featureSubmodule} {...props}>
                            <BarCodeSettings />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="barcode/barcode-upload"
                    element={
                        <PrivateRoute module={barCode} subModule={SUBF_BARCODEUPLOAD} moduleType={featureSubmodule} {...props}>
                            <BarCodeList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="barcode/barcode-upload/:id"
                    element={
                        <PrivateRoute module={barCode} subModule={SUBF_BARCODEUPLOAD} moduleType={featureSubmodule} {...props}>
                            <BarcodeUploadedList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="barcode/mappings"
                    element={
                        <PrivateRoute module={barCode} subModule={SUBF_BARCODEMAPPING} moduleType={featureSubmodule} {...props}>
                            <BarCodeMappings />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="barcode/mappings/bar-:id"
                    element={
                        <PrivateRoute module={barCode} subModule={SUBF_BARCODEMAPPING} moduleType={featureSubmodule} {...props}>
                            <CreateBarCodeMapping />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="barcode/adhoc-barcode-generation"
                    element={
                        <PrivateRoute module={barCode} subModule={SUBF_ADHOCBARCODEGENERATION} moduleType={featureSubmodule} {...props}>
                            <AdhocBarcodeGeneration />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="trackhistory"
                    element={
                        <PrivateRoute module={orderManagement} subModule={historyModule} moduleType={featureSubmodule} {...props}>
                            <TrackHistory />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="csvlist"
                    element={
                        <PrivateRoute module={FEATURE_UPLOADS} subModule={SUBF_CSVMAPPING} moduleType={featureSubmodule} {...props}>
                            <CreateCsv />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="csvUpload"
                    element={
                        <PrivateRoute module={FEATURE_UPLOADS} subModule={SUBF_CSVUPLOAD} moduleType={featureSubmodule} {...props}>
                            <CSVList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="fundManagement"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_FUNDMANAGEMENT} moduleType={featureSubmodule} {...props}>
                            <Fundmanagement />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="fundManagement/recharge"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_FUNDMANAGEMENT} moduleType={featureSubmodule} {...props}>
                            <Recharge />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="companyManagement"
                    element={
                        <PrivateRoute module={accountsManagement} subModule={SUBF_COMPANY} moduleType={featureSubmodule} {...props}>
                            <CompanyManagement />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="sellerManagement"
                    element={
                        <PrivateRoute module={accountsManagement} subModule={SUBF_SELLER} moduleType={featureSubmodule} {...props}>
                            <SellerManagement />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="userManagement"
                    element={
                        <PrivateRoute module={accountsManagement} subModule={SUBF_USER} moduleType={featureSubmodule} {...props}>
                            <UserManagement />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="outbound"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_OUTBOUND} moduleType={featureSubmodule} {...props}>
                            <OutboundComponent />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="outbound/:id"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_OUTBOUND} moduleType={featureSubmodule} {...props}>
                            <OutboundAddComponent />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="outbound/history-:id"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_OUTBOUND} moduleType={featureSubmodule} {...props}>
                            <ProductHistory {...props} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="inbound"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_INBOUND} moduleType={featureSubmodule} {...props}>
                            <InboundComponent />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="inbound/:id"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_INBOUND} moduleType={featureSubmodule} {...props}>
                            <ConsoleComponent />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="inventory"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_LISTING} moduleType={featureSubmodule} {...props}>
                            <Inventory />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="inventory/history-:id"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_LISTING} moduleType={featureSubmodule} {...props}>
                            <ProductHistory historyType="inventory" />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="inbound/history-:id"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_INBOUND} moduleType={featureSubmodule} {...props}>
                            <ProductHistory historyType="inventory" />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="inventory/:id"
                    element={
                        <PrivateRoute module={inventoryManagement} subModule={SUBF_LISTING} moduleType={featureSubmodule} {...props}>
                            <ProductInventory />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="editCompany/comp-:rowId"
                    element={
                        <PrivateRoute module={accountsManagement} subModule={SUBF_COMPANY} moduleType={featureSubmodule} {...props}>
                            <EditCompany />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="editSeller/sell-:rowId"
                    element={
                        <PrivateRoute module={accountsManagement} subModule={SUBF_SELLER} moduleType={featureSubmodule} {...props}>
                            <EditSeller />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="editUser/user-:rowId"
                    element={
                        <PrivateRoute module={accountsManagement} subModule={SUBF_USER} moduleType={featureSubmodule} {...props}>
                            <EditUserManagement />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="fundManagement/history/fd-:rowId-3pl"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_FUNDMANAGEMENT} moduleType={featureSubmodule} {...props}>
                            <FundmanagementHistory />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="MarketplaceIntegration/history/fd-:rowId-3pl"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_MARKETPLACEINTEGRATION} moduleType={featureSubmodule} {...props}>
                            <MarketPlaceHistory />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-packages"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={packages} moduleType={featureSubmodule} {...props}>
                            <IncomingPackages />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-products"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={products} moduleType={featureSubmodule} {...props}>
                            <IncomingProducts />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-product-listing"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={products} moduleType={featureSubmodule} {...props}>
                            <ProductList productType="allProducts" />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-product-listing/history-:id"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={products} moduleType={featureSubmodule} {...props}>
                            <ProductHistory historyType="imcoming" />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-packages/:id"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={packages} moduleType={featureSubmodule} {...props}>
                            <PackageList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-products/:id"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={products} moduleType={featureSubmodule} {...props}>
                            <ProductList productType="Product" />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-packages/:id/:id"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={packages} moduleType={featureSubmodule} {...props}>
                            <EditPackages />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-packages/create-packages/:id"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={packages} moduleType={featureSubmodule} {...props}>
                            <EditPackages />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/incoming-product/create-product/:id"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={products} moduleType={featureSubmodule} {...props}>
                            <EditProduct />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="incoming-product/:id/:id"
                    element={
                        <PrivateRoute module={returnsManagement} subModule={products} moduleType={featureSubmodule} {...props}>
                            <EditProduct />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="rateCard"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_RATECARD} moduleType={featureSubmodule} {...props}>
                            <RateCard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="createcsv"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_RATECARD} moduleType={featureSubmodule} {...props}>
                            <CreateCsv />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="shipment"
                    element={
                        <PrivateRoute module={orderManagement} subModule={shipmentModule} moduleType={featureSubmodule} {...props}>
                            <Shipment />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="BatchShipping"
                    element={
                        <PrivateRoute module={orderManagement} subModule={batchModule} moduleType={featureSubmodule} {...props}>
                            <BatchShipping />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="createIntegration/:id"
                    element={
                        <PrivateRoute module={FEATURE_UPLOADS} subModule={SUBF_CSVMAPPING} moduleType={featureSubmodule} {...props}>
                            <CreateIntegration />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="generate-key"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_APIKEYGENERATION} moduleType={featureSubmodule} {...props}>
                            <KeyGeneration />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="manage-carriers"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_SERVICECONFIGIURATION} moduleType={featureSubmodule} {...props}>
                            <ManageCarriers userDetails={userDetails} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="seller-carrier-services/:id"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_SERVICECONFIGIURATION} moduleType={featureSubmodule} {...props}>
                            <SellerCarrierList userDetails={userDetails} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="manage-carriers/:id"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_SERVICECONFIGIURATION} moduleType={featureSubmodule} {...props}>
                            <EditConfiguration />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="scan-form"
                    element={
                        <PrivateRoute module={orderManagement} subModule={scanFormModule} moduleType={featureSubmodule} {...props}>
                            <ScanForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="refunds"
                    element={
                        <PrivateRoute>
                            <Refunds />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="address-book"
                    element={
                        <PrivateRoute module={addressBook} moduleType={featureModule} {...props}>
                            <AddressBook />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="address-book/:id"
                    element={
                        <PrivateRoute module={addressBook} moduleType={featureModule} {...props}>
                            <AddressDetail />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/market-integration"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_MARKETPLACEINTEGRATION} moduleType={featureSubmodule} {...props}>
                            <MarketPlace />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/market-place"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_MARKETPLACEINTEGRATION} moduleType={featureSubmodule} {...props}>
                            <MarketPlace />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/marketplace-integration"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_MARKETPLACEINTEGRATION} moduleType={featureSubmodule} {...props}>
                            <MarketplaceIntegration />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/market-place/:id"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_MARKETPLACEINTEGRATION} moduleType={featureSubmodule} {...props}>
                            <IntegrationConfigure />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/uploads"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_MARKETPLACEINTEGRATION} moduleType={featureSubmodule} {...props}>
                            <MultiCsvUploads />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/ws"
                    element={
                        <HandleRedirection />
                    }
                />
                <Route
                    path="/ws/:id"
                    element={
                        <HandleRedirection />
                    }
                />
                <Route
                    path="/margin-configuration"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_MARGINCONFIGURATION} moduleType={featureSubmodule} {...props}>
                            <MarginConfiguration />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/margin-configuration/mc-:id-3pl"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_MARGINCONFIGURATION} moduleType={featureSubmodule} {...props}>
                            <CarrierListing />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="Configuration"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_CONFIGURATION} moduleType={featureSubmodule} {...props}>
                            <Configuration />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="permissions"
                    element={
                        <PrivateRoute module={settings} subModule={SUBF_USERSPERMISSIONS} moduleType={featureSubmodule} {...props}>
                            <Permissions />
                        </PrivateRoute>
                    }
                />
                <Route path="*" element={<Main />} />
            </Routes>
            {currentUser ? (
                <Sidebar closeSidebar={closeSidebar} />
            ) : null}
        </BrowserRouter>
    )
}