import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from 'uuid';
import { fetchAllEmployeePermission, fetchRoleList, updatePermission } from '../../store/actions/auth';
import _ from 'lodash';
import { findPermissionByRole } from '../../config/utils';
import { toast } from 'react-toastify';

const PermissionTable = (props) => {
    const { dispatch } = props;
    const { roleList, allEmployeePermission } = useSelector((state) => state?.auth);
    const [permissionList, setPermissionList] = useState([]);

    useEffect(() => {
        if (!allEmployeePermission.length) {
            dispatch(fetchAllEmployeePermission());
        } else {
            handleMergePermissions();
        }
    }, [allEmployeePermission]);

    useEffect(() => {
        if (!roleList.length) {
            dispatch(fetchRoleList());
        }
    }, []);

    const handleExpand = (index, subFeatureindex = null) => {
        let permissionListClone = _.cloneDeep(permissionList);
        if (subFeatureindex !== null) {
            permissionListClone[index].SubFeatures[subFeatureindex].showSubPermission = !permissionListClone[index].SubFeatures[subFeatureindex].showSubPermission;
        } else {
            permissionListClone[index].showSubPermission = !permissionListClone[index].showSubPermission;
        }
        setPermissionList(permissionListClone);
    }

    const findPermission = (role, permissions) => {
        if (roleList.length) {
            return findPermissionByRole(role, permissions, roleList);
        }
    }

    const handlePermissionChange = async (event, featureId, subFeatureId, permissionId, roleName) => {
        const { checked } = event.target;
        let findRoleId = roleList.filter((role) => role.name === roleName)[0];
        let data = {
            "featureId": featureId,
            "subFeatureId": subFeatureId,
            "roleId": findRoleId.id,
            "permissionId": permissionId,
            "permissionHave": checked,
        };
        let permissionResponse = await updatePermission(data);
        if (permissionResponse?.data?.Status === 200) {
            dispatch(fetchAllEmployeePermission());
            toast.success('Permission updated successfully');
        }
    };

    const mergeArrays = (array1, array2, key, keepKeysFromArray1 = []) => {
        return array2.map(item2 => {
            let item1 = array1.find(item1 => item1[key] === item2[key]);
            if (item1) {
                keepKeysFromArray1.forEach(prop => {
                    if (item1.hasOwnProperty(prop)) {
                        item2[prop] = item1[prop];
                    }
                });
                if (item1.SubFeatures && item2.SubFeatures) {
                    item2.SubFeatures = mergeArrays(item1.SubFeatures, item2.SubFeatures, 'subFeatureId', keepKeysFromArray1);
                }
            }
            return item2;
        });
    }

    const handleMergePermissions = () => {
        let allEmployeePermissionClone = _.cloneDeep(allEmployeePermission);
        let permissionListClone = _.cloneDeep(permissionList);
        let mergedData = mergeArrays(permissionListClone, allEmployeePermissionClone, "featureId", ['showSubPermission'])
        setPermissionList(mergedData);
    }

    return (
        <div className="scrollableXAxis">
            <div className='permissionTbl' key={uuidv4()}>
                <div className='row mb-4' >
                    <div className='col-3'></div>
                    {roleList.map((role, index) => (
                        <div key={role.id} className={`${index === 0 ? 'col-1' : 'col-2'} heading alignCenter`} id={role.id}>{role.name}</div>
                    ))}
                </div>

                {permissionList.map((permission, index) => (
                    <div className={`${permission.showSubPermission ? 'expanded' : ''}  row permissionRow`} key={permission.id}>
                        <div className='col-3 pt-2 pb-2 moduleBar cursorPointer' onClick={() => handleExpand(index)}>
                            <span className='module'>
                                <i className={`${permission.showSubPermission ? 'fa-angle-down' : 'fa-angle-right'} fa mr-2`} aria-hidden="true"></i>
                                {permission.featureName}
                            </span>
                        </div>
                        <div className='col-1 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Admin', permission.roleDetails)}
                                className='ml-0 moduleEnable'
                                onChange={(e) => handlePermissionChange(e, permission.featureId, null, null, 'Admin')}
                            />
                        </div>
                        <div className='col-2 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Seller', permission.roleDetails)}
                                className='ml-0 moduleEnable'
                                onChange={(e) => handlePermissionChange(e, permission.featureId, null, null, 'Seller')}
                            />
                        </div>
                        <div className='col-2 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Super Admin', permission.roleDetails)}
                                className='ml-0 moduleEnable'
                                onChange={(e) => handlePermissionChange(e, permission.featureId, null, null, 'Super Admin')}
                            />
                        </div>
                        <div className='col-2 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Warehouse Manager', permission.roleDetails)}
                                className='ml-0 moduleEnable'
                                onChange={(e) => handlePermissionChange(e, permission.featureId, null, null, 'Warehouse Manager')}
                            />
                        </div>
                        <div className='col-2 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Warehouse Worker', permission.roleDetails)}
                                className='ml-0 moduleEnable'
                                onChange={(e) => handlePermissionChange(e, permission.featureId, null, null, 'Warehouse Worker')}
                            />
                        </div>
                        {permission.showSubPermission && (
                            <div className='col-12'>
                                {permission.SubFeatures.map((subfeature, subFeatureindex) => (
                                    <div key={uuidv4()}>
                                        <div className='row permissionRow featureEnable'>
                                            <div className='col-3 pt-2 pb-2 pl-5 expandSubFeature cursorPointer' onClick={() => handleExpand(index, subFeatureindex)}>
                                                <span className='module'>
                                                    <i className={`${subfeature.showSubPermission ? 'fa-angle-down' : 'fa-angle-right'} fa mr-2`} aria-hidden="true"></i>
                                                    {subfeature.subFeatureName}
                                                </span>
                                            </div>
                                            <div className='col-1 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Admin', subfeature.roleDetails)}
                                                    className='ml-0'
                                                    onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, null, 'Admin')}
                                                />
                                            </div>
                                            <div className='col-2 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Seller', subfeature.roleDetails)}
                                                    className='ml-0'
                                                    onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, null, 'Seller')}
                                                />
                                            </div>
                                            <div className='col-2 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Super Admin', subfeature.roleDetails)}
                                                    className='ml-0'
                                                    onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, null, 'Super Admin')}
                                                />
                                            </div>
                                            <div className='col-2 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Warehouse Manager', subfeature.roleDetails)}
                                                    className='ml-0'
                                                    onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, null, 'Warehouse Manager')}
                                                />
                                            </div>
                                            <div className='col-2 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Warehouse Worker', subfeature.roleDetails)}
                                                    className='ml-0'
                                                    onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, null, 'Warehouse Worker')}
                                                />
                                            </div>
                                        </div>
                                        {subfeature.showSubPermission && (
                                            <>
                                                {subfeature.Permissions.map((userPermission) => (
                                                    <div className='row permissionRow permissionEnable' key={uuidv4()}>
                                                        <div className='col-3 pt-2 pb-2 pl-5'>
                                                            <span className='module ml-permission'>
                                                                {userPermission.permissionName}
                                                            </span>
                                                        </div>
                                                        <div className='col-1 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Admin', userPermission.roleDetails)}
                                                                className='ml-0'
                                                                onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, userPermission.permissionId, 'Admin')}
                                                            />
                                                        </div>
                                                        <div className='col-2 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Seller', userPermission.roleDetails)}
                                                                className='ml-0'
                                                                onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, userPermission.permissionId, 'Seller')}
                                                            />
                                                        </div>
                                                        <div className='col-2 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Super Admin', userPermission.roleDetails)}
                                                                className='ml-0'
                                                                onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, userPermission.permissionId, 'Super Admin')}
                                                            />
                                                        </div>
                                                        <div className='col-2 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Warehouse Manager', userPermission.roleDetails)}
                                                                className='ml-0'
                                                                onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, userPermission.permissionId, 'Warehouse Manager')}
                                                            />
                                                        </div>
                                                        <div className='col-2 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Warehouse Worker', userPermission.roleDetails)}
                                                                className='ml-0'
                                                                onChange={(e) => handlePermissionChange(e, permission.featureId, subfeature.subFeatureId, userPermission.permissionId, 'Warehouse Worker')}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PermissionTable;