import React, { useEffect, useState } from 'react';
import { Button } from 'bootstrap-4-react/lib/components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Select from "react-select";
import { getSellerCarriersForCompany, resetSellerByCompany, updateSellerCarriers } from '../../store/actions';
import { toast } from "react-toastify";
import { setLocalStorageItem } from '../../services/StorageService';

const EditConfiguration = (props) => {
    const userDetails = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const companyDetails = location.state?.company;
    const [isEditable, setIsEditable] = useState(location?.state?.editable);
    const [sellerList, setSellerList] = useState([]);
    const [selectedSellers, setSelectedSellers] = useState([]);
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const companySellers = useSelector((state) => state.shipment.companySellerList);

    useEffect(() => {
        //fetch sellers
        if (companyDetails && companyDetails.carrierCompanyId && companyDetails.companyId) {
            let data = `carrierCompanyId=${companyDetails.carrierCompanyId}&companyId=${companyDetails.companyId}`;
            dispatch(getSellerCarriersForCompany(data));
        }
    }, []);

    useEffect(() => {
        if (companySellers && companySellers.length) {
            let sellersData = companySellers.map((item) => {
                return {
                    ...item,
                    value: item.sellerCarrierId,
                    label: item.sellerName,
                };
            });
            setSellerList(sellersData);
            let filterSelectedSellers = sellersData.filter((seller) => seller.isActive);
            setSelectedSellers(filterSelectedSellers);
        } else {
            setSellerList([]);
            setSelectedSellers([]);
        }
    }, [companySellers]);

    const handleSellerForm = (value) => {
        setSelectedSellers(value);
        setIsFormEdited(true);
    };
    const saveConfiguration = async () => {
        let updateData = sellerList.map((item, index) => {
            if (selectedSellers.find((seller) => seller.sellerCarrierId === item.sellerCarrierId)) {
                item.isActive = true;
                return item;
            } else {
                item.isActive = false;
                return item;
            }
        });
        let data = {
            "modifiedBy": userDetails?.Value?.employeeId,
            "sellerCarriers": updateData,
        };
        setIsLoading(true);
        let saveRes = await updateSellerCarriers(data);
        if (saveRes && saveRes.data.Status === 200) {
            toast.success('Carriers Status Updated Successfully');
            setIsLoading(false);
        }
    };

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">Edit Configuration
                    <div className="top_right_button_container">
                        <button
                            className="btn cus-seconday-bg-btn marginLeft5"
                            onClick={() => setIsEditable(true)}
                            disabled={isEditable}
                        >Edit</button>
                        <button
                            disabled={isLoading || !isFormEdited}
                            className="btn cus-seconday-bg-btn marginLeft5"
                            onClick={saveConfiguration}
                        >
                            Save
                        </button>
                        <Button
                            className="cus-primary-transparent-btn"
                            onClick={() => {
                                navigate("/manage-carriers");
                                dispatch(resetSellerByCompany());
                                setLocalStorageItem('carrierTab', '2');
                            }}
                        >Back</Button>
                    </div>
                </div>

                <div className="main_content cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className='incoming_wrapper carrierConfig pt-4'>
                            <div className='section'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <h2>Carrier Details</h2>
                                        <label>Carrier</label>
                                        <select
                                            className='form-control' disabled>
                                            <option>{companyDetails?.carrierCompanyName}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='section'>
                                <h2>Associate Company & Seller Details</h2>
                                <div className='row'>
                                    <div className='col-3'>
                                        <label>Associate Company</label>
                                        <select
                                            className='form-control'
                                            disabled
                                        >
                                            <option>{companyDetails?.companyName}</option>
                                        </select>
                                    </div>
                                    <div className='col-6'>
                                        <label>Sellers (select to enable)</label>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            isDisabled={!isEditable}
                                            options={sellerList}
                                            value={selectedSellers}
                                            onChange={(value) =>
                                                handleSellerForm(value)
                                            }
                                            className='sellerSelect'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </main>
    );
}

export default EditConfiguration;