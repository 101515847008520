import Col from "react-bootstrap/Col";
import React from "react";

const IntegrationTable = (props) => {
    const { tableRows, handleChangeSave, csvColumnList } = props;

    return (
        <Col style={{ display: "flex", flexDirection: "column" }} className='selectWrapper col-6'>
            {csvColumnList.length > 0 && csvColumnList.map((item, index) => (
                <select
                    key={item.id}
                    className="select"
                    aria-label="Default select example"
                    name="csvintegrationcolumnsmapping"
                    onChange={(e) => handleChangeSave(e, index)}
                    value={item.mapId}
                >
                    {tableRows.map((column) => (
                        <option key={column?.id} value={column.mapId} disabled={column.mapId && csvColumnList.some((listItem) => listItem.mapId === column.mapId)}>
                            {column.name}
                        </option>
                    ))}
                </select>
            ))}
        </Col>
    );
}

export default IntegrationTable;