import React, { useEffect, useState } from "react";
import { Button } from "bootstrap-4-react/lib/components";
import Table from "react-bootstrap/Table";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistory } from "../../store/actions/history";
import moment from 'moment';
import LoaderSpinner from "../common/Loader";
import DraftEmail from "../common/DraftEmail";
import ExportOrderCsv from "../common/ExportOrderCsv";
import BatchPagination from "../batch/BatchPagination";
import _ from "lodash";
import { createPackingSlip, createScanForm, getMergedPdf } from "../../store/actions/batch.action";
import HistoryFilter from "./HistoryFilter";
import { Tooltip as WmsTooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { featureSubmodulePermission, historyModule, numOfPage, orderManagement, permissionCreateScanForm, permssionCreate, scanFormModule } from "../../constant";
import { checkPermission, findIsHostShipAi } from "../../config/utils";
import DetailedReports from "./DetailedStatementReport";

function TrackHistory(props) {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth.user);
  const { historyRecords, historyRecordStatus } = useSelector(
    (state) => state.HistoryReducer
  );
  const [modalShow, setModalShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [enableScanForm, setEnableScanForm] = useState(true);
  const [sellerList, setSellerList] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const [idList, setIdList] = useState([]);
  let historyFormData = {
    pageIndex: 1,
    pageSize: 30,
    companyId: userDetails?.Value?.companyId,
    orderNumber: "",
    isAdmin: userDetails?.Value?.isSuperAdmin,
    createdBy: userDetails?.Value?.employeeId,
    sellerId: userDetails?.Value?.sellerId,
    orderStatusId: 0,
    companySellerId: userDetails?.Value?.companySellerId,
    labelCreationFrom: null,
    labelCreationTo: null,
    weightUnit: 0,
    referenceSearchText: '',
    receiverSearchText: '',
    shipTo: null,
    shipFrom: null,
    quoteMin: '',
    weightMin: '',
    weightMax: '',
    quoteMax: '',
    carrierIdList: [],
    numPiece: '',
    serviceIdList: [],
    shipmentStatus: 0,
    companyIdList: [],
    sellerIdList: [],
    showFilter: false,
    numberOfPage: 30
  }
  const { filterBackDetails } = useSelector((state) => state.batchReducer);
  const [defaultFilterData, setDefaultFilterData] = useState({});
  const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);

  useEffect(() => {
    if (!_.isEmpty(filterBackDetails)) {
      setDefaultFilterData({ ...defaultFilterData, ...filterBackDetails });
    } else {
      setDefaultFilterData({ ...historyFormData });
    }
  }, []);

  const handleReset = () => {
    let data = JSON.parse(JSON.stringify(historyFormData));
    data.pageSize = defaultFilterData.pageSize;
    setDefaultFilterData(data);
    dispatch(getOrderHistory(data));
    setIdList([]);
    setSellerList(allSellers)
  }

  useEffect(() => {
    if (historyRecordStatus !== "success") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [historyRecordStatus]);
  useEffect(() => {
    let findCheckedRecords = historyData.filter((item) => item.isChecked).length;
    if (findCheckedRecords > 0) {
      // eslint-disable-next-line array-callback-return
      let findDate = historyData.filter((item) => {
        let dateCreated = moment(item.CreatedDate).format("DD-MM-YYYY");
        let dateNow = moment(new Date()).format("DD-MM-YYYY");
        if (item?.SupportsScanForm && item?.isChecked && dateCreated === dateNow) {
          return item;
        }
      });
      setEnableScanForm(findDate.length === findCheckedRecords ? false : true);
    } else {
      setEnableScanForm(true);
    }
  }, [historyData]);

  useEffect(() => {
    if (
      historyRecords.Entity &&
      historyRecords.Entity.historyTrackEntities.length
    ) {
      setHistoryData([...historyRecords.Entity.historyTrackEntities]);
      setTotalPages(Math.ceil(historyRecords.Entity.TotalRecords / (defaultFilterData?.pageSize || 30)));
    } else {
      setHistoryData([]);
      setTotalPages(0);
    }
  }, [historyRecords]);

  useEffect(() => {
    if (!_.isEmpty(defaultFilterData)) {
      fetchHistory();
    }
  }, [defaultFilterData?.pageSize, defaultFilterData.pageIndex]);

  const fetchHistory = () => {
    let historyObj = {
      ...defaultFilterData,
      carrierIdList: _.map(defaultFilterData.carrierIdList, "id") || [],
      serviceIdList: _.map(defaultFilterData.serviceIdList, "id") || [],
      companyIdList: _.map(defaultFilterData.companyIdList, "Id") || [],
      sellerIdList: _.map(defaultFilterData.sellerIdList, "id") || []
    };
    dispatch(getOrderHistory(historyObj));
  };

  const handlePrintLabel = (item) => {
    window.open(item.ShippingLabel, "PRINT", "height=400,width=600");
  };

  const handleEmailLabel = (item) => {
    setModalShow(true);
    setSelectedRecord(item);
  };

  const handleDownloadLabel = async () => {
    setLoader(true);
    let checkedData = historyData.filter((item) => {
      if (item?.isChecked && item.IsPrintLabelEnable) {
        return item;
      }
    });
    let checkedDataLength = historyData.filter(item => item.checked).length;
    if (checkedData.length) {
      let data = _.map(checkedData, "OrderNumber");
      let mergedRes = await getMergedPdf(data);
      if (mergedRes && mergedRes.data && mergedRes.data.Status === 200) {
        if (checkedDataLength !== checkedData.length) {
          toast.info('Some of the orders may have already delivered');
        }
        let updateIsChecked = historyData.map((record) => {
          return {
            ...record,
            isChecked: false,
          };
        });
        setHistoryData(updateIsChecked);
        window.open(mergedRes.data.Entity);
      }
    } else {
      toast.error('Can not reprint selected order(s) label');
    }
    setLoader(false);
  };

  const handleCheckRecord = (e, type) => {
    const { checked } = e.target;
    let recordData = [...historyData];
    let newIdList = [...idList];
    if (type === "all") {
      if (checked) {
        let markCheck = recordData.map((item) => ({
          ...item,
          isChecked: true,
        }));
        setHistoryData(markCheck);
        let data = _.map(markCheck, "ShipmentId");
        setIdList(data);
      } else {
        let markCheck = recordData.map((item) => ({
          ...item,
          isChecked: false,
        }));
        setIdList([]);
        setHistoryData(markCheck);
      }
    } else {
      recordData[type].isChecked = checked;
      if (checked) {
        newIdList.push(recordData[type].ShipmentId);
      } else {
        newIdList = newIdList.filter(id => id !== recordData[type].ShipmentId);
      }
      setHistoryData(recordData);
      setIdList(newIdList);
    }
  };

  const handleCreateScanForm = async () => {
    let scanFormData = `companySellerId=${userDetails?.Value.companySellerId}&createdBy=${userDetails?.Value.employeeId}`;
    let findCheckedRecords = historyData.filter((item) => item.isChecked);
    let data = _.map(findCheckedRecords, "ShipmentId");

    let resScanForm = await createScanForm(scanFormData, data);
    if (resScanForm.data && resScanForm.data.Status === 200) {
      toast.success('Scan form created Successfully');
      window.open(resScanForm.data.Entity, "_blank");
    } else if (resScanForm.data.Status === 400) {
      if (!resScanForm.data.Entity) {
        toast.error(resScanForm.data?.Message?.AppStatusDescription);
      } else {
        toast.error('Some error while creating scan form');
        window.open(resScanForm.data.Entity, "_blank");
      }
    } else {
      toast.error(resScanForm.data?.Message?.AppStatusDescription);
    }
    let updateIsChecked = historyData.map((record) => {
      return {
        ...record,
        isChecked: false,
      };
    });
    setHistoryData(updateIsChecked);
  };

  const handlePackingSlip = async (e, item) => {
    e.preventDefault();
    let response = await Promise.resolve(dispatch(createPackingSlip(item.OrderId)));
    if (response.data.Status === 200 && response.data.Entity) {
      window.open(response.data.Entity, "PRINT", "height=400,width=600");
      toast.success('Packing slip generated successfully')
    } else {
      toast.error(response.data.Message.AppStatusDescription);
    }
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          History/Track
          <div className="top_right_button_container">
            <>
              {checkPermission(orderManagement, historyModule, permssionCreate, featureSubmodulePermission, employeePermissions) && (
                <button
                  className="btn cus-seconday-bg-btn top-btn createScanFormBtn"
                  onClick={handleCreateScanForm}
                  id='scanformBtn'
                  disabled={enableScanForm}
                >
                  Create Scan Forms
                  <i id="scanformBtnIcon" className="fa fa-info marginLeft5" aria-hidden="true" ></i>
                  {enableScanForm && (
                    <WmsTooltip
                      anchorId={'scanformBtnIcon'}
                      place="bottom"
                      className="tooltipWidth"
                    >Select current date records only.</WmsTooltip>
                  )}
                </button>
              )}
            </>
            <button
              className="btn cus-seconday-bg-btn top-btn downloadLabelBtn rePrint"
              onClick={handleDownloadLabel}
              disabled={!historyData?.filter((item) => item.isChecked).length || loader}
            >
              Reprint selected Labels
            </button>

            <ExportOrderCsv
              idList={idList}
              gridData={historyData}
              csvData={defaultFilterData}
            />

            <DetailedReports dispatch={dispatch} />

            <Button
              className="cus-seconday-bg-btn showHideFilter"
              onClick={() => setDefaultFilterData({ ...defaultFilterData, showFilter: !defaultFilterData.showFilter })}
            >
              <i className="fa fa-filter" aria-hidden="true"></i> Filter
            </Button>
          </div>
        </div>

        <HistoryFilter
          showFilter={defaultFilterData.showFilter}
          userDetails={userDetails}
          defaultFilterData={defaultFilterData}
          setDefaultFilterData={setDefaultFilterData}
          handleReset={handleReset}
          setIdList={setIdList}
          setTotalPages={setTotalPages}
          historyFormData={defaultFilterData}
          HistoryFormData={JSON.parse(JSON.stringify(historyFormData))}
          sellerList={sellerList}
          setSellerList={setSellerList}
          allSellers={allSellers}
          setAllSellers={setAllSellers}
        />
        <div className="main_content cus-page-content-sec">
          {historyRecordStatus !== "success" && <LoaderSpinner />}
          <div className="main_content_table_history">
            <div className="tableFixHead">
              <Table className="table-height cus-left-right-col-layout">
                <thead border="Green">
                  <tr>
                    <th>
                      <input
                        className="checkAll"
                        checked={
                          historyData.filter((item) => item.isChecked)
                            .length === historyData.length
                        }
                        type={"checkbox"}
                        onChange={(e) => handleCheckRecord(e, "all")}
                      />
                    </th>
                    <th>Tracking Number</th>
                    {userDetails?.Value?.roleName !== 'Seller' && (
                      <th width={"15%"}>Company / Seller Name</th>
                    )}
                    <th>Service Type / Package Type</th>
                    <th>Pieces / Weight / Quoted</th>
                    <th>Ship Date / Label Creation Date</th>
                    <th>Order Number / Shipper Reference</th>
                    <th>Receiver / Return To</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {historyRecords && historyData.length > 0 && (
                    <>
                      {historyData.map((item, index) => (
                        <tr key={item.OrderId}>
                          <td>
                            <input
                              className="historyCheck"
                              type={"checkbox"}
                              checked={Boolean(item.isChecked) || idList.includes(item.ShipmentId)}
                              onChange={(e) => handleCheckRecord(e, index)}
                            />
                          </td>
                          <td>
                            {item.TrackingNumber ? (
                              <a
                                href={item.TrackingUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.TrackingNumber}
                              </a>
                            ) : (
                              "--"
                            )}
                          </td>
                          {userDetails?.Value?.roleName !== 'Seller' && (
                            <td width={"10%"}>{item.CompanySellerName}</td>
                          )}
                          <td>
                            <div>{item.ServiceType}</div>
                            {item.PackageType}
                          </td>
                          <td>
                            {item.TotalQuantity}
                            <br />
                            {item.TotalWeight} (
                            {item.TotalWeight &&
                              (item.WeightUnit ? item.WeightUnit : "LBS")}
                            )<br />$
                            {(Math.round(item.Rate * 100) / 100).toFixed(2)}
                          </td>
                          <td width={"10%"}>
                            {moment(item.ShipmentDate).format("MM-DD-YYYY")}
                            <br />
                            {moment(item.CreatedDate).format(
                              "MM-DD-YYYY | h:mm:ss A"
                            )}
                          </td>
                          <td>
                            <div className="wordWrap">{item.OrderNumber}</div>
                            <div className="wordWrap">{item?.Note}</div>
                          </td>
                          <td>
                            <div>{item?.ReceiverName}</div>
                            {item.ReceiverAddressLine1} {item.ReceiverCity}{" "}
                            {item.ReceiverState} {item.ReceiverZip}{" "}
                            {item.CountryCode}
                          </td>
                          <td>
                            <span className={item.BookingStatus.includes('Cancellation') ? 'activeStatus activeStatusInprogress' : `activeStatus`}>
                              {item.BookingStatus
                                ? item.BookingStatus
                                : "Booked"}
                            </span>
                          </td>
                          <td>
                            {item.IsPrintLabelEnable ? (
                              <Dropdown className="historyPopper">
                                <Dropdown.Toggle className="opneDropDownMenu" disabled={!item.IsPrintLabelEnable}>
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    className="printLabelBtn"
                                    disabled={!item.ShippingLabel}
                                    onClick={() => handlePrintLabel(item)}
                                  >
                                    Print Label
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="emailLabelBtn"
                                    onClick={() => handleEmailLabel(item)}
                                  >
                                    Email Label
                                  </Dropdown.Item>
                                  {!findIsHostShipAi() && (
                                    <>
                                      <Dropdown.Item
                                        className="emailLabelBtn"
                                        onClick={(e) => handlePackingSlip(e, item)}
                                        disabled={!item.OrderId}
                                        id="emailLabelBtn"
                                      >
                                        Packing Slips
                                      </Dropdown.Item>
                                    </>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                  {historyRecordStatus === "success" &&
                    historyRecords?.Entity?.historyTrackEntities?.length ===
                    0 && (
                      <tr>
                        <td colSpan={12} align="center">
                          No Record Found
                        </td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </div>
          </div>
          {modalShow && (
            <DraftEmail
              dispatch={dispatch}
              modalShow={modalShow}
              setModalShow={setModalShow}
              selectedRecord={selectedRecord}
            />
          )}
        </div>
        <BatchPagination
          gridData={historyData}
          totalVisualRecords={defaultFilterData.length}
          numberOfPage={defaultFilterData.pageSize}
          numOfPage={numOfPage}
          loader={loader}
          currentPage={defaultFilterData.pageIndex}
          totalPages={totalPages}
          setCurrentPage={(value) => setDefaultFilterData({ ...defaultFilterData, pageIndex: value })}
          totalRecords={historyRecords?.Entity?.TotalRecords}
          setNumberOfPage={(value) => setDefaultFilterData({ ...defaultFilterData, pageSize: value })}
        />
      </div>
    </main>
  );
}

export default TrackHistory;
