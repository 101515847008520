import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import SelectMenu from '../common/SelectMenu';
import { Col, Row } from "react-bootstrap";
import LoaderSpinner from '../common/Loader';
import { bulkUploadSampleTemplate, genericBulkUpload, getBulkUploadModules } from '../../store/actions/uploads.action';
import { useDispatch, useSelector } from "react-redux";
import { getCsvActiveIntegration } from '../../store/actions';
import Trash from "../../assets/trashRed.svg";
import { toast } from "react-toastify";
import { getSelectList } from '../../config/utils';

const BulkUpload = (props) => {
    const { handleCancel, show, formData, setFormData, moduleId, fetchUploadList } = props;
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const { bulkUploadModulesList } = useSelector((state) => state?.uploadsReducer);
    const activeCSVList = useSelector((state) => state?.shipment?.activeCSVList);
    const [fileName, setFileName] = useState("");
    const [File, setFile] = useState();
    const [moduleList, setModuleList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [sampleTemplateUrl, setSampleTemplateUrl] = useState("");
    const [templateId, setTemplateId] = useState(0);

    useEffect(() => {
        if (!bulkUploadModulesList.length) {
            dispatch(getBulkUploadModules());
        }
        setFormData({ ...formData, module: [], template: [] });
    }, []);

    useEffect(() => {
        if (bulkUploadModulesList.length) {
            const filteredModuleData = getSelectList(bulkUploadModulesList, 'id', 'moduleName');
            const filteredTemplateData = getSelectList(activeCSVList, 'Id', 'Name');
            if (moduleId === 0) {
                setModuleList(filteredModuleData);
            } else {
                setModuleList(filteredModuleData.filter(item => item.id === moduleId));
            }
            setTemplateList(filteredTemplateData);
        }
    }, [bulkUploadModulesList, activeCSVList]);


    const handleUploadClick = async (event) => {
        const fileNamechosen = event.target.files[0].name;
        setFile(event.target.files[0]);
        setFileName(fileNamechosen);
        setErrors({
            ...errors,
            chooseFile: "",
        });
    };

    const handleModuleChange = (value) => {
        setFormData({ ...formData, module: value, template: null });
        setErrors({
            ...errors,
            module: "",
        });
        dispatch(getCsvActiveIntegration(`?PageIndex=1&PageSize=10&BulkUploadModuleId=${value.value}`));
    }

    const handleOnChange = (value) => {
        setFormData({ ...formData, template: value });
        setTemplateId(value.Id);
        setErrors({
            ...errors,
            template: "",
        });
    }

    useEffect(() => {
        if (templateId !== 0) {
            downloadTemplate();
        }
    }, [templateId]);

    const downloadTemplate = async () => {
        setShowLoader(true);
        const data = {
            integrationId: formData.template.Id,
            integrationName: formData.template.Name,
        }
        bulkUploadSampleTemplate(data)?.then((res) => {
            setSampleTemplateUrl(res.data.Entity);
            setShowLoader(false);
        }).catch((err) => {
            console.error(err);
        })
    }

    const validateForm = () => {
        let isValid = true;
        let errorsObj = {};

        if (!formData.module.id) {
            isValid = false;
            errorsObj.module = "Required field";
        }
        if (!formData.template.Id) {
            isValid = false;
            errorsObj.template = "Required field";
        }
        if (!File) {
            isValid = false;
            errorsObj.chooseFile = "Required field";
        }
        setErrors(errorsObj);
        return isValid;
    }

    const handleUploadCsvFile = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const uploadCSVFile = new FormData();
            uploadCSVFile.append("FormFile", File);
            uploadCSVFile.append("BulkUploadModule", formData.module.id);
            uploadCSVFile.append("IntegrationId", formData.template.Id);
            setShowLoader(true);
            let resp = await Promise.resolve(dispatch(genericBulkUpload(uploadCSVFile)));
            setShowLoader(false);
            if (resp.data?.HasError) {
                toast.error(resp.data?.Error);
                fetchUploadList();
                resp?.data?.ErrorFilePath && window.open(resp?.data?.ErrorFilePath);
            } else {
                fetchUploadList();
                toast.success('File uploaded successfully');
                handleCancel();
            }
        }
    };

    const onTrashClick = () => {
        setFileName(null);
        setFile(null);
    };

    const handleCancelWithReset = () => {
        setModuleList([]);
        handleCancel();
    };

    return (
        <Modal show={show} centered backdrop="static" className="modalDeleteWrapper" size="lg" onHide={handleCancel}>
            <Modal.Header
                closeButton
                onHide={handleCancel}
            >
                <div className="modal-main-div"><span className="modal-m-text">Upload </span>{showLoader && <LoaderSpinner />}</div>
            </Modal.Header>
            <Modal.Body className="multiUploadDialog pt-5 fontFix">
                <div className="row">
                    <div className='col-12'>
                        <div className='row flexCenter'>
                            <div className="col-sm-12 col-lg-4 formLabel alignRightRes">Select Module <span className='require-field'>*</span></div>
                            <div className="col-sm-12 col-lg-6">
                                <div className={"select_react_Csv"}>
                                    <SelectMenu
                                        optionList={moduleList}
                                        handleChange={(value) => { handleModuleChange(value) }}
                                        placeholderText={'Select Module'}
                                        value={formData.module}
                                        name='moduleList'
                                        isMulti={false}
                                        classPrefix="moduleList"
                                        closeMenuOnSelect={true}
                                    />
                                    <div className="error">{errors.module}</div>
                                </div>
                            </div>
                        </div>
                        <div className='row flexCenter mb-4'>
                            <div className="col-sm-12 col-lg-4"></div>
                            <div className="col-sm-12 col-lg-6">
                                <i className='infoItalicTxt'>Select the module for bulk data upload.</i>
                            </div>
                        </div>
                        <div className='row flexCenter mb-4'>
                            <div className="col-sm-12 col-lg-4 formLabel alignRightRes">Select Template <span className='require-field'>*</span></div>
                            <div className="col-sm-12 col-lg-6">
                                <div className={"select_react_Csv"}>
                                    <SelectMenu
                                        optionList={templateList}
                                        handleChange={(value) => { handleOnChange(value) }}
                                        placeholderText={'Select Template'}
                                        name='templateList'
                                        value={formData.template}
                                        classPrefix="templateList"
                                    />
                                    <div className="error">{errors.template}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row flexCenter">
                            <div className="col-sm-12 col-lg-4 formLabel alignRightRes">Select File <span className='require-field'>*</span></div>
                            <div className='col-sm-12 col-lg-6'>
                                {fileName ? (
                                    <div>
                                        {fileName}
                                        <span className="trash-icon">
                                            {" "}
                                            <img
                                                className="trash-Img"
                                                onClick={onTrashClick}
                                                src={Trash}
                                                alt=""
                                            ></img>
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        <button className="btn-input-csv">
                                            <label className="btn-chooseFile" htmlFor={`actual-btn-6`}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-upload"
                                                    viewBox="0 0 16 16"
                                                    cursor="pointer"
                                                >
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                                </svg>{" "}
                                                &nbsp; Choose File{" "}
                                            </label>
                                        </button>
                                        <input
                                            type="file"
                                            name="file"
                                            accept=".csv"
                                            id={`actual-btn-6`}
                                            onChange={(e) => {
                                                handleUploadClick(e);
                                            }}
                                            hidden
                                        />
                                    </>
                                )}
                                <div className="error file-upload">{errors.chooseFile}</div>
                                {sampleTemplateUrl && (
                                    <div>
                                        <a rel="noreferrer" href={sampleTemplateUrl} target="_blank">
                                            Download Sample Template
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Row className="modaL_footer mt-0 mt-5 mr-2">
                <Col className="csv_Buttons">
                    <button
                        className="btn cus-seconday-bg-btn uploadBtn"
                        disabled={showLoader}
                        onClick={handleUploadCsvFile}
                    >Upload</button>

                    <button
                        className="btn cus-seconday-bg-btn marginLeft5 cancelBtn"
                        onClick={handleCancelWithReset}
                    >
                        Cancel
                    </button>
                </Col>
            </Row>
        </Modal>
    );
}

export default BulkUpload;