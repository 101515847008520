import { Button, Table } from "bootstrap-4-react/lib/components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import GenerateKeyPopup from "./GenerateKeyPopup";
import { toast } from "react-toastify";
import { fetchAllApiKeyList, setCompanyStatus } from "../../store/actions";
import moment from "moment";
import { MenuList } from "../common/MenuList";
import { Dropdown } from "react-bootstrap";
import AlertDialogModal from "../common/AlertDialog";
import BatchPagination from "../batch/BatchPagination";
import { featureSubmodulePermission, numOfPage, permssionAPIKeyActive, permssionGenerate, settings, SUBF_APIKEYGENERATION } from "../../constant";
import FilterKeyGenerate from "./Filter";
import { checkPermission, renderTableRow } from "../../config/utils";
import _ from "lodash";
import LoaderSpinner from "../common/Loader";

const KeyGeneration = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showKeyGenratePopup, setShowKeyGenratePopup] = useState(false);
  const [sortOrder, setSortOrder] = useState("DESCENDING");
  const [sortingColumn, setSortingColumn] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [alertStatusUpdate, setAlertStatusUpdate] = useState(false);
  const [selectRecord, setSelectRecord] = useState({});
  const [numberOfPage, setNumberOfPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyListing, setKeyListing] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const userDetails = useSelector((state) => state.auth.user);
  const [sellerList, setSellerList] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const { companyApiKeysStatus, companyApiKeys } = useSelector(
    (state) => state.company
  );

  const [filterValue, setFilterValue] = useState({
    DateFrom: null,
    DateTo: null,
    SellerIdList: [],
    CompanyIdList: [],
    pageSize: numberOfPage,
    pageIndex: currentPage
  });

  useEffect(() => {
    handleFetchAllApiKeyList();
  }, [numberOfPage, currentPage, sortOrder]);

  useEffect(() => {
    if (companyApiKeysStatus === "pending") {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [companyApiKeysStatus]);

  useEffect(() => {
    if (companyApiKeys?.EntityList) {
      setKeyListing(companyApiKeys?.EntityList);
      setTotalPages(
        Math.ceil(companyApiKeys?.TotalRecords / numberOfPage)
      );
    }
  }, [companyApiKeys]);

  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setSortingColumn(sortColumn);
    setSortOrder(newSortOrder);
    setFilterValue({
      ...filterValue,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleCopyClipboard = async (e, item) => {
    e.preventDefault();
    await navigator.clipboard.writeText(item.ApiKey);
    toast.success("Key Copied");
  };

  const handleActiveInActive = (item) => {
    setAlertStatusUpdate(true);
    setSelectRecord(item);
  };

  const updateStatus = async () => {
    let data = `id=${selectRecord.Id}&modifiedBy=${userDetails?.Value?.employeeId
      }&status=${selectRecord.Status === 1 ? false : true}`;
    setShowLoader(true);
    let res = await Promise.resolve(dispatch(setCompanyStatus(data)));
    if (res.data && res.data.Status === 200) {
      toast.success("Status Updated successfully");
      setAlertStatusUpdate(false);
      setSelectRecord({});
      dispatch(
        fetchAllApiKeyList({
          CompanyIdList: _.map(filterValue.CompanyIdList, "Id"),
          DateFrom: filterValue.DateFrom,
          DateTo: filterValue.DateTo,
          SellerIdList: _.map(filterValue.SellerIdList, "sellerId"),
          pageSize: filterValue.pageSize,
        })
      );
      setShowLoader(false);
    }
  };

  const handleFilter = () => {
    handleFetchAllApiKeyList();
  }

  const handleFetchAllApiKeyList = () => {
    let data = {
      ...filterValue,
      "pageIndex": currentPage,
      "pageSize": numberOfPage,
      "sortColumn": sortingColumn,
      "sortOrder": sortOrder,
      "CompanyIdList": _.map(filterValue.CompanyIdList, "Id"),
      "SellerIdList": _.map(filterValue.SellerIdList, "id")
    }
    dispatch(fetchAllApiKeyList(data));
  }

  const handleResetFilter = () => {
    let resetObj = { DateFrom: '', DateTo: '', CompanyIdList: [], SellerIdList: [] };
    setFilterValue({ ...resetObj });
    setCurrentPage(1);
    setSortingColumn('');
    setSortOrder('DESCENDING');
    let data = {
      "pageIndex": 1,
      "pageSize": numberOfPage,
      "sortColumn": '',
      "sortOrder": 'DESCENDING',
      "CompanyIdList": [],
      "SellerIdList": []
    };
    dispatch(fetchAllApiKeyList(data));
    setSellerList(allSellers);
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          API Key Generation
          <div className="top_right_button_container">
            {checkPermission(settings, SUBF_APIKEYGENERATION, permssionGenerate, featureSubmodulePermission, props.employeePermissions) && (
              <Button
                className="cus-seconday-bg-btn top-btn"
                id="savesr"
                onClick={() => setShowKeyGenratePopup(!showKeyGenratePopup)}
              >
                Generate
              </Button>
            )}
            <button
              className="btn cus-seconday-bg-btn top-btn"
              id="filterBtn"
              onClick={() => setShowFilter(!showFilter)}
            >
              Filter
            </button>
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/setting");
              }}
            >
              Back
            </Button>
          </div>
        </div>
        {showLoader && <LoaderSpinner />}
        <div className="main_content cus-page-content-sec incomingTbl">
          <div className="top_right_button_container cus-content-only-sec">
            {showFilter && (
              <FilterKeyGenerate
                showFilter={showFilter}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                handleFilter={handleFilter}
                handleResetFilter={handleResetFilter}
                customClass={'sellerCarrier'}
                sellerList={sellerList}
                setSellerList={setSellerList}
                allSellers={allSellers}
                setAllSellers={setAllSellers}
              />
            )}
            <div className="incoming_wrapper main_content_table tableFixHead">
              <Table className="table table-borderless keyGenerate">
                <thead border="Green">
                  <tr className="trow">
                    <th className="trows-s">#</th>
                    <th>
                      <div
                        className={
                          sortingColumn === "CompanyName"
                            ? "active"
                            : "sortColumn"
                        }
                        onClick={(e) => handleSortingCoulmn(e, "CompanyName")}
                      >
                        Company
                        <i
                          className={
                            sortingColumn === "CompanyName" &&
                              sortOrder === "ASCENDING"
                              ? "fa fa-arrow-up"
                              : "fa fa-arrow-down"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th>
                      <div
                        className={
                          sortingColumn === "SellerName"
                            ? "active SellerName"
                            : "sortColumn SellerName"
                        }
                        onClick={(e) => handleSortingCoulmn(e, "SellerName")}
                      >
                        Seller
                        <i
                          className={
                            sortingColumn === "SellerName" &&
                              sortOrder === "ASCENDING"
                              ? "fa fa-arrow-up"
                              : "fa fa-arrow-down"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>

                    <th className="trows-s">
                      <div
                        className={
                          sortingColumn === "CreatedDate"
                            ? "active"
                            : "sortColumn"
                        }
                        onClick={(e) => handleSortingCoulmn(e, "CreatedDate")}
                      >
                        Date
                        <i
                          className={
                            sortingColumn === "CreatedDate" &&
                              sortOrder === "ASCENDING"
                              ? "fa fa-arrow-up"
                              : "fa fa-arrow-down"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th>
                      <div
                        className={
                          sortingColumn === "EmailId"
                            ? "active"
                            : "sortColumn"
                        }
                        onClick={(e) => handleSortingCoulmn(e, "EmailId")}
                      >
                        Email Address
                        <i
                          className={
                            sortingColumn === "EmailId" &&
                              sortOrder === "ASCENDING"
                              ? "fa fa-arrow-up"
                              : "fa fa-arrow-down"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th className="trows-s">
                      API Key
                    </th>
                    <th>
                      <div
                        className={
                          sortingColumn === "Status" ? "active Status" : "sortColumn Status"
                        }
                        onClick={(e) => handleSortingCoulmn(e, "Status")}
                      >
                        Status
                        <i
                          className={
                            sortingColumn === "Status" &&
                              sortOrder === "ASCENDING"
                              ? "fa fa-arrow-up"
                              : "fa fa-arrow-down"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th className="trows-s"></th>
                  </tr>
                </thead>
                <tbody>
                  {keyListing &&
                    keyListing.length > 0 &&
                    keyListing.map((item, index) => (
                      <tr key={item.ApiKey}>
                        <td className="trows-s">{index + 1}</td>
                        <td className="trows-s">{item.CompanyName}</td>
                        <td className="trows-s">{item.SellerName}</td>
                        <td className="trows-s">
                          {moment(item?.CreatedDate).format(
                            "MM-DD-YYYY | h:mm:ss A"
                          )}
                        </td>
                        <td className="trows-s">{item?.EmailId}</td>
                        <td className="trows-s">
                          <div className="keyFieldWrapper">
                            <input
                              value={item.ApiKey}
                              className="form-control"
                            />
                            {item.Status === 1 && (
                              <button
                                onClick={(e) => handleCopyClipboard(e, item)}
                                className="btn copyBtn"
                                title="Copy key"
                              >
                                <i
                                  className="fa fa-clone"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          </div>
                        </td>
                        <td className="trows-s">
                          {item.Status === 1 ? (
                            <span className="activeStatusBtn">Active</span>
                          ) : (
                            <span className="activeStatusBtn inactiveBtn">
                              Inactive
                            </span>
                          )}
                        </td>
                        <td>
                          {checkPermission(settings, SUBF_APIKEYGENERATION, permssionAPIKeyActive, featureSubmodulePermission, props.employeePermissions) && (
                            <MenuList
                              children={
                                <Dropdown.Item
                                  className="printLabelBtn"
                                  onClick={() => handleActiveInActive(item)}
                                >
                                  Mark {item.Status === 1 ? "InActive" : "Active"}
                                </Dropdown.Item>
                              }
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  {keyListing.length === 0 && companyApiKeysStatus === "success" && (
                    <>{renderTableRow(9, 'No Record Found')}</>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {keyListing?.length > 0 && (
          <BatchPagination
            gridData={keyListing}
            numberOfPage={numberOfPage}
            numOfPage={numOfPage}
            loader={showLoader}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            totalRecords={companyApiKeys?.TotalRecords}
            setNumberOfPage={setNumberOfPage}
          />
        )}
      </div>


      {showKeyGenratePopup && (
        <GenerateKeyPopup
          showKeyGenratePopup={showKeyGenratePopup}
          setShowKeyGenratePopup={setShowKeyGenratePopup}
          numberOfPage={numberOfPage}
        />
      )}
      {alertStatusUpdate && (
        <AlertDialogModal
          modalShow={alertStatusUpdate}
          handleCancelDeleteAlert={() => setAlertStatusUpdate(false)}
          modalClass={"modalDeleteWrapper"}
          messageText="Are you sure, you want to update this record?"
          handleYesDeleteAlert={updateStatus}
          headingText={"UPDATE STATUS"}
          loader={showLoader}
        />
      )}
    </main>
  );
};

export default KeyGeneration;
