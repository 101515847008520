import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import "../mapping/csvListUploadForm.css";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import { Button } from "bootstrap-4-react/lib/components";
import React, { useState, useEffect } from "react";
import Trash from "../../assets/trashRed.svg";
import { toast } from "react-toastify";
import {
  ProductListFileUpload,
  getProductListTemplateUrl,
  outboundBulkUpload,
  outboundBulkUploadSampleTemplate,
} from "../../store/actions/inventory.action";
import { useDispatch, useSelector } from "react-redux";
import LoaderSpinner from "./Loader";

export function BulkUploadDialog(props) {
  const [fileName, setFileName] = useState("");
  const [File, setFile] = useState();
  const [errors, setErrors] = useState({});
  const createdBy = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    Name: "",
    CarrierId: "",
    BaseMasterId: 0,
  });

  const [loader, setLoader] = useState(false);
  const [sampleTemplateUrl, setSampleTemplateUrl] = useState("");

  useEffect(() => {
    if (props.type === 2) {
      downloadTemplate();
    }
  }, [props.type]);

  const downloadTemplate = async () => {
    setLoader(true);
    outboundBulkUploadSampleTemplate()?.then((res) => {
      setSampleTemplateUrl(res.data.Entity);
      setLoader(false);
    }).catch((err) => {
      console.error(err);
    })
  }

  const validateForm = () => {
    let isValid = true;
    let errorsObj = {};
    if (!formData.Id) {
      isValid = false;
      errorsObj.Id = "Required field";
    }
    if (!fileName) {
      isValid = false;
      errorsObj.chooseFile = "Choose file";
    }
    setErrors(errorsObj);
    return isValid;
  };

  const handleCsvForm = (item) => {
    setFormData({
      Name: item.label,
      IntegrationId: item?.value,
      Id: item?.value,
      CreatedBy: createdBy,
      csvTemp: item,
    });
    setErrors({
      ...errors,
      Id: "",
    });
  };

  const handleCancelUpload = () => {
    props.onHide();
  };

  const handleUploadClick = async (event) => {
    const fileNamechosen = event.target.files[0].name;
    setFile(event.target.files[0]);
    setFileName(fileNamechosen);
    setErrors({
      ...errors,
      chooseFile: "",
    });
  };

  const onTrashClick = () => {
    setFileName(null);
  };

  const handleUploadFile = async () => {
    if (validateForm()) {
      setLoader(true);
      const fd = new FormData();
      fd.append("FormFile", File);
      fd.append("IntegrationId", formData?.IntegrationId);
      fd.append("CreatedBy", createdBy?.Value?.employeeId);
      fd.append("SellerId", createdBy?.Value?.sellerId);
      fd.append("CompanyId", createdBy?.Value?.companyId);
      fd.append("CompanySellerId", createdBy?.Value?.companySellerId);
      fd.append("Role", createdBy?.Value?.role);
      fd.append("ModifiedBy", createdBy?.Value?.employeeId);
      setErrors({
        ...errors,
        chooseFile: "",
      });

      let resp = await Promise.resolve(dispatch(ProductListFileUpload(fd)));
      if (resp.data.Entity.errorFileUrl === "") {
        if (resp.data.Status === 200) {
          toast.success(resp.data?.Entity.message);
          props.onHide();
          props.fetchProductList && props.fetchProductList();
        } else {
          toast.error(resp.data?.Entity.message);
          setFileName("");
          setFormData({ ...formData, csvTemp: "", Id: "" });
        }
      } else {
        if (resp.data.Status === 200) {
          const link = document.createElement("a");
          link.href = resp.data.Entity.errorFileUrl;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          toast.success(resp.data?.Entity.message);
          props.onHide();
          props.fetchProductList && props.fetchProductList();

        } else {
          const link = document.createElement("a");
          link.href = resp.data.Entity.errorFileUrl;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          toast.error(resp.data?.Entity.message);
          setFileName("");
          setFormData({ ...formData, csvTemp: "", Id: "" });

        }
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(formData.csvTemp)) {
      fetchSampleTemplateUrl(formData.csvTemp);
    }
  }, [formData.csvTemp]);

  const fetchSampleTemplateUrl = async (integrationObj) => {
    let data = `integrationId=${integrationObj.value}&integrationName=${integrationObj.label}`;
    let response = await Promise.resolve(
      dispatch(getProductListTemplateUrl(data))
    );
    if (response?.data?.Status === 200) {
      setSampleTemplateUrl(response?.data?.Entity);
    }
  };

  const handleUploadCsv = async (e) => {
    const outboundCSVFile = new FormData();
    outboundCSVFile.append("File", File);
    setLoader(true);
    let resp = await Promise.resolve(dispatch(outboundBulkUpload(outboundCSVFile)));
    setLoader(false);
    if (resp.data.HasError) {
      toast.error(resp.data.Error);
      resp.data.ErrorFilePath && window.open(resp.data.ErrorFilePath);
    } else {
      toast.success('File uploaded successfully');
      props?.renderData(e, 'clear');
      handleCancelUpload();
    }
  }

  return (
    <div>
      <Modal {...props} className="modalDeleteWrapper" size="lg">
        <Modal.Header closeButton>
          <div className="modal-main-div">
            {loader ? (
              <>
                <text className="modal-m-text"> Upload CSV</text>{" "}
                <LoaderSpinner />
              </>
            ) : (
              <text className="modal-m-text"> Upload CSV</text>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="selectSvcModal fontFix">
          {!props.hideIntegration && (
            <div className="row modal_input">
              <div className="col-4 formLabel csv_label">Select CSV Template</div>
              <div className="col-8">
                <div className={"select_react_Csv"}>
                  <Select
                    options={
                      props.gridData &&
                      props.gridData?.map((data) => {
                        return {
                          label: data.name,
                          value: data.id,
                        };
                      })
                    }
                    onChange={(value) => handleCsvForm(value)}
                    value={formData?.csvTemp}
                    placeholder="Select template.."
                    classNamePrefix={'list'}
                  />
                  <p className="error dialogError">{errors.Id}</p>
                </div>
              </div>
            </div>
          )}
          <div className="bulkUpload_input">
            <div>
              {fileName ? (
                <div>
                  {fileName}
                  <span className="trash-icon">
                    {" "}
                    <img
                      className="trash-Img"
                      onClick={onTrashClick}
                      src={Trash}
                      alt=""
                    ></img>
                  </span>
                </div>
              ) : (
                <>
                  <button className="btn-input-csv">
                    <label className="btn-chooseFile" htmlFor={`actual-btn-6`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-upload"
                        viewBox="0 0 16 16"
                        cursor="pointer"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                      </svg>{" "}
                      &nbsp; Choose File{" "}
                    </label>
                  </button>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    id={`actual-btn-6`}
                    onChange={(e) => {
                      handleUploadClick(e);
                    }}
                    hidden
                  />
                </>
              )}
              <p className="error file-upload">{errors.chooseFile}</p>
              {sampleTemplateUrl && (
                <div>
                  <a rel="noreferrer" href={sampleTemplateUrl} target="_blank">
                    Download Sample Template
                  </a>
                </div>
              )}
            </div>
          </div>
          <Row className="modaL_footer">
            <Col xs={6}></Col>
            <Col className="csv_Buttons" xs={6}>
              <Button
                className="btn cus-seconday-bg-btn uploadBtn"
                onClick={props.type === 2 ? handleUploadCsv : handleUploadFile}
                disabled={loader || !File}
              >
                Upload
              </Button>
              <Button
                className="btn cus-seconday-bg-btn marginLeft5"
                onClick={() => handleCancelUpload()}
                disabled={loader}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}
