import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import Form from "react-validation/build/form";
import { login } from "../../store/actions/auth";
import "../../components/login/bootstraplogin.css";
import MainLogoV from "../../assets/images/main-logo-vertical.png"
import MainLogoAi from "../../assets/images/bannerLogin.svg"
import EmailIcon from "../../assets/icons/email-icon.png"
import PasswordIcon from "../../assets/icons/password-icon.png"
import { findIsHostShipAi } from "../../config/utils";

const Login = (props) => {
  const form = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const onChangeUsername = (e) => {
    const usernameValue = e.target.value;
    setUsername(usernameValue);
  };

  const handleBlur = (e) => {
    setUsername(e.target.value.trim());
  }

  const onChangePassword = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();
    await dispatch(login(username, password))
      .then((res) => {
        if (res.Data.StatusCode === 200) {
          props.history.push("/profile");
          window.location.reload();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="cus-login-pg login-form-center">
      <div>
        <div className="cus-login-pg-sec">
          <div className="cus-login-pg-left-sec">
            <img alt="" className="cus-main-logo-img" src={findIsHostShipAi() ? MainLogoAi : MainLogoV} />
          </div>
          <div className="cus-login-pg-right-sec">
            <div className="cus-welcome-txt">Welcome to <span>{findIsHostShipAi() ? 'ShipVisionAI' : '3PLVision'}</span></div>
            <div className="cus-login-form">
              <div className="cus-form-sec-heading">Log In</div>
              <div className="cus-login-form-sec">
                <Form className='loginFormSubmit' onSubmit={handleLogin} ref={form}>
                  <div className="form-group cus-rel">
                    <div className="cus-rel">
                      <input
                        type="text"
                        className="form-controlzm"
                        autoComplete={'false'}
                        name="username"
                        value={username}
                        onChange={onChangeUsername}
                        placeholder="Enter your email address"
                        onBlur={handleBlur}
                      />
                      <img alt="" className="cus-sm-icon-cls" src={EmailIcon} />
                    </div>
                    <div className="cus-rel">
                      <input
                        type="password"
                        className="form-controlzm "
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        autoComplete='off'
                        placeholder="Enter your Password"
                      />
                      <img alt="" className="cus-sm-icon-cls" src={PasswordIcon} />
                    </div>
                  </div>

                  <div className="form-group cus-action-group">
                    <div className="cus-action-btn-sec">
                      <button
                        className="btn cus-seconday-bg-btn loginBtn"
                        disabled={!password || !username || loading}
                      >
                        {loading ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <>Login</>
                        )}
                      </button>
                    </div>
                    <div className="form-group forgot-password">
                      <Link to={'/forgotPassword'}>forgot password</Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
