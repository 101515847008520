import React, { useEffect, useState } from 'react';
import Button from "bootstrap-4-react/lib/components/Button";
import LoaderSpinner from '../common/Loader';
import BulkUpload from './UploadDialog';
import DataTable from '../common/DataTable';
import { numOfPage, UploadListColumns } from '../../constant';
import { useDispatch, useSelector } from 'react-redux';
import { getBuklUploadCsvFileMasterDetails } from '../../store/actions/uploads.action';
import BatchPagination from '../batch/BatchPagination';
import _ from 'lodash';

const MultiCsvUploads = (props) => {
    let data = {
        pageIndex: 1,
        pageSize: 30,
        totalPages: 1,
        sortColumn: '',
        sortOrder: 'DESCENDING',
        totalRecords: 0,
    }
    const [showLoader, setShowLoader] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const { bulkUploadCsvFileMasterDetails, bulkUploadCsvFileMasterDetailsStatus } = useSelector((state) => state?.uploadsReducer);
    const [uploadData, setUploadData] = useState([]);
    const [filterData, setfilterData] = useState(data);
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setfilterData(data);
    }, []);

    useEffect(() => {
        fetchUploadList();
    }, [filterData.pageSize, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder]);

    const fetchUploadList = () => {
        let data = new FormData();
        data.append('SortColumn', filterData.sortColumn);
        data.append('SortOrder', filterData.sortOrder);
        data.append('module', _.map(filterData.module, 'id'));
        data.append('template', _.map(filterData.template, 'Id'));
        data.append('PageIndex', filterData.pageIndex);
        data.append('PageSize', filterData.pageSize);
        dispatch(getBuklUploadCsvFileMasterDetails(data));
    };

    useEffect(() => {
        if (bulkUploadCsvFileMasterDetailsStatus === "PENDING") {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [bulkUploadCsvFileMasterDetailsStatus]);

    useEffect(() => {
        if (bulkUploadCsvFileMasterDetails?.Entity?.TotalRecords) {
            setfilterData({ ...filterData, totalRecords: bulkUploadCsvFileMasterDetails?.Entity?.TotalRecords, totalPages: Math.ceil(bulkUploadCsvFileMasterDetails?.Entity?.TotalRecords / filterData.pageSize) });
        }
        if (bulkUploadCsvFileMasterDetails?.Entity?.listCsvFileMaster) {
            setUploadData(bulkUploadCsvFileMasterDetails?.Entity?.listCsvFileMaster);
        }
    }, [bulkUploadCsvFileMasterDetails]);

    const handleSortingColumn = (sortColumn) => {
        let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
        setfilterData({
            ...filterData,
            sortColumn: sortColumn,
            sortOrder: newSortOrder,
        });
    };

    const setCurrentPagePagination = (value) => {
        setfilterData({ ...filterData, pageIndex: Number(value) });
    }

    const setTotalNumberOfPage = (value) => {
        setfilterData({ ...filterData, pageSize: Number(value), pageIndex: 1 });
    }

    const handleUploadDialog = () => {
        setFormData(data)
        setShowUploadDialog(!showUploadDialog);
        setShowLoader(false);
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    Uploads
                    <div className="top_right_button_container">

                        <Button
                            className="cus-seconday-bg-btn top-btn"
                            onClick={handleUploadDialog}
                        >
                            Upload
                        </Button>
                    </div>
                </div>
                {showLoader && <LoaderSpinner />}

                <div className="main_content_table_history zIndex1">
                    <div className="tableFixHead pl-2 pr-2">
                        <DataTable
                            headers={UploadListColumns}
                            tableData={uploadData}
                            actionType={4}
                            tableDataStatus={bulkUploadCsvFileMasterDetailsStatus}
                            filterData={filterData}
                            handleUploadDialog={handleUploadDialog}
                            handleSortingColumn={handleSortingColumn}
                        />
                    </div>
                </div>
                {showUploadDialog && (
                    <BulkUpload fetchUploadList={fetchUploadList} show={showUploadDialog} formData={formData} setFormData={setFormData} handleCancel={handleUploadDialog} moduleId={4} />
                )}

                <BatchPagination
                    gridData={uploadData}
                    totalRecords={filterData.totalRecords}
                    numberOfPage={filterData.pageSize}
                    numOfPage={numOfPage}
                    loader={showLoader}
                    currentPage={filterData.pageIndex}
                    totalPages={filterData.totalPages}
                    setCurrentPage={setCurrentPagePagination}
                    setNumberOfPage={setTotalNumberOfPage}
                />
            </div>
        </main>
    );
}

export default MultiCsvUploads;