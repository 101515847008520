import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./rateCardModal.css";
import "./";
import { useState, useEffect } from "react";
import Switch from "react-switch";
import { FileUploader } from "react-drag-drop-files";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getAllcarriers,
  saveRateCard,
} from "../../../store/actions/batch.action";
import moment from "moment";
import { toast } from "react-toastify";
import { sampleTemplateRateCard } from "../../../constant";
import {
  createNewSellerRateCard,
  createSellerRateCard,
  GetActiveCompany,
  GetExistingBaseRateCards,
  getFilterSellers,
} from "../../../store/actions/rateCard";
import _ from "lodash";
import { getAllSellersList, getSellersforMultipleCompany } from "../../../store/actions";
import { GET_MULTIPLE_SELLERS } from "../../../store/types";
import { getSelectList } from "../../../config/utils";

const fileTypes = ["CSV"];

const RateCardModal = (props) => {
  const { getRateCards, showModal = true } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [active, setActive] = useState(true);
  const [rateCardType, setRateCardType] = useState("existingCard");
  const [errors, setErrors] = useState({});
  const [carrierId, setCarrierId] = useState();
  const [sellerList, setSellerList] = useState([]);
  const [rateCardMessage, setRateCardMessage] = useState("");
  const [activeSellerList, setActiveSellerList] = useState([]);
  const [baseRateCards, setBaseRateCards] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const [existingRateCard] = useState([]);
  const dispatch = useDispatch();
  const carrierListData = useSelector(
    (state) => state?.batchReducer?.carrierList
  );

  const GetActiveCompanyData = useSelector(
    (state) => state.RateCardReducer?.getActiveCompany
  );
  const createdBy = useSelector((state) => state.auth.user);
  const { baseRateCardList, filterAllSellers } = useSelector((state) => state.RateCardReducer);

  const getMultipleSellers = useSelector(
    (state) => state?.company?.getMultipleCompanySellers
  );

  useEffect(() => {
    if (
      getMultipleSellers?.Entity?.listSellerMasterOrdersEntity &&
      getMultipleSellers?.Entity?.listSellerMasterOrdersEntity.length
    ) {
      let data = getMultipleSellers?.Entity?.listSellerMasterOrdersEntity.map(
        (item, index) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: index + 1,
          };
        }
      );
      setSellerList(data);
    } else {
      setSellerList([]);
    }
  }, [getMultipleSellers]);

  const [formData, setFormData] = useState({
    CarrierId: "",
    EffectiveFrom: moment(new Date()).format("YYYY-MM-DD"),
    EffectiveTo: moment(new Date()).add(1, "d").format("YYYY-MM-DD"),
    effectiveCompanyId: "",
    createdDate: moment(new Date()).format("YYYY-MM-DD"),
    BaseMasterId: 0,
    margin: null,
    IsActive: active,
  });

  useEffect(() => {
    if (!carrierListData?.length) {
      dispatch(getAllcarriers());
    }
    setRateCardType("existingCard");
  }, []);

  useEffect(() => {
    if (props.type === "Seller") {
      let sellerData = {
        "pageIndex": 1,
        "pageSize": 10,
        "sortColumn": "name",
        "sortOrder": "ASCENDING",
        "companyIdList": [0],
        screenType: 2,
      };
      dispatch(GetActiveCompany());
      dispatch(getFilterSellers(sellerData));
      dispatch(GetExistingBaseRateCards(createdBy, true));
    }
    if (rateCardType === "newCard") {
      setErrors({});
    }
  }, [rateCardType]);

  const handleChangeFile = (file) => {
    if (file) {
      setErrors({ ...errors, fileName: "" });
    }
    setSelectedFile(file);
  };

  const handleFilterChange = (e, name) => {
    const { value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });

    setRateCardMessage("");
  };
  const handleInActiveClick = (e) => {
    setFormData({ ...formData, IsActive: e });
    setActive(e);
  };

  useEffect(() => {
    if (carrierId && errors.CarrierId) {
      setErrors({
        ...errors,
        CarrierId: "",
      });
    }
    setFormData({ ...formData, CarrierId: carrierId });
  }, [carrierId]);

  const validateBaseForm = () => {
    let isFormValid = true;
    let errorsObj = { ...errors };
    if (!formData.name) {
      errorsObj.name = "Name is required";
      isFormValid = false;
    }
    if (!carrierId) {
      errorsObj.CarrierId = "Select a carrier";
      isFormValid = false;
    }

    if (
      !formData.effectiveCompanyIdsAndSellerIds?.length &&
      rateCardType === "newCard"
    ) {
      errorsObj.effectiveCompanyIdsAndSellerIds = "Please select the seller";
      isFormValid = false;
    }
    if (!formData.effectiveCompanyId?.length && rateCardType === "newCard") {
      errorsObj.effectiveCompanyId = "Please select Effective Companies";
      isFormValid = false;
    }
    if (!selectedFile) {
      errorsObj.fileName = "Please select the file.";
      isFormValid = false;
    }
    setErrors(errorsObj);
    return isFormValid;
  };

  const validateSellerForm = () => {
    let isFormValid = true;
    let errorsObj = { ...errors };
    if (!formData.name) {
      errorsObj.name = "Name is required";
      isFormValid = false;
    }
    if (!formData.effectiveCompanyId) {
      errorsObj.effectiveCompanyId = "Please select Effective Companies";
      isFormValid = false;
    }
    if (!formData.margin) {
      errorsObj.margin = "Please enter margin %";
      isFormValid = false;
    }
    if (!formData.effectiveCompanyIdsAndSellerIds) {
      errorsObj.effectiveCompanyIdsAndSellerIds = "Please select the seller";
      isFormValid = false;
    }

    if (!formData.existingRateCard) {
      errorsObj.existingRateCard = "Please select Rate Card";
      isFormValid = false;
    }
    if (!formData.effectiveCompanyId) {
      errorsObj.effectiveCompanyId = "Please select Effective Company";
      isFormValid = false;
    }
    if (rateCardType === "newCard") {
      if (!selectedFile) {
        errorsObj.fileName = "Please select the file.";
        isFormValid = false;
      }
    }
    setErrors(errorsObj);
    return isFormValid;
  };

  const saveBaseRateCard = async () => {
    if (props.type === "Seller") {
      if (rateCardType === "existingCard") {
        saveExistingSellerRateCard();
      } else if (rateCardType === "newCard") {
        saveNewSellerRateCard();
      }
    } else {
      if (validateBaseForm()) {
        saveRateCardDetails();
      }
    }
  };

  const saveRateCardDetails = async () => {
    let rateCardRes = await saveRateCard(createNewSellerRateCardData());
    if (rateCardRes.data && rateCardRes.data.Status === 200) {
      let response = rateCardRes.data.Entity;
      if (response.isValid) {
        toast(response.message, {
          time: 3000,
          className: "sucess-toast",
        });
        getRateCards();
        setSellerList([]);
        props.onHide();
      } else {
        setRateCardMessage(response.message);
      }
    }
  }

  const saveExistingSellerRateCard = async () => {
    if (validateSellerForm()) {
      const data = {
        sellerRateCardMasterId: 0,
        name: formData.name,
        effectiveCompanyIdsAndSellerIds: formData?.effectiveCompanyIdsAndSellerIds?.map((d) => ({
          companyId: d?.companyIds,
          sellerId: d?.id,
        })),
        carrierId: formData.existingRateCard.carrierId,
        margin: formData.margin,
        isActive: true,
        createdBy: createdBy?.Value?.employeeId,
        sellerId: createdBy?.Value?.sellerId,
        companyId: createdBy?.Value?.companyId,
        existingRateCard: formData.existingRateCard.baseMasterId,
        companySellerId: createdBy?.Value?.companySellerId,
      };

      const sellerRes = await createSellerRateCard(data);

      if (sellerRes.data && sellerRes.data.Status === 200) {
        toast(sellerRes.data.Entity.message, {
          time: 3000,
          className: "success-toast",
        });
        props.fetchSellerCards();
        props.onHide();
        dispatch({
          type: GET_MULTIPLE_SELLERS,
          payload: [],
        });
      }
    }
  };

  const saveNewSellerRateCard = async () => {
    if (validateBaseForm()) {
      const rateCardRes = await createNewSellerRateCard(createNewSellerRateCardData());
      if (rateCardRes?.data?.Status === 200) {
        const responseData = rateCardRes.data.Entity;

        if (responseData.isValid) {
          toast(responseData.message, {
            time: 3000,
            className: "success-toast",
          });
          props.fetchSellerCards();
          props.onHide();
          dispatch({
            type: GET_MULTIPLE_SELLERS,
            payload: [],
          });
        } else {
          setRateCardMessage(responseData.message);
        }
      }
    }
  };

  const createNewSellerRateCardData = () => {
    const formDataPost = new FormData();
    formDataPost.append("fromFile", selectedFile);
    formDataPost.append("name", formData.name);
    formDataPost.append("carrierId", carrierId);
    formDataPost.append("effectiveFrom", formData.effectiveFrom);
    formDataPost.append("isActive", formData.isActive);
    formDataPost.append("createdBy", createdBy?.Value?.employeeId);
    formDataPost.append("modifiedBy", createdBy?.Value?.employeeId);
    formDataPost.append("companyId", createdBy?.Value?.companyId);
    formDataPost.append("sellerId", createdBy?.Value?.sellerId);
    formDataPost.append(
      "companySellerId",
      createdBy?.Value?.companySellerId
    );
    formDataPost.append(
      "companySellerIds",
      JSON.stringify(
        formData?.effectiveCompanyIdsAndSellerIds?.map((d) => {
          return {
            companyId: d?.companyIds,
            sellerId: d?.id,
          };
        })
      )
    );
    return formDataPost;
  }

  useEffect(() => {
    setFormData({
      effectiveFrom: moment(new Date()).format("YYYY-MM-DD"),
      effectiveTo: moment(new Date()).add(1, "d").format("YYYY-MM-DD"),
      effectiveSellers: "",
      createdDate: moment(new Date()).format("YYYY-MM-DD"),
      baseMasterId: 0,
      effectiveCompanyId: "",
      margin: null,
      isActive: active,
    });
  }, [props.type]);

  useEffect(() => {
    if (GetActiveCompanyData && GetActiveCompanyData.length) {
      let data = GetActiveCompanyData.map((item) => {
        return {
          ...item,
          value: item.Id,
          label: item.name,
        };
      });
      setActiveSellerList(data);
    }
    let sellerList = filterAllSellers?.EntityList || [];
    if (sellerList.length) {
      setAllSellers(getSelectList(sellerList, 'id', 'sellerName'));
      setSellerList(getSelectList(sellerList, 'id', 'sellerName'));
    }
  }, [GetActiveCompanyData, filterAllSellers]);

  useEffect(() => {
    if (
      baseRateCardList?.BaseRateResponseEntity &&
      baseRateCardList?.BaseRateResponseEntity?.length
    ) {
      let data = baseRateCardList?.BaseRateResponseEntity?.map((item) => {
        return {
          ...item,
          value: item.baseMasterId,
          label: item.name,
        };
      });
      setBaseRateCards(data);
    }
  }, [baseRateCardList]);

  const CarrierListOptions =
    carrierListData &&
    carrierListData?.map((d) => {
      return { value: d?.id, label: d?.carrierCompanyName };
    });

  const handleSellerForm = (item, name) => {
    setFormData({ ...formData, [name]: item });
    setErrors({
      ...errors,
      [name]: "",
    });

    if (name === "effectiveCompanyId") {
      dispatch(getSellersforMultipleCompany(_.map(item, "Id")));
      setFormData({ ...formData, [name]: item, seller: null });
    }
  };
  const fetchSellers = async (companyList) => {
    if (companyList.length === 0) {
      setSellerList(allSellers);
      return;
    }
    let data = {
      companyIds: companyList,
    };
    let res = await getAllSellersList(data);
    if (res?.data?.Status === 200) {
      let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: item.id,
          };
        }
      );
      setSellerList(dataList);
    }
  };

  const handleMultiSelect = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    });
    if (fieldName === "effectiveCompanyId") {
      let companyData = _.map(value, "Id");
      fetchSellers(companyData);
      if (formData?.effectiveCompanyIdsAndSellerIds?.length) {
        const filteredSellers = formData.effectiveCompanyIdsAndSellerIds.filter(seller =>
          companyData.includes(parseInt(seller.companyIds))
        );
        setFormData({ ...formData, effectiveCompanyIdsAndSellerIds: filteredSellers, effectiveCompanyId: value })
      }
    }
  }

  const handelCancel = () => {
    dispatch({
      type: GET_MULTIPLE_SELLERS,
      payload: [],
    });

    return props?.onHide();
  };

  const handleSellerCardType = (type) => {
    setFormData({
      ...formData,
      name: "",
      effectiveCompanyId: [],
      effectiveCompanyIdsAndSellerIds: [],
    });
    setRateCardType(type);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      effectiveCompanyIdsAndSellerIds: formData?.effectiveCompanyIdsAndSellerIds?.filter((item) => {
        return formData?.effectiveCompanyId?.some(
          (s) =>
            s?.Id === Number(item?.companyIds)
        );
      })
    });
  }, [formData.effectiveCompanyId, getMultipleSellers]);

  return (
    <Modal {...props} size="lg" backdrop="static" centered show={showModal}>
      <div className="top_modal_header_container uploadNew-modal">
        <span className="">
          {props.type === "Seller" ? "Seller Rate Card" : "Base Rate Card"}
        </span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handelCancel}
          ></button>
        </div>
      </div>
      <div className="modal_container_rate_card fontFix">
        <div className="row">
          <div
            className={
              props.type === "Seller" && rateCardType != "newCard"
                ? "col-12"
                : "col-8"
            }
          >
            {props.type === "Seller" && (
              <>
                <div className="row modal_input">
                  <div
                    className={
                      rateCardType === "newCard"
                        ? "col-12 formLabel card-Modal-Update"
                        : "col-12 formLabel card-Modal"
                    }
                  >
                    <input
                      onChange={() => handleSellerCardType("existingCard")}
                      type="radio"
                      checked={rateCardType === "existingCard"}
                      id="html"
                      name="fav_language"
                      value="HTML"
                      className="fav_language"
                    />
                    <label for="html">Existing Rate Card</label>

                    <input
                      onChange={() => handleSellerCardType("newCard")}
                      type="radio"
                      id="html"
                      className="newCardClass"
                      name="fav_language"
                      value="HTML"
                    />
                    <label for="html">Upload New</label>
                  </div>
                </div>
              </>
            )}
            <div className="row modal_input">
              <div className="col-4 formLabel">
                Name <span className="require-field">*</span>
              </div>

              <div className={"col-8"}>
                <input
                  onChange={(e) => handleFilterChange(e, "name")}
                  value={formData.name}
                  className="form-control"
                  type="text"
                />
                <p className="error dialogError">{errors.name}</p>
              </div>
            </div>
            {props.type === "Seller" && (
              <>
                <div className="row modal_input">
                  <div className="col-4 formLabel">Company</div>
                  <div className={"col-8"}>
                    <div class={"select_react"}>
                      <Select
                        isMulti
                        options={activeSellerList}
                        value={formData.effectiveCompanyId}
                        onChange={(value) =>
                          handleMultiSelect(value, "effectiveCompanyId")
                        }
                        classNamePrefix="list"
                        id="existingRateCardSelect"
                      />
                    </div>
                    <p className="error dialogError">
                      {errors.effectiveCompanyId}
                    </p>
                  </div>
                </div>
                <div className="row modal_input">
                  <div className="col-4 formLabel">Seller</div>
                  <div className={"col-8"}>
                    <div class={"select_react"}>
                      <Select
                        options={sellerList}
                        onChange={(value) =>
                          handleMultiSelect(
                            value,
                            "effectiveCompanyIdsAndSellerIds"
                          )
                        }
                        isMulti
                        classNamePrefix="list"
                        value={formData?.effectiveCompanyIdsAndSellerIds}
                        id="existingRateCardSelect"
                      />
                    </div>
                    <p className="error dialogError">
                      {errors.effectiveCompanyIdsAndSellerIds}
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="row modal_input">
              <div className="col-4 formLabel">Status</div>
              <div className={"col-8"}>
                <Row className="status_bar_container">
                  <Switch
                    disabled
                    onChange={handleInActiveClick}
                    checked={active}
                    className="switchBtn"
                  />
                </Row>
              </div>
            </div>
            {props.type === "Seller" && rateCardType != "newCard" && (
              <div className="row modal_input">
                <div className="col-4 formLabel">Existing Rate Card</div>
                <div className={"col-8"}>
                  <div class={"select_react"}>
                    <Select
                      options={baseRateCards}
                      onChange={(value) =>
                        handleSellerForm(value, "existingRateCard")
                      }
                      classNamePrefix="list-value"
                      value={formData.existingRateCard}
                      id="existingRateCardSelect"
                    />
                  </div>
                  <p className="error dialogError">{errors.existingRateCard}</p>
                </div>
              </div>
            )}
            {rateCardType === "newCard" ? (
              <div className="row modal_input">
                <div className="col-4 formLabel">Carrier Provider</div>
                <div className="col-8">
                  <Select
                    onChange={(e) => setCarrierId(e?.value)}
                    options={CarrierListOptions}
                    classNamePrefix="list-value"
                  />
                  <p className="error dialogError">{errors.CarrierId}</p>
                </div>
              </div>
            ) : (
              <div className="row modal_input">
                <div className="col-4 formLabel">Carrier Provider</div>
                <div className={"col-8"}>
                  {props.type === "Seller" ? (
                    <input
                      disabled
                      className="form-control"
                      type={"text"}
                      value={formData.existingRateCard?.carrierName}
                    />
                  ) : (
                    <>
                      <Select
                        onChange={(e) => setCarrierId(e?.value)}
                        options={CarrierListOptions}
                        isDisabled={props.type === "Seller"}
                        defaultValue={existingRateCard}
                        id="selectExistingRateCardId"
                        classNamePrefix="list-value"
                      />
                      <p className="error dialogError">{errors.CarrierId}</p>
                    </>
                  )}
                </div>
              </div>
            )}

            {(props.type != "Seller" || rateCardType === "newCard") && (
              <div className="row modal_input">
                <div className="col-4 formLabel">Effective From:</div>
                <div className={"col-8"}>
                  <div className="form-control">
                    {" "}
                    {moment(new Date()).format("MM-DD-YYYY")}
                  </div>
                </div>
              </div>
            )}
            {props.type === "Seller" && rateCardType != "newCard" && (
              <div className="row modal_input">
                <div className="col-4 formLabel">
                  Margin (%) <span className="require-field">*</span>
                </div>

                <div className={"col-8"}>
                  <input
                    onChange={(e) => handleFilterChange(e, "margin")}
                    value={formData.margin}
                    className="form-control"
                    type="number"
                    maxLength={7}
                    id="marginInput"
                  />
                  <p className="error dialogError">{errors.margin}</p>
                </div>
              </div>
            )}
          </div>
          {(props.type !== "Seller" || rateCardType === "newCard") && (
            <div className="col-4">
              <div className="drag_drop_file">
                <FileUploader
                  maxSize={2}
                  fileOrFiles={selectedFile}
                  onTypeError={handleChangeFile}
                  minSize={0}
                  handleChange={handleChangeFile}
                  name="file"
                  types={fileTypes}
                />
              </div>
              <p className="error dialogError">{errors.fileName}</p>
              <div>
                <div className="xlsx_container">File format: CSV</div>
              </div>
              <div>
                <div className="download_template">
                  <a
                    rel="noreferrer"
                    href={sampleTemplateRateCard}
                    target="_blank"
                  >
                    Download Sample Template
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="col-4">
            <p className="error dialogError">{rateCardMessage}</p>
          </div>
        </div>
        <Row className="modaL_footer">
          <Col xs={8}>
          </Col>
          <Col style={{ textAlign: "right" }} xs={4}>
            <Button className="btn cus-seconday-bg-btn cancel-btn" onClick={handelCancel}>
              Cancel
            </Button>
            <Button
              className="btn cus-seconday-bg-btn marginLeft5"
              id="saveCardBtn"
              onClick={(e) => {
                saveBaseRateCard();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default RateCardModal;
