import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router";
import { addUpdateBarcodeIntegration, barCodeMappingBackDetails, barcodeUploadIntegration, checkIfDuplicateName, fetchBarcodeColumns, getColumnsByFileId, getIntegrationById } from '../../../store/actions/barcode.action';
import Papa from "papaparse";
import { handleColumnsLogic, handleMapping, saveModelObject } from '../../../config/utils';
import LoaderSpinner from '../../common/Loader';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { allowedCSVExtension } from '../../../constant';

const CreateBarCodeMapping = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { barcodeColumnsList, barcodeColumnsStatus } = useSelector((state) => state.barcodeReducer);
    const userDetails = useSelector((state) => state.auth.user);
    const [tableRows, setTableRows] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [isEditable, setIsEditable] = useState(location?.state?.isEdit);
    const [formData, setFormData] = useState({});
    const [formEdited, setFormEdited] = useState(false);
    const [columnList, setColumnList] = useState([]);
    const [saveModel, setSaveModel] = useState(JSON.parse(JSON.stringify(saveModelObject)));
    const [error, setErrors] = useState({});
    const [allColumns, setAllColumns] = useState([]);
    const [fileName, setFileName] = useState('');
    const { id } = useParams();

    useEffect(() => {
        if (Number(id)) {
            fetchIntegration(id);
        }
    }, [id]);

    useEffect(() => {
        setFormData({ ...formData, creatorName: userDetails?.Value?.userName });
    }, [userDetails]);

    const fetchIntegration = async (id) => {
        let res = await Promise.resolve(dispatch(getIntegrationById(id)));
        if (res.data.Status === 200) {
            let getColumnsRes = await Promise.resolve(dispatch(getColumnsByFileId(res.data.Entity.barCodeProductFilesId)));
            if (getColumnsRes.data.Status === 200) {
                setFormData(res.data.Entity);
                let allList = getColumnsRes.data.EntityList;
                allList.unshift({ name: "Please Select" });
                setTableRows(allList);
            }
        }
    }

    useEffect(() => {
        if (barcodeColumnsStatus === 'pending') {
            setShowLoader(true);
            return;
        }
        setShowLoader(false);
    }, [barcodeColumnsStatus]);

    useEffect(() => {
        setColumnList([...barcodeColumnsList]);
        if (barcodeColumnsList.length && formData.id) {
            let colmunData = handleMapping(columnList, formData);
            setColumnList(colmunData);
        }
    }, [barcodeColumnsList, formData.id]);

    useEffect(() => {
        dispatch(fetchBarcodeColumns());
    }, []);

    useEffect(() => {
        handleColumnOptions();
    }, [columnList]);

    const changeHandler = (event) => {
        const extension = event.target.files[0].name.split(".").slice(-1).pop();
        if (!allowedCSVExtension.includes(extension ? extension.toLowerCase() : "")) {
            toast.error('Please select CSV file only');
            return
        }

        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const rowsArray = [];
                const itemArray1 = [];
                let allColumns = [];
                itemArray1.push({ name: "Please Select" });
                rowsArray.push(Object.values(results.meta.fields));
                for (let i = 0; i < rowsArray[0].length; i++) {
                    itemArray1.push({ name: rowsArray[0][i].trim(), isDisabled: false, id: i + 1 });
                    allColumns.push({ columnName: rowsArray[0][i].trim() });
                }
                setAllColumns(allColumns);
                setTableRows(itemArray1);
                let makeColumnList = handleColumnsLogic(itemArray1, columnList);
                setColumnList(makeColumnList);

                let backUpSaveModel = { ...saveModel };
                backUpSaveModel.csvuploadcolumnsid = 0;
                setSaveModel(backUpSaveModel);
                const clearedSaveModel = { ...saveModel };
                clearedSaveModel.csvintegrationcolumnsmapping.forEach((item) => {
                    for (const key in item) {
                        item[key] = ""; // Clearing all values
                    }
                });
                setFileName(event.target.files[0].name);
                setErrors({ ...error, fileName: '' });
                setSaveModel(clearedSaveModel);
                setFormEdited(true);
            },
            error: function (error) {
                alert(error);
            }
        });
    }

    const handleValidations = () => {
        let error = {};
        let isFormValid = true;
        if (!formData.templateName) {
            error.templateName = 'This is required.';
            isFormValid = false;
        }
        setErrors(error);
        const removeEmptyValues = columnList.filter(item => (item.id === 1 || item.id === 4) && !item.value);
        if (removeEmptyValues.length && formData.templateName) {
            isFormValid = false;
            toast.error('Map mandatory columns.');
        }

        if (!fileName && Number(id) === 0) {
            isFormValid = false;
            error.fileName = 'Select file.'
        }
        return isFormValid;
    }

    const handleChange = (event) => {
        const { value, name } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...error, [name]: '' });
        setFormEdited(true);
    }

    const handleBlurInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
    }

    const handleSaveIntegration = async () => {
        let addFileName = allColumns.map((item) => {
            return {
                ...item,
                fileName: fileName,
            }
        });
        let response = await Promise.resolve(dispatch(barcodeUploadIntegration(addFileName)));
        if (response.data.Status === 200) {
            handleUpdateIntegration(response?.data?.Entity?.id);
        } else {
            toast.error('Something went wrong');
            setShowLoader(false);
        }
    }

    const handleNewFile = async (integrationId) => {
        let addFileName = allColumns.map((item) => {
            return {
                ...item,
                fileName: fileName,
                id: Number(id) ? formData.barCodeProductFilesId : 0
            }
        });
        let uploadColsResponse = await Promise.resolve(dispatch(barcodeUploadIntegration(addFileName)));
        if (uploadColsResponse.data.Status === 200) {
            handleAddUpdateIntegration(integrationId);
        }
    }


    const handleAddUpdateIntegration = async (integrationId) => {
        const removeEmptyValues = columnList.filter(item => item.value);
        let columnListData = removeEmptyValues.map((item) => {
            return {
                columnName: item.columnName,
                columnValueName: item.value
            }
        });
        let dataValues = {
            ...formData,
            barcodeIntegrationRequestColumnsMapping: columnListData,
            barcodeintegrationcolumnsmappingbyname: [],
            barcodeintegrationcolumnsmapping: [],
            templateName: formData.templateName,
            id: Number(id) ? formData.id : 0,
            barCodeProductFilesId: Number(id) === 0 ? integrationId : formData.barCodeProductFilesId
        }
        let addRes = await Promise.resolve(dispatch(addUpdateBarcodeIntegration(dataValues)));
        if (addRes.data.Status === 200) {
            toast.success(Number(id) === 0 ? 'Integration added successfully!' : 'Integration updated successfully!');
        } else {
            toast.error(addRes?.data?.Message?.AppStatusDescription);
            setShowLoader(false);
        }
        dispatch(barCodeMappingBackDetails({}));
    }

    const handleUpdateIntegration = async (integrationId) => {
        if (fileName && Number(id) > 0) {
            handleNewFile(integrationId);
        } else {
            handleAddUpdateIntegration(integrationId);
        }
    }

    const handleIntegrationName = async () => {
        setShowLoader(true);
        let response = await Promise.resolve(dispatch(checkIfDuplicateName(formData.templateName)));
        if (response?.data && response.data.Status === 200 && response?.data.Entity.isValid) {
            handleSaveIntegration();
            setShowLoader(false);
        } else {
            toast.error('Integration name already exists.');
            setShowLoader(false);
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        if (handleValidations()) {
            if (Number(id) > 0 && formEdited) {
                handleUpdateIntegration(formData.barCodeProductFilesId);
            } else {
                handleIntegrationName();
            }
        }
    }

    const handleColumnOptions = () => {
        let allTableRows = [...tableRows];
        let createList = allTableRows.map((item) => {
            let index = columnList.findIndex(col => col.value === (item.name || item.columnName));
            if (index > -1 && item.name !== 'Please Select') {
                item.isDisabled = true;
            } else {
                item.isDisabled = false;
            }
            return item;
        });
        setTableRows(createList);
    }

    const handleColumnValueChange = (event, index) => {
        let { value } = event.target;
        let list = [...columnList];
        list[index].value = value !== 'Please Select' ? value : '';
        setColumnList(list);
        setFormEdited(true);
    }

    const handleBackNavigation = () => {
        if (!location?.state?.backUrl) {
            navigate("/barcode/mappings");
        } else {
            navigate(location.state.backUrl);
        }
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec custitle">
                    <div>
                        Create Barcode Mapping
                    </div>

                    <div className="top_right_button_container">
                        <button
                            className="cus-seconday-bg-btn btn top-btn"
                            id="savesr"
                            onClick={handleSave}
                            disabled={showLoader || !isEditable}
                        >
                            Save
                        </button>
                        <button
                            className="btn cus-seconday-bg-btn top-btn"
                            onClick={() => setIsEditable(true)}
                            disabled={showLoader || isEditable}
                        >
                            Edit
                        </button>
                        <button
                            className="cus-primary-transparent-btn btn"
                            onClick={handleBackNavigation}
                        >
                            Back
                        </button>
                    </div>
                </div>
                {showLoader && <LoaderSpinner />}
                <div className="main_content">
                    <div className="row">
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='card fontFix'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <label>Template Name</label>
                                        <input type='text' disabled={!isEditable} name='templateName' className='form-control' value={formData.templateName} onChange={handleChange} onBlur={handleBlurInput} />
                                        <div className='error'>{error.templateName}</div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12'>
                                        <label>Created By</label>
                                        <input type='text' className='form-control' disabled value={formData?.creatorName} />
                                    </div>

                                    <div className='col-12 mt-3'>
                                        <label
                                            htmlFor="actual-btn"
                                            className={!isEditable ? 'btn cus-seconday-bg-btn disabled' : 'btn cus-seconday-bg-btn'}
                                        >
                                            <i class="fa fa-cloud-upload mr-2" aria-hidden="true"></i>{" "}
                                            CSV Template
                                        </label>
                                        <div className='error'>{error?.fileName}</div>
                                        <p>{fileName}</p>
                                        <input
                                            type="file"
                                            name="file"
                                            accept=".csv"
                                            id="actual-btn"
                                            onChange={changeHandler}
                                            hidden
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 col-sm-12 col-md-12 mobMbTop-10'>
                            <div className='card'>

                                <div className='card-title headingCsv'>Column Mapping</div>
                                <div className='row fontFix'>
                                    {columnList.map((item, index) => (
                                        <>
                                            <div className='col-lg-4 col-sm-12'>
                                                <div className='col-12 borderBox' key={item.id}>{item.columnName} {(item.id === 1 || item.id === 4) && <span className='error'>*</span>}</div>
                                            </div>
                                            <div className='col-lg-8 col-sm-12 mobMb-10'>
                                                <select
                                                    className='selectInput barCode form-control'
                                                    value={item.value}
                                                    key={item.id}
                                                    disabled={tableRows.length === 0 || !isEditable}
                                                    onChange={(e) => handleColumnValueChange(e, index)}
                                                >
                                                    {tableRows.map((option) => (
                                                        <option value={option.name} key={option.id} disabled={option.isDisabled}>{option.name || option.columnName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default CreateBarCodeMapping;