import { incomingConstant } from "../constants/incoming";

const initialState = {
  productYears: [],
  productYearsStatus: "",
  productDetails: [],
  productDetailsStatus: "",

  productDetailsById: {},
};

export default function IncomingProductsReducer(state = initialState, action = "") {
  const { type, payload } = action;
  switch (type) {
    case incomingConstant.GET_PRODUCT_YEAR_LIST_PENDING:
      return {
        ...state,
        productYears: [],
        productYearsStatus: "pending",
      };

    case incomingConstant.GET_PRODUCT_YEAR_LIST_SUCCESS:
      return {
        ...state,
        productYears: payload,
        productYearsStatus: "success",
      };
    case incomingConstant.GET_PRODUCT_YEAR_LIST_FAILED:
      return {
        ...state,
        productYears: [],
        productYearsStatus: "failed",
      };

    case incomingConstant.GET_DAILY_PRODUCT_COUNT_PENDING:
      return {
        ...state,
        productDetails: [],
        productDetailsStatus: "pending",
      };
    case incomingConstant.GET_DAILY_PRODUCT_COUNT_SUCCESS:
      return {
        ...state,
        productDetailsStatus: "success",
        productDetails: payload,
      };
    case incomingConstant.GET_DAILY_PRODUCT_COUNT_FAILED:
      return {
        ...state,
        productDetailsStatus: "failed",
        productDetails: [],
      };

    case incomingConstant.GET_PRODUCT_DETAILS_PENDING:
      return {
        ...state,
        productDetailsById: {},
        productDetailStatus: "pending",
      };

    case incomingConstant.GET_PRODUCT_DETAILS_FAILED:
      return {
        ...state,
        productDetailsById: {},
        productDetailStatus: "failed",
      };
    case incomingConstant.GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetailsById: payload,
        productDetailStatus: "success",
      };

    case incomingConstant.GET_PRODUCT_STATUS_LIST_SUCCESS:
      return {
        ...state,
        productStatusList: payload,
      };
    case incomingConstant.GET_PRODUCT_CONDITION_LIST_SUCCESS:
      return {
        ...state,
        productConditionList: payload,
      };
    case incomingConstant.GET_PRODUCT_WAREHOUSE_LIST_SUCCESS:
      return {
        ...state,
        productWarehouseList: payload,
      };
    case incomingConstant.GET_FILTER_DAILY_PRODUCT_COUNT_PENDING:
      return {
        ...state,
        productsListStatus: "pending",
        productsList: []
      };
    case incomingConstant.GET_FILTER_DAILY_PRODUCT_COUNT_SUCCESS:
      return {
        ...state,
        productsListStatus: "success",
        productsList: payload.Entity.incomingProductListing || [],
        productsListPageInfo: payload,
        productwarehouseList: payload.Entity.warehousesDetails || [],
      };
    case incomingConstant.GET_FILTER_DAILY_PRODUCT_COUNT_FAILED:
      return {
        ...state,
        productsListStatus: "failed",
        productsList: [],
        productsListPageInfo: 0,
      };
    case incomingConstant.GET_PRODUCT_PROCESS_TYPE_LIST_SUCCESS:
      return {
        ...state,
        productProcessList: payload,
      };
    case incomingConstant.RESET_PRODUCT_DATA:
      return {
        ...state,
        productDetailsById: {},
        productDetailStatus: "",
      };
    default:
      return state;
  }
}
