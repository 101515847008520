import { uploadConstant } from '../constants/uploads.constant'
const initialState = {
    bulkUploadModulesList: [],
    bulkUploadCsvFileMasterDetails: [],
    bulkUploadCsvFileMasterDetailsStatus: '',
};
export default function (state = initialState, action = '') {
  const { type, payload } = action;
  switch (type) {
    case uploadConstant.GET_BULK_UPLOAD_MODULES:
      return {
        ...state,
        bulkUploadModulesList: payload,
    };
    case uploadConstant.GET_BULK_UPLOAD_CSV_FILE_MASTER_DETAILS_SUCCESS:
      return {
        ...state,
        bulkUploadCsvFileMasterDetails: payload,
        bulkUploadCsvFileMasterDetailsStatus: 'SUCCESS',
    };
    case uploadConstant.GET_BULK_UPLOAD_CSV_FILE_MASTER_DETAILS_FAILED:
      return {
        ...state,
        bulkUploadCsvFileMasterDetails: [],
        bulkUploadCsvFileMasterDetailsStatus: 'FAILED',
    };
    case uploadConstant.GET_BULK_UPLOAD_CSV_FILE_MASTER_DETAILS_PENDING:
      return {
        ...state,
        bulkUploadCsvFileMasterDetails: [],
        bulkUploadCsvFileMasterDetailsStatus: 'PENDING',
    };
    default:
      return state;
  }
}

