import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteShipment, getShipmentById, loadShipments } from '../../store/actions';
import AlertDialogModal from './AlertDialog';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import moment from 'moment';

const LoadOrdersDialog = (props) => {
    const { modalClass, modalShow, toggleShipmentSave, loadShipmentData, userDetail, companyId, commodityObj } = props;
    let dispatch = useDispatch();
    const [shipments, setShipments] = useState([]);
    const [orderType, setOrderType] = useState('name');
    const [sortBy, setSortBy] = useState('asc');
    const [showAlert, setShowAlert] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const [loader, setLoader] = useState(false);
    const shipmentList = useSelector((state) => state.shipment.shipmentList);
    const shipmentListStatus = useSelector((state) => state.shipment.shipmentListStatus);

    useEffect(() => {
        fetchShipments();
    }, []);

    useEffect(() => {
        if ((shipmentList && shipmentList.length) || shipmentListStatus === 'success') {
            setShipments(shipmentList);
            setLoader(false);
        } else {
            setLoader(true);
        }
    }, [shipmentList]);

    const handleSorting = () => {
        if (orderType === 'name') {
            let shipmentsSort = [...shipments];
            if (sortBy === 'asc') {
                shipmentsSort = shipmentsSort.sort((a, b) => a.shipmentName.toLowerCase() < b.shipmentName ? 1 : -1)
            } else {
                shipmentsSort = shipmentsSort.sort((a, b) => a.shipmentName.toLowerCase() > b.shipmentName ? 1 : -1)
            }
            setShipments(shipmentsSort);
        }
        if (sortBy === 'asc') {
            setSortBy('desc');
        } else {
            setSortBy('asc')
        }
    };

    const fetchShipments = () => {
        let data = {
            shipmentStatus: 1,
            companyID: companyId,
            companySellerId: userDetail?.Value?.companySellerId,
        }
        dispatch(loadShipments(data));
    };

    const handleOrderType = (type) => {
        setOrderType(type);
    };

    const loadShipmentById = async (shipmentObj) => {
        let data = `shipmentId=${shipmentObj.id}&employeeId=${userDetail?.Value?.employeeId}&companyId=${userDetail?.Value?.companyId}`;
        let shipmentDataRes = await Promise.resolve(dispatch(getShipmentById(data)));
        if (shipmentDataRes.data.Status === 200) {
            if (shipmentDataRes.data?.Entity?.commodity) {
                if ((parseInt(shipmentDataRes.data?.Entity.receiverCountryID) !== 218 ||
                    shipmentDataRes.data?.Entity.sState === 'AA' ||
                    shipmentDataRes.data?.Entity.sState === 'AE' ||
                    shipmentDataRes.data?.Entity.sState === 'AP')) {
                    shipmentDataRes.data.Entity.commodity.includeCommercial = true;
                }
            } else {
                shipmentDataRes.data.Entity.commodity = JSON.parse(JSON.stringify(commodityObj));
            }
            toggleShipmentSave();
            loadShipmentData(shipmentDataRes.data.Entity);
        }
    };

    const handleDeleteForm = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentRecord(item);
        setShowAlert(true);
    };

    const handleDeleteShipment = async () => {
        let data = `shipmentId=${currentRecord.id}&employeeId=${userDetail?.Value?.employeeId}&companyId=${userDetail?.Value?.companyId}&sellerId=${userDetail?.Value?.sellerId}&companySellerId=${userDetail?.Value?.companySellerId}`;
        const deleteRes = await Promise.resolve(dispatch(deleteShipment(data)));
        if (deleteRes && deleteRes.data && deleteRes.data.Status === 200) {
            toast.success(deleteRes.data.Message.AppStatusDescription);
            setShipments([]);
            fetchShipments();
            props.createNewShipment();
            setShowAlert(false);
        } else {
            toast.error(deleteRes.data.Message.AppStatusDescription);
            setShipments([]);
            fetchShipments();
            setShowAlert(false);
        }
    };

    const renderData = () => {
        let content;

        if (loader) {
            content = (
                <div className='loadFormLoader'>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner>
                </div>
            );
        } else if (!loader && shipmentListStatus === 'success' && !shipments.length) {
            content = <>No Record Found</>;
        } else {
            content =
                <>
                    {shipments.length > 0 && shipments.map((item, index) => (
                        <div className='data-row' key={item?.id} onClick={() => loadShipmentById(item)}>
                            <div className='name'>{item.shipmentName}</div>
                            <div className='date'>{moment(item.createdDate).format("DD-MMM-YYYY | h:mm:ss A")}</div>
                            <div
                                className='actions'
                                title="Delete Order"
                                onClick={(e) => handleDeleteForm(e, item)}
                            >
                                <i className="fa fa-trash-o delete" aria-hidden="true"></i>
                            </div>
                        </div>
                    ))}
                </>
        }
        return content;
    }

    return (
        <>
            {showAlert ? (
                <AlertDialogModal
                    modalShow={true}
                    modalClass={"modalDeleteWrapper"}
                    handleCancelDeleteAlert={() => {
                        setShowAlert(false);
                        setCurrentRecord({});
                    }}
                    handleYesDeleteAlert={handleDeleteShipment}
                />
            ) : (
                <Modal
                    className={modalClass || ''}
                    size="lg"
                    show={modalShow || true}
                    onHide={() => {
                        toggleShipmentSave(false)
                    }}
                >
                    <Modal.Header closeButton>
                        <div className="modal-main-div">
                            <div className="modal-m-text">
                                <div>Load Saved Shipment</div>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='orderList'>
                            <div className='head'>
                                <div
                                    className='name delete'
                                    onClick={() => {
                                        handleOrderType('name');
                                        handleSorting();
                                    }}
                                >Name
                                    {orderType === 'name' && <i className={`${sortBy === 'asc' ? 'fa fa-arrow-up delete' : 'fa fa-arrow-down'}`} aria-hidden="true"></i>}
                                </div>
                                <div
                                    className='date delete'
                                    onClick={() => {
                                        handleOrderType('created');
                                    }}>
                                    Created
                                </div>
                                <div className='actions'></div>
                            </div>
                            <div className='body fontFix'>
                                {renderData()}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default LoadOrdersDialog;