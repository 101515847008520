import React from 'react';
import { Modal } from 'react-bootstrap';

const SaveShipmentDialog = (props) => {
    const { modalClass, modalShow, toggleShipmentSave, saveShipment, shipmentName, setShipmentName } = props;

    return (
        <Modal
            className={modalClass || ''}
            size="md"
            show={modalShow}
            onHide={() => {
                toggleShipmentSave(false);
                setShipmentName('');
            }}
        >
            <Modal.Header closeButton>
                <div className="modal-main-div">
                    <div className="modal-m-text">Save Shipment</div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='saveForm'>
                    <input placeholder='Enter Shipment Name' type="text" value={shipmentName} className='shipNameInput' onChange={(e) => setShipmentName(e.target.value)} />
                    <div className='formActions'>
                        <button className='btn cus-seconday-bg-btn' onClick={toggleShipmentSave}>Cancel</button>
                        <button className='btn cus-seconday-bg-btn marginLeft5' onClick={() => saveShipment('save')} disabled={shipmentName === ''}>Save Shipment</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SaveShipmentDialog;