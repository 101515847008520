import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import { InputGroup, Accordion } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button } from "bootstrap-4-react/lib/components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noImage from "../../../assets/No-Image-Placeholder.png";
import {
    createproduct,
    getProductConditionList,
    getProductDetails,
    getProductProcessTypeList,
    getProductStatusList,
    getProductWarehouseList,
    resetProduct,
    updateMediaForProduct,
    updateproduct,
} from "../../../store/actions/incoming";
import moment from "moment";
import LoaderSpinner from "../../common/Loader";
import { allowedExtensions, videoallowedExtensions } from "../../../constant";
import ImageSlider from "../ImageSlider";
import InLargeImageSlider from "../../modal/InlargeImageSlider";
import { handleDocsUpload } from "../../../config/utils";
import { getAllCountries } from "../../../store/actions";
import RenderVideos from "./RenderVideos";
import RenderImages from "./images";
import WarehouseDetails from "../../Inventory/Product/WarehouseDetails";
import _ from "lodash";

/*istanbul ignore next */
const EditProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { view, date, landingSearch, trackingNumber, productType } = location?.state;
    const userDetails = useSelector((state) => state.auth.user);
    const videoInputRef = useRef(null);
    const [image, setImage] = useState([]);
    const [showBeforeProcessingModal, setShowBeforeProcessingModal] = useState(false);
    const [showAfterProcessingModal, setShowAfterProcessingModal] = useState(false);
    const { warehouseList } = useSelector((state) => state.InventoryManagement);
    const [attachments, setAttachments] = useState([]);
    const [afterImage, setAfterImage] = useState([]);
    const [error, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [video, setVideo] = useState();
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [deletedWareHouses, setDeletedWareHouses] = useState([]);
    const UserValue = useSelector((state) => state.auth.user);
    const countries = useSelector((state) => state.shipment.countries);
    const productDetailsById = useSelector(
        (state) => state?.IncomingProductReducer?.productDetailsById
    );
    const productDetailStatus = useSelector(
        (state) => state?.IncomingProductReducer?.productDetailStatus
    );
    const productStatusList = useSelector(
        (state) => state?.IncomingProductReducer?.productStatusList
    );
    const productConditionList = useSelector(
        (state) => state?.IncomingProductReducer?.productConditionList
    );
    const productWarehouseList = useSelector(
        (state) => state?.IncomingProductReducer?.productWarehouseList
    );
    const productProcessList = useSelector(
        (state) => state?.IncomingProductReducer?.productProcessList
    );
    const [formData, setFormData] = useState({
        modifiedDate: new Date(),
        createdDate: new Date(),
        modifiedBy: UserValue?.Value?.employeeId,
        incomingDate: new Date(),
        countryOfOrigin: '218',
        wareHouseDetails: [{
            id: 0,
            quantity: 0,
            areaId: 0,
            rowId: 0,
            bayId: 0,
            levelId: 0,
            positionId: 0,
            inboundProductId: 0,
            warehouseId: 0,
            warehouseBinLocationId: 0,
            warehouse: '',
            area: '',
            row: '',
            bay: '',
            level: '',
            position: '',
        }]
    });

    const dispatch = useDispatch();
    let { id } = useParams();
    let isCreateForm = parseInt(id) === 0;

    useEffect(() => {
        if (parseInt(id) !== 0) {
            dispatch(getProductDetails(id));
        }
    }, [id]);

    useEffect(() => () => {
        dispatch(resetProduct());
    }, []);

    useEffect(() => {
        if (!countries.length) {
            dispatch(getAllCountries());
        }
    }, [countries]);

    useEffect(() => {
        if (!productStatusList?.length) {
            dispatch(getProductStatusList());
        }
        if (!productConditionList?.length) {
            dispatch(getProductConditionList());
        }
        if (!productWarehouseList?.length) {
            dispatch(getProductWarehouseList());
        }
        if (!productProcessList?.length) {
            dispatch(getProductProcessTypeList());
        }
    }, []);

    useEffect(() => {
        if (parseInt(id) !== 0) {
            setFormData(productDetailsById);
            if (productDetailsById?.attachments?.length) {
                setAttachments([...productDetailsById.attachments]);
            }
        }
    }, [productDetailsById]);

    useEffect(() => {
        if (productDetailStatus === "pending") {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [productDetailStatus]);

    const validateForm = () => {
        let isValid = true;
        let errorData = {};

        if (UserValue?.Value?.roleName === "Seller" && !formData?.notes) {
            isValid = false;
            errorData.notes = "Notes is required";
            setErrors(errorData);
            return isValid;
        } else {
            if (!formData?.inboundProductCondition_Id) {
                isValid = false;
                errorData.inboundProductCondition_Id = "This is required";
            }
            if (!formData?.sku) {
                isValid = false;
                errorData.sku = "This is required";
            }
            if (!formData?.itemName) {
                isValid = false;
                errorData.itemName = "This is required";
            }

            if (!formData?.inboundProductStatus_Id) {
                isValid = false;
                errorData.inboundProductStatus_Id = "This is required";
            }

            setErrors(errorData);
            return isValid;
        }
    };

    const validateCreateForm = () => {
        let isValid = true;
        let errorData = {};
        if (
            formData?.inboundProductCondition_Id == "0" ||
            formData?.inboundProductCondition_Id == undefined
        ) {
            isValid = false;
            errorData.inboundProductCondition_Id = "This is required";
        }
        if (!formData?.sku) {
            isValid = false;
            errorData.sku = "This is required";
        }
        if (!formData?.itemName) {
            isValid = false;
            errorData.itemName = "This is required";
        }
        if (!formData?.additionalProductId) {
            isValid = false;
            errorData.additionalProductId = "This is required";
        }
        if (!formData?.productDescription) {
            isValid = false;
            errorData.productDescription = "This is required";
        }

        setErrors(errorData);
        return isValid;
    };

    const handleFormChange = (e) => {
        const { value, name } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...error,
            [name]: "",
        });
        setIsFormEdited(true);
    };

    const handleSliderDeleteImage = (e, item) => {
        let formDataCopyObj = JSON.parse(JSON.stringify(formData));
        let updateBeforeProcessingImages =
            formDataCopyObj.beforeProcessingImages.map((imagePack) => {
                return {
                    ...imagePack,
                    isDeleted: item.id === imagePack.id ? true : false,
                };
            });
        let updateAfterProcessingImages = formDataCopyObj.afterProcessingImages.map(
            (imagePack) => {
                return {
                    ...imagePack,
                    isDeleted: item.id === imagePack.id ? true : false,
                };
            }
        );

        if (updateBeforeProcessingImages.length) {
            formDataCopyObj.beforeProcessingImages = updateBeforeProcessingImages;
        }
        if (updateAfterProcessingImages.length) {
            formDataCopyObj.afterProcessingImages = updateAfterProcessingImages;
        }

        setIsFormEdited(true);
        setFormData(formDataCopyObj);
    };


    const handleMarkPrimary = (e, item) => {
        let formDataCopyObj = JSON.parse(JSON.stringify(formData));

        let updateBeforeProcessingImages =
            formDataCopyObj.beforeProcessingImages.map((imagePack) => {
                return {
                    ...imagePack,
                    isPrimary: item.id === imagePack.id ? true : false,
                };
            });
        let updateAfterProcessingImages = formDataCopyObj.afterProcessingImages.map(
            (imagePack) => {
                return {
                    ...imagePack,
                    isPrimary: item.id === imagePack.id ? true : false,
                };
            }
        );
        formDataCopyObj.beforeProcessingImages = updateBeforeProcessingImages;
        formDataCopyObj.afterProcessingImages = updateAfterProcessingImages;
        setFormData(formDataCopyObj);
        setIsFormEdited(true);
    };


    const handleCreateForm = async () => {
        let data = { ...formData };
        data.createdBy = UserValue?.Value?.employeeId;
        setLoader(true);

        let response = await createproduct(data);
        if (response && response?.data?.Status === 200) {
            let uploadData = new FormData();
            uploadData.append("id", response?.data?.Entity);
            uploadData.append("modifiedBy", UserValue?.Value?.employeeId);
            uploadData = appendFilesToUploadData(uploadData, video, 'video');
            uploadData = appendFilesToUploadData(uploadData, image, 'beforeProcessingImages');
            uploadData = appendFilesToUploadData(uploadData, afterImage, 'afterProcessingImages');
            uploadData = appendFilesToUploadData(uploadData, attachments, 'attachments');

            let uploadRes = await updateMediaForProduct(uploadData);
            if (uploadRes && uploadRes?.data?.Status === 200) {
                navigate(`/incoming-products`, { state: { view: true, date: location?.state?.date } });
                setIsFormEdited(false);
                toast.success("Changes Saved Successfully");
                dispatch(getProductDetails(response?.data?.Entity));
                setVideo(null);
                setImage([]);
                setAttachments([]);
                setAfterImage([]);
            } else {
                toast.error(response?.data?.Message?.AppStatusDescription);
            }
            setLoader(false);
        } else {
            setLoader(false);
            toast.error(response?.data?.Message?.AppStatusDescription);
        }
    }

    const appendFilesToUploadData = (uploadData, files, key) => {
        if (files) {
            for (let i = 0; i < files.length; i++) {
                uploadData.append(key, files[i]);
            }
        }
        return uploadData;
    };

    const handleSave = async (e) => {
        e.preventDefault();
        if (isCreateForm) {
            if (validateCreateForm()) {
                handleCreateForm();
            }
        } else {

            if (validateForm()) {
                setLoader(true);
                let updateRes = JSON.parse(JSON.stringify(formData));
                updateRes.companySellerId = UserValue?.Value?.companySellerId;
                updateRes.modifiedBy = UserValue?.Value?.employeeId;
                updateRes.imageActions = [...updateRes.afterProcessingImages.filter((item) => item.isPrimary || item.isDeleted),
                ...updateRes.beforeProcessingImages.filter(
                    (item) => item.isPrimary || item.isDeleted
                )];
                updateRes.attachmentActions = [...updateRes.attachments.filter((item) => item.isDeleted)];
                handleUpdateProduct(updateRes);
            }
        }
    };

    const handleUpdateProduct = async (updateRes) => {
        let response = await updateproduct(updateRes);
        if (response && response?.data?.Status === 200) {
            let uploadData = new FormData();
            uploadData.append("id", id);
            uploadData.append("modifiedBy", 1);

            uploadData = appendFilesToUploadData(uploadData, video, 'video');
            uploadData = appendFilesToUploadData(uploadData, image, 'beforeProcessingImages');
            uploadData = appendFilesToUploadData(uploadData, afterImage, 'afterProcessingImages');
            uploadData = appendFilesToUploadData(uploadData, attachments, 'attachments');

            let uploadRes = await updateMediaForProduct(uploadData);
            if (uploadRes && uploadRes?.data?.Status === 200) {
                navigate(
                    `/incoming-product/fd-${new Date(
                        formData.createdDate
                    ).toISOString()}-3pl/${id}`,
                    {
                        state: {
                            view: true,
                            date: location?.state?.date,
                            landingSearch,
                            trackingNumber,
                            productType: productType,
                        },
                    }
                );

                toast.success("Changes Saved Successfully");
                setIsFormEdited(false);
                dispatch(getProductDetails(id));
                setVideo(null);
                setImage([]);
                setAfterImage([]);
            } else {
                toast.error(response?.data?.Message?.AppStatusDescription);
            }
            setLoader(false);
        }
    }

    const handleImageUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = Math.round(file.size / 1024);

                    if (
                        !allowedExtensions.includes(
                            extension ? extension.toLowerCase() : ""
                        )
                    ) {
                        files.splice(index, 1);
                        toast.error("Unsupported File format", 1000);

                        return false;
                    } else if (fileSize > 1024) {
                        files.splice(index, 1);
                        e.target.value = "";
                        toast.info("Each picture size should be less than 1MB", 1000);
                        return false;
                    }
                    return file;
                });

                setErrors({ ...error, image: "" });
                setImage(filterFiles);
                setIsFormEdited(true);



            } else if (files.length > 5) {
                toast.info("5 images can be upload at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    };


    const handleDeleteImage = (index) => {
        let images = [...image];
        images.splice(index, 1);
        setImage(images);
    };


    const handleDeleteAfterImage = (index, type = '') => {
        if (type === 'attachments') {
            let attachmentsList = [...attachments];
            if (attachmentsList[index].id) {
                attachmentsList[index].isDeleted = true;
            } else {
                attachmentsList.splice(index, 1);
            }
            setAttachments(attachmentsList);
        } else {
            let afterimages = [...afterImage];
            afterimages.splice(index, 1);
            setAfterImage(afterimages);
        }
    };

    const handleAfterImageUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = Math.round(file.size / 1024);

                    if (
                        !allowedExtensions.includes(
                            extension ? extension.toLowerCase() : ""
                        )
                    ) {
                        files.splice(index, 1);
                        toast.error("Unsupported File format", 1000);
                        return false;
                    } else if (fileSize > 1024) {
                        files.splice(index, 1);
                        e.target.value = "";
                        toast.info("Each picture size should be less than 1MB", 1000);
                        return false;
                    }
                    return file;
                });
                setErrors({ ...error, image: "" });
                setAfterImage(filterFiles);
                setIsFormEdited(true);

            } else if (files.length > 5) {

                toast.error("5 images can be upload at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleVideoUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = file.size / (1024 * 1024); // Convert size to MB


                    if (fileSize > 10) {
                        files.splice(index, 1);
                        toast.error("Video size should be less than 10MB", 1000);
                        e.target.value = "";
                        return false;
                    } else if (
                        !videoallowedExtensions.includes(
                            extension ? extension.toLowerCase() : ""
                        )
                    ) {
                        files.splice(index, 1);
                        toast.error("Unsupported file format", 1000);
                        return false;
                    }
                    return file;
                });
                setErrors({ ...error, video: "" });
                setVideo(filterFiles);
                setIsFormEdited(true);
                if (e.target.files.length !== files.length) {
                    toast.info("Each video size should be less than 10MB", 1000);
                    e.target.value = "";
                }
            } else if (files.length > 5) {
                toast.info("Up to 5 videos can be uploaded at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    };


    const handleEdit = () => {
        navigate(`/incoming-product/fd-${date}-3pl/${id}`, {
            state: {
                view: false,
                date: location?.state?.date,
                landingSearch,
                trackingNumber,
            },
        });
    };

    const handleBack = () => {
        if (isCreateForm) {
            navigate(`/incoming-products`);
        } else if (landingSearch || landingSearch === "") {
            navigate(`/incoming-products/fd-${date}-3pl`, {
                state: { dateValue: location?.state?.date, searchTxt: landingSearch },
            });
        } else if (trackingNumber) {
            navigate(`/incoming-products/fd-${date}-3pl`, {
                state: { trackingNumber },
            });
        } else if (productType === "allProducts") {
            navigate(`/incoming-product-listing`, {
            });
        } else {
            navigate(`/incoming-products/fd-${date}-3pl`, {
                state: { date: location?.state?.date, searchTxt: landingSearch },
            });
        }
    };


    const handleDateChange = (e, name) => {
        setFormData({
            ...formData,
            [name]: moment(e).format("MM-DD-YYYY"),
        });

        setIsFormEdited(true);
    };

    useEffect(() => {
        formData?.wareHouseDetails?.forEach((warehouse, index) => {
            if (warehouse?.id) {
                let warehouseListData = warehouseList?.filter(
                    (item) => item.id === Number(warehouse?.warehouseId)
                );
                let updatedWareHouseDetails = [...formData?.wareHouseDetails];
                if (warehouseListData?.length > 0) {
                    updatedWareHouseDetails[index].address = warehouseListData[0];
                }
                setFormData((prevState) => ({
                    ...prevState,
                    wareHouseDetails: updatedWareHouseDetails,
                }));
            }
        });
    }, [warehouseList]);

    const handleUpdateDeleteVideo = () => {

        setFormData({
            ...formData,
            videoUrl: "",
            deleteVideo: true,
        });
        setIsFormEdited(true);

    };


    const renderTitle = () => {
        let title;
        if (parseInt(id) === 0) {
            title = "Create Product";
        } else if (!view) {
            title = "Edit Product Details";
        } else {
            title = "Product Details";
        }
        return title
    }

    const addWarehouse = () => {
        let data = { ...formData };
        data.wareHouseDetails.push({ wareHouseName: "", quantity: 1 });
        setFormData(data);
    };

    const removeWarehouse = (index) => {
        let data = { ...formData };
        if (Number(id) > 0) {
            let deletedWareHouseList = [...deletedWareHouses];
            deletedWareHouseList.push(data.wareHouseDetails[index].id)
            setDeletedWareHouses(deletedWareHouseList);
        }
        data.wareHouseDetails.splice(index, 1);
        setFormData(data);
    };

    const handleWarehouse = (e, index, isNumber = false) => {
        const { value, name } = e.target;
        let data = _.cloneDeep(formData);
        data.wareHouseDetails[index][name] = isNumber ? Number(value) : value;
        if (name === "warehouseId") {
            let warehouseListData = warehouseList?.filter(
                (item) => item.id === Number(value)
            );
            data.wareHouseDetails[index].address = warehouseListData[0];
            data.wareHouseDetails[index].areaId = 0;
            data.wareHouseDetails[index].rowId = 0;
            data.wareHouseDetails[index].bayId = 0;
            data.wareHouseDetails[index].levelId = 0;
            data.wareHouseDetails[index].positionId = 0;
        }
        if (name === 'itemExpiryDate') {
            let dateSelected = moment(value).utc(true).format();
            data.wareHouseDetails[index][name] = dateSelected;
        }
        setFormData(data);
    };

    const updateWareHouseDetails = (index, name, list) => {
        let data = { ...formData };
        data.wareHouseDetails[index][name] = list;
        setFormData(data);
    };

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
    };

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    {renderTitle()}
                    <div></div>
                    <div className="top_right_button_container">
                        <button
                            disabled={!isFormEdited}
                            className="btn cus-seconday-bg-btn top-btn"
                            id="savesr"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                        {!isCreateForm && (
                            <Button
                                disabled={!view}
                                className="cus-seconday-bg-btn top-btn"
                                id="hde"
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        )}
                        <Button
                            className="cus-primary-transparent-btn top-btn"
                            id="hdb"
                            disabled={loader}
                            onClick={() => handleBack()}
                        >
                            Back
                        </Button>
                    </div>
                </div>
                {loader && <LoaderSpinner />}
                <div className="main_content cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className="incoming_wrapper incomingAccordion accordion pt-2">
                            <div className="row fontFix">
                                <div className={parseInt(id) === 0 ? "col-12" : "col-lg-8 col-sm-12"}>
                                    <Row className="p-2">
                                        <Col xs={12} lg={12}>
                                            <Accordion
                                                defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                                                alwaysOpen={true}
                                            >
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        Details
                                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row addressForm pl-3 pr-3">
                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-1">
                                                                <InputGroup>
                                                                    <div className="input-label-txt">
                                                                        Product SKU (Stock Keeping Unit) <span className="require-field">*</span>
                                                                    </div>
                                                                    <div className="input-box">
                                                                        <input
                                                                            disabled={view}
                                                                            onChange={handleFormChange}
                                                                            value={formData?.sku}
                                                                            name={"sku"}
                                                                            className="form-control"
                                                                            maxLength={100}
                                                                        />
                                                                    </div>
                                                                    {error.sku && (
                                                                        <label className="requiredthis">
                                                                            *This is required
                                                                        </label>
                                                                    )}
                                                                </InputGroup>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Product Name <span className="require-field">*</span>
                                                                </div>
                                                                <div className="input-box">
                                                                    <input
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.itemName}
                                                                        name={"itemName"}
                                                                        className="form-control"
                                                                        maxLength={100}
                                                                    />
                                                                </div>
                                                                {error.itemName && (
                                                                    <label className="requiredthis">
                                                                        *This is required
                                                                    </label>
                                                                )}
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Product Description <span className="require-field">*</span>
                                                                </div>
                                                                <div className="input-box">
                                                                    <input
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.productDescription}
                                                                        name={"productDescription"}
                                                                        className="form-control"
                                                                        maxLength={100}
                                                                    />
                                                                </div>
                                                                {error.productDescription && (
                                                                    <label className="requiredthis">
                                                                        *This is required
                                                                    </label>
                                                                )}
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">Product Size</div>
                                                                <div className="input-box">
                                                                    <div className="input-box">
                                                                        <input
                                                                            disabled={view}
                                                                            onChange={handleFormChange}
                                                                            value={formData?.size}
                                                                            name={"size"}
                                                                            className="form-control"
                                                                            maxLength={100}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">Product ID <span className="require-field">*</span></div>
                                                                <div className="input-box">
                                                                    <input
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.additionalProductId}
                                                                        name={"additionalProductId"}
                                                                        className="form-control"
                                                                        maxLength={100}
                                                                    />
                                                                </div>
                                                                {error.additionalProductId && (
                                                                    <label className="requiredthis">
                                                                        *This is required
                                                                    </label>
                                                                )}
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">Country of Origin <span className="require-field">*</span></div>
                                                                <div className="input-box">
                                                                    <select
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.countryOfOrigin}
                                                                        name={"countryOfOrigin"}
                                                                        className="form-control"
                                                                    >
                                                                        {countries?.map((country) => (
                                                                            <option key={country.id} value={country.id}>
                                                                                {country.name}
                                                                            </option>
                                                                        )
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">Product Condition <span className="require-field">*</span></div>
                                                                <div className="input-box">
                                                                    <select
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.inboundProductCondition_Id}
                                                                        name={"inboundProductCondition_Id"}
                                                                        className="form-control noMarginBtm"
                                                                    >
                                                                        {isCreateForm && (
                                                                            <option selected value={0}>
                                                                                Select
                                                                            </option>
                                                                        )}
                                                                        {productConditionList?.length &&
                                                                            productConditionList.map((item, index) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.condition}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                </div>
                                                                {error.inboundProductCondition_Id && (
                                                                    <label className="requiredthis">
                                                                        *This is required
                                                                    </label>
                                                                )}
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-box">
                                                                    <div className="input-label-txt">
                                                                        Product Expiry
                                                                    </div>
                                                                    <div>
                                                                        <DatePicker
                                                                            disabled={view}
                                                                            className="form-control receivedDate"
                                                                            onChange={(receivedDate) =>
                                                                                handleDateChange(receivedDate, "productExpiry")
                                                                            }
                                                                            showIcon
                                                                            value={
                                                                                formData?.productExpiry || isCreateForm
                                                                                    ? moment(formData?.productExpiry).format(
                                                                                        "MM-DD-YYYY"
                                                                                    )
                                                                                    : null
                                                                            }
                                                                            name={"productExpiry"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Package Tracking Id
                                                                </div>
                                                                <div className="input-box">
                                                                    {formData?.trackingUrl ? (
                                                                        <div className="tackingUrl">
                                                                            <a rel="noreferrer" target="_blank" href={formData?.trackingUrl}>
                                                                                {" "}
                                                                                {formData?.trackingNo}
                                                                            </a>
                                                                        </div>
                                                                    ) : (
                                                                        <input
                                                                            disabled={view}
                                                                            onChange={handleFormChange}
                                                                            value={formData?.trackingNo}
                                                                            name={"trackingNo"}
                                                                            className="form-control"
                                                                            maxLength={100}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    RMA Code
                                                                </div>
                                                                <div className="input-box">
                                                                    <input
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.rmaCode}
                                                                        name={"rmaCode"}
                                                                        className="form-control"
                                                                        maxLength={100}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    External Platform Product ID
                                                                </div>
                                                                <div className="input-box">
                                                                    <input
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.externalPlatformProductId}
                                                                        name={"externalPlatformProductId"}
                                                                        className="form-control"
                                                                        maxLength={100}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Before processing image
                                                                </div>
                                                                <div>
                                                                    <div className="fileUpload">
                                                                        <button
                                                                            disabled={view}
                                                                            type="button"
                                                                            className="btn cus-seconday-bg-btn"
                                                                        >
                                                                            Upload image
                                                                        </button>
                                                                        <input
                                                                            className="hiddenFileField"
                                                                            onChange={handleImageUpload}
                                                                            disabled={view}
                                                                            type="file"
                                                                            id="customFilebefore"
                                                                            multiple
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {image.length > 0 &&
                                                                            image.map((item, index) => (
                                                                                <div
                                                                                    className="selectedFile"
                                                                                    key={item?.id}
                                                                                >
                                                                                    <div className="fileName">{item.name}</div>
                                                                                    <button
                                                                                        onClick={() => handleDeleteImage(index)}
                                                                                    >
                                                                                        <i
                                                                                            className="fa fa-times"
                                                                                            aria-hidden="true"
                                                                                        ></i>
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    After processing Image
                                                                </div>
                                                                <div>
                                                                    <div className="fileUpload">
                                                                        <button
                                                                            disabled={view}
                                                                            type="button"
                                                                            className="btn cus-seconday-bg-btn"
                                                                        >
                                                                            Upload image
                                                                        </button>
                                                                        <input
                                                                            className="hiddenFileField"
                                                                            onChange={handleAfterImageUpload}
                                                                            disabled={view}
                                                                            type="file"
                                                                            id="customFileafter"
                                                                            multiple
                                                                        />
                                                                    </div>

                                                                    <RenderImages
                                                                        afterImage={afterImage}
                                                                        setAfterImage={setAfterImage}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Video (format: mp4, avi, mov)
                                                                </div>
                                                                <div>
                                                                    <div className="fileUpload">
                                                                        <button
                                                                            disabled={view}
                                                                            type="button"
                                                                            className="btn cus-seconday-bg-btn"
                                                                        >
                                                                            Upload Video
                                                                        </button>
                                                                        <input
                                                                            ref={videoInputRef}
                                                                            className="hiddenFileField"
                                                                            onChange={(e) => {
                                                                                handleVideoUpload(e);
                                                                                setIsFormEdited(true);
                                                                            }} // Modify the function name here
                                                                            disabled={view}
                                                                            type="file"
                                                                            id="customFile"
                                                                            multiple
                                                                        />
                                                                    </div>
                                                                    {formData?.videoUrl && (
                                                                        <div className="selectedFile">
                                                                            <div className="fileName">
                                                                                {formData?.video}
                                                                            </div>
                                                                            <a
                                                                                href={formData?.videoUrl}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {formData?.video}
                                                                            </a>
                                                                            {!view && (
                                                                                <button onClick={handleUpdateDeleteVideo}>
                                                                                    <i
                                                                                        className="fa fa-times"
                                                                                        aria-hidden="true"
                                                                                    ></i>
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                    <RenderVideos
                                                                        video={video}
                                                                        formData={formData}
                                                                        setVideo={setVideo}
                                                                        videoInputRef={videoInputRef}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">Upload Attachments</div>
                                                                <div>
                                                                    <div className="fileUpload">
                                                                        <button
                                                                            disabled={view}
                                                                            type="button"
                                                                            className="btn cus-seconday-bg-btn"
                                                                        >
                                                                            Upload Docs
                                                                        </button>
                                                                        <input
                                                                            className="hiddenFileField"
                                                                            onChange={(e) => {
                                                                                setIsFormEdited(true);
                                                                                handleDocsUpload(e, toast, error, setErrors, setAttachments, attachments)
                                                                            }}
                                                                            disabled={view}
                                                                            type="file"
                                                                            id="customFileafter"
                                                                            multiple
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {attachments.length > 0 &&
                                                                            attachments.map((item, index) => (
                                                                                <>
                                                                                    {!item.isDeleted && (
                                                                                        <div
                                                                                            className="selectedFile mr-2"
                                                                                            key={item?.id}
                                                                                        >
                                                                                            <div className="fileName">
                                                                                                <a href={item.fileUrl} target="_blank" rel="noreferrer">{item.name}</a>
                                                                                            </div>
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    handleDeleteAfterImage(index, 'attachments')
                                                                                                }
                                                                                                disabled={view}
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-times"
                                                                                                    aria-hidden="true"
                                                                                                ></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        Technicals
                                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row addressForm pl-3 pr-3">
                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Incoming Date
                                                                </div>
                                                                <div>
                                                                    <DatePicker
                                                                        disabled={view}
                                                                        className="form-control receivedDate"
                                                                        onChange={(receivedDate) =>
                                                                            handleDateChange(receivedDate, "incomingDate")
                                                                        }
                                                                        showIcon
                                                                        value={moment(formData?.incomingDate).format(
                                                                            "MM-DD-YYYY"
                                                                        )}
                                                                        name={"incomingDate"}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">Status</div>
                                                                <div>
                                                                    <select
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.inboundProductStatus_Id}
                                                                        name={"inboundProductStatus_Id"}
                                                                        className="form-control noMarginBtm"
                                                                    >
                                                                        {isCreateForm && (
                                                                            <option value={0} selected>
                                                                                Select
                                                                            </option>
                                                                        )}
                                                                        {productStatusList?.length &&
                                                                            productStatusList.map((item, index) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.status}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Process Type
                                                                </div>
                                                                <div>
                                                                    <select
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.inboundProductProcessType_Id}
                                                                        name={"inboundProductProcessType_Id"}
                                                                        className="form-control noMarginBtm"
                                                                    >
                                                                        {isCreateForm && (
                                                                            <option selected value>
                                                                                Select
                                                                            </option>
                                                                        )}
                                                                        {productProcessList?.length &&
                                                                            productProcessList.map((item, index) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.processType}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Processing Date{" "}
                                                                </div>
                                                                <div>
                                                                    <DatePicker
                                                                        disabled
                                                                        className="form-control receivedDate"
                                                                        dateFormat="MM-dd-yyyy"
                                                                        onChange={(receivedDate) =>
                                                                            handleDateChange(receivedDate, "processingDate")
                                                                        }
                                                                        showIcon
                                                                        value={
                                                                            formData?.processingDate || isCreateForm
                                                                                ? moment(formData?.processingDate).format(
                                                                                    "MM-DD-YYYY"
                                                                                )
                                                                                : null
                                                                        }
                                                                        name={"processingDate"}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Ship Date{" "}
                                                                </div>
                                                                <div>
                                                                    <DatePicker
                                                                        disabled
                                                                        className="form-control receivedDate"
                                                                        dateFormat="MM-dd-yyyy"
                                                                        onChange={(receivedDate) =>
                                                                            handleDateChange(receivedDate, "shipDate")
                                                                        }
                                                                        showIcon
                                                                        value={
                                                                            formData?.shipDate || isCreateForm
                                                                                ? moment(formData?.shipDate).format(
                                                                                    "MM-DD-YYYY"
                                                                                )
                                                                                : null
                                                                        }
                                                                        name={"shipDate"}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                                <div className="input-label-txt">
                                                                    Packing Supplies
                                                                </div>
                                                                <div className="input-box">
                                                                    <input
                                                                        disabled={view}
                                                                        onChange={handleFormChange}
                                                                        value={formData?.packingSupplies}
                                                                        name={"packingSupplies"}
                                                                        className="form-control"
                                                                        maxLength={100}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>
                                                        Warehouse
                                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        {formData?.wareHouseDetails && formData?.wareHouseDetails.map((item, index) => (
                                                            <WarehouseDetails
                                                                item={item}
                                                                key={item.id}
                                                                isEditable={!view}
                                                                handleInputBlur={handleInputBlur}
                                                                addWarehouse={addWarehouse}
                                                                index={index}
                                                                removeWarehouse={removeWarehouse}
                                                                productForm={formData}
                                                                setProductForm={setFormData}
                                                                handleWarehouse={handleWarehouse}
                                                                updateWareHouseDetails={updateWareHouseDetails}
                                                                error={error}
                                                                userDetails={userDetails}
                                                                isProduct={true}
                                                            />
                                                        ))}
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>
                                                        Notes
                                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row addressForm pl-3 pr-3">
                                                            <div className="col-12">
                                                                <div className="input-label-txt">Notes</div>
                                                                <textarea
                                                                    disabled={view}
                                                                    type={"text"}
                                                                    name="notes"
                                                                    value={formData?.notes}
                                                                    className="form-control incomingTextarea"
                                                                    onChange={handleFormChange}
                                                                    maxLength={200}
                                                                />
                                                                {error.notes && (
                                                                    <label className="requiredthis">
                                                                        *This is required
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </div>

                                {parseInt(id) !== 0 && (
                                    <div className="col-4">
                                        <div className="row mt-2">
                                            <div className="col-lg-6 col-sm-12">
                                                <h5 className="subHeadingInner2">Before Processing</h5>
                                                {formData?.beforeProcessingImages?.length > 0 ? (
                                                    <ImageSlider
                                                        isView={view}
                                                        handleMarkPrimary={handleMarkPrimary}
                                                        handleDeleteImage={handleSliderDeleteImage}
                                                        images={formData?.beforeProcessingImages.filter(
                                                            (item) => !item.isDeleted
                                                        )}
                                                        isUserAdmin={UserValue?.Value?.isSuperAdmin}
                                                        setShowLargeSliderModal={setShowBeforeProcessingModal}
                                                        showLargeSliderModal={showBeforeProcessingModal}
                                                    />
                                                ) : (
                                                    <img src={noImage} alt="" />
                                                )}
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <h5 className="subHeadingInner2">After Processing</h5>
                                                {formData?.afterProcessingImages?.length ? (
                                                    <ImageSlider
                                                        isView={view}
                                                        handleMarkPrimary={handleMarkPrimary}
                                                        images={formData?.afterProcessingImages.filter(
                                                            (item) => !item.isDeleted
                                                        )}
                                                        handleDeleteImage={handleSliderDeleteImage}
                                                        isUserAdmin={UserValue?.Value?.isSuperAdmin}
                                                        setShowLargeSliderModal={setShowAfterProcessingModal}
                                                        showLargeSliderModal={showBeforeProcessingModal}
                                                    />
                                                ) : (
                                                    <img src={noImage} alt="" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {formData?.beforeProcessingImages?.length > 0 &&
                <InLargeImageSlider
                    popupTitle={'Product'}
                    view={view}
                    handleMarkPrimary={handleMarkPrimary}
                    images={formData?.beforeProcessingImages.filter(
                        (item) => !item.isDeleted
                    )}
                    handleDeleteImage={handleSliderDeleteImage}
                    isUserAdmin={UserValue?.Value?.isSuperAdmin}
                    showModal={showBeforeProcessingModal}
                    setShowLargeSliderModal={setShowBeforeProcessingModal}
                />
            }

            {formData?.afterProcessingImages?.length > 0 &&
                <InLargeImageSlider
                    popupTitle={'Product'}
                    view={view}
                    handleMarkPrimary={handleMarkPrimary}
                    images={formData?.afterProcessingImages.filter(
                        (item) => !item.isDeleted
                    )}
                    handleDeleteImage={handleSliderDeleteImage}
                    isUserAdmin={UserValue?.Value?.isSuperAdmin}
                    showModal={showAfterProcessingModal}
                    setShowLargeSliderModal={setShowAfterProcessingModal}
                />
            }

        </main>
    );
};

export default EditProduct;
