import { combineReducers } from 'redux'
import auth from "./auth";
import message from "./message";
import company from "./company";
import warehouse from "./warehouse";
import employee from "./employee";
import seller from "./seller";
import ratetype from './ratetype';
import ratetypecategory from './ratetypecategory';
import store from './store';
import shipment from './shipment.reducers'
import sidebar from './sidebar.reducers'
import batch from './batch'
import RateCardReducer from './rateCard'
import FundManagementReducer from './fundManagement';
import WalletReducer from './wallet'
import HistoryReducer from './history'
import IncomingReducer from './incoming'
import IncomingProductReducer from './incomingProduct'
import AddressReducer from './address.reducer';
import MarginReducer from './margin.reducer';
import InventoryReducer from './inventory';
import marketplace from './marketplace';
import BarcodeReducer from './barcode.reducer';
import InventoryOutboundReducer from './inventoryOutbound';
import ServiceRateCardReducer from './serviceRateCard';
import uploads from './uploads.reducers';

export default combineReducers({
  auth: auth,
  message: message,
  company: company,
  warehouse: warehouse,
  employee: employee,
  seller: seller,
  ratetype: ratetype,
  ratetypecategory: ratetypecategory,
  store: store,
  shipment: shipment,
  sidebar: sidebar,
  batchReducer: batch,
  FundManagementReducer: FundManagementReducer,
  RateCardReducer: RateCardReducer,
  WalletReducer: WalletReducer,
  HistoryReducer: HistoryReducer,
  IncomingReducer: IncomingReducer,
  IncomingProductReducer: IncomingProductReducer,
  AddressReducer: AddressReducer,
  MarginReducer: MarginReducer,
  InventoryManagement: InventoryReducer,
  InventoryOutboundManagement: InventoryOutboundReducer,
  marketplace: marketplace,
  barcodeReducer: BarcodeReducer,
  serviceRateCardReducer: ServiceRateCardReducer,
  uploadsReducer: uploads,
})