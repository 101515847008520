import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import SelectMenu from '../common/SelectMenu';
import DatePickerComponent from '../common/DatePickerComponent';
import { useDispatch, useSelector } from "react-redux";
import { createUpdateServiceRateCard, getBillingCycleList, getChargeUnitTypeList, getPackingTypeList, getServiceTypeList, getSizeTypeList, getTransactionTypeList } from '../../store/actions/serviceRateCard.action';
import { getSelectList, transformServiceDetails } from "../../config/utils";
import { fetchCompanyFilters, getFilterSellers } from "../../store/actions/rateCard";
import { getAllSellersList } from "../../store/actions";
import moment from "moment";
import { toast } from "react-toastify";

const ServiceRateCard = (props) => {
    const { isEditable = false } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ServiceTypeList, PackingTypeList, TransactionTypeList, SizeTypeList, UnitTypeList, BillingCycleTypeList } = useSelector((state) => state.serviceRateCardReducer);
    const { filterCompanies, filterAllSellers } = useSelector((state) => state.RateCardReducer);
    const [companyList, setCompanyList] = useState([])
    const [sellerList, setSellerList] = useState([])
    const [deletedServiceRateCard, setDeletedServiceRateCard] = useState([])
    const [sellerId, setSellerId] = useState(null)
    const [error, setErrors] = useState({
        serviceDetails: [
            {
                serviceType_Id: "",
                transactionType_Id: "",
                packingType_Id: "",
                sizeType_Id: "",
                unit_Id: "",
                billingCycle_Id: "",
                amount: "",
                additionalCharge: "",
            }
        ]
    });

    const serviceRateCardObj = {
        isActive: true,
        id: Number(id),
        selectedCompany_Id: 0,
        selectedSeller_Id: 0,
        seller_Id: 0,
        validFrom: "",
        validTo: "",
        serviceDetails: [
            {
                id: Number(id),
                details_Id: 0,
                serviceType_Id: 0,
                transactionType_Id: 0,
                packingType_Id: 0,
                sizeType_Id: 0,
                unit_Id: 0,
                billingCycle_Id: 0,
                amount: "",
                additionalCharge: "",
                isServiceActive: false,
            }
        ]
    }

    const [formData, setFormData] = useState(serviceRateCardObj);

    const handleBack = () => {
        navigate('/service-rate-card');
    }

    useEffect(() => {
        let sellerData = {
            "pageIndex": 1,
            "pageSize": 10,
            "sortColumn": "name",
            "sortOrder": "ASCENDING",
            "companyIdList": [0],
            screenType: 2
        };
        let data = {
            pageIndex: 1,
            pageSize: 10,
            sortColumn: "name",
            sortOrder: "ascending",
        };
        dispatch(fetchCompanyFilters(data));
        dispatch(getFilterSellers(sellerData));
        fetchServiceTypeList();
    }, []);

    useEffect(() => {
        let sellerList = filterAllSellers?.EntityList || [];
        let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
        setCompanyList(getSelectList(companyList, 'Id', 'name'));
        setSellerList(getSelectList(sellerList, 'id', 'sellerName'));
    }, [filterCompanies, filterAllSellers]);

    const fetchServiceTypeList = () => {
        dispatch(getServiceTypeList());
        dispatch(getPackingTypeList());
        dispatch(getTransactionTypeList());
        dispatch(getSizeTypeList());
        dispatch(getChargeUnitTypeList());
        dispatch(getBillingCycleList());
    }

    const fetchSellers = async (activeSellerList) => {
        let data = {
            companyIds: [activeSellerList],
        };
        let res = await Promise.resolve(dispatch(getAllSellersList(data)));
        if (res?.data?.Status === 200) {
            let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
                (item) => {
                    return {
                        ...item,
                        label: item.sellerAndCompany,
                        value: item.id,
                    };
                }
            );
            setSellerList(dataList);
            setSellerId(dataList[0].id)
        }
    };

    const handleFormChange = (value, name) => {
        setFormData({ ...formData, [name]: value, ['seller_Id']: sellerId ? sellerId : '' });
        setErrors({ ...error, [name]: '' });
        if (name === "selectedCompany_Id") {
            let companyData = value.Id
            fetchSellers(companyData);
        }
    }

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
        setErrors({ ...error, [name]: '' });
    };

    const handleDatChange = (value, name) => {
        let dateSelected = moment(value).utc(true).format();
        setFormData({ ...formData, [name]: dateSelected })
        setErrors({ ...error, [name]: "" });
    }

    const handleValidations = () => {
        let error = { serviceDetails: [] };
        let isFormValid = true;

        const validateField = (field, fieldName, errorObj) => {
            if (!field) {
                errorObj[fieldName] = 'This is required.';
                isFormValid = false;
            }
        };

        validateField(formData.validFrom, 'validFrom', error);
        validateField(formData.validTo, 'validTo', error);

        const validateServiceFields = (service, index) => {
            const serviceErrors = {};
            const fieldsToValidate = [
                { field: service.serviceType_Id?.value, name: 'serviceType_Id' },
                { field: service.transactionType_Id?.value, name: 'transactionType_Id' },
                { field: service.packingType_Id?.value, name: 'packingType_Id' },
                { field: service.sizeType_Id?.value, name: 'sizeType_Id' },
                { field: service.unit_Id?.value, name: 'unit_Id' },
                { field: service.billingCycle_Id?.value, name: 'billingCycle_Id' },
                { field: service.amount, name: 'amount' },
                { field: service.additionalCharge, name: 'additionalCharge' }
            ];

            fieldsToValidate.forEach(({ field, name }) => {
                validateField(Number(field), name, serviceErrors);
            });

            error.serviceDetails[index] = serviceErrors;
        };

        formData.serviceDetails.forEach((service, index) => {
            validateServiceFields(service, index);
        });

        setErrors(error);
        return isFormValid;
    };

    const handleCreateUpdateServiceRateCard = () => {
        if (handleValidations()) {
            if (Number(id) === 0) {
                const simplifiedData = transformServiceDetails(formData);
                const data = JSON.parse(JSON.stringify(simplifiedData));
                dispatch(createUpdateServiceRateCard(data));
                toast.success(`Service rate card create successfully`);
                navigate('/service-rate-card');
            } else {
                console.log("err")
            }
        }
        else {
            console.log("err")
        }
    }

    const addServiceRateCard = () => {
        let data = { ...formData };
        data.serviceDetails.push({ ...serviceRateCardObj.serviceDetails[0] });
        setFormData(data);
    };

    const removeServiceRateCard = (index) => {
        let data = { ...formData };
        data.serviceDetails[index].isServiceActive = true;
        if (Number(id) > 0) {
            let deletedServiceRateCardList = [...deletedServiceRateCard];
            deletedServiceRateCardList.push(data.serviceDetails[index].id)
            setDeletedServiceRateCard(deletedServiceRateCardList);
        }
        data.serviceDetails.splice(index, 1);
        setFormData(data);
    };

    const handleSrviceDetails = (value, name, index, isNumber = false) => {
        let data = _.cloneDeep(formData);
        data.serviceDetails[index][name] = isNumber ? Number(value) : value;
        setFormData(data);
        if (error.serviceDetails?.[index]) {
            error.serviceDetails[index][name] = "";
        }
        setErrors({ ...error, [name]: "" });
    };

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <span>
                        {id === "0" && "Create Service Rate Card"}{" "}
                        {id !== "0" && isEditable && "Edit Service Rate Card"}{" "}
                        {id !== "0" && isEditable && "View Service Rate Card"}
                    </span>
                    <div className="top_right_button_container">
                        <div className="top_right_button_container">
                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                                onClick={handleCreateUpdateServiceRateCard}
                            >
                                {Number(id) ? "Save" : "Create"}
                            </button>
                            <button
                                className="btn cus-primary-transparent-btn"
                                onClick={handleBack}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>

                <div className="cus-page-content-sec">
                    <div className="incomingAccordion accordion">
                        <div className="row fontFix">
                            <div className={Number(id) > 0 ? "col-lg-9 col-sm-12" : "col-12"}>
                                <Accordion
                                    defaultActiveKey={["0", "1", "2", "3", "4", "5", "6"]}
                                    alwaysOpen={true}
                                >
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Details{" "}
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row addressForm">
                                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                    <label>Company</label>
                                                    <SelectMenu
                                                        optionList={companyList}
                                                        handleChange={(value) => { handleFormChange(value, "selectedCompany_Id") }}
                                                        value={formData.selectedCompany_Id}
                                                        placeholderText={'Select company..'}
                                                        name='selectedCompany_Id'
                                                        isMulti={false}
                                                        closeMenuOnSelect={true}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                    <label>Seller</label>
                                                    <SelectMenu
                                                        optionList={sellerList}
                                                        handleChange={(value) => { handleFormChange(value, "selectedSeller_Id") }}
                                                        value={formData.selectedSeller_Id}
                                                        placeholderText={'Select seller..'}
                                                        name='selectedSeller_Id'
                                                        isMulti={false}
                                                        closeMenuOnSelect={false}
                                                        classPrefix={'sellerField'}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                    <label>Seller ID</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        value={formData.seller_Id || ""}
                                                    />
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                    <label>Valid From <span className="error">*</span></label>
                                                    <DatePickerComponent
                                                        dateField={formData.validFrom}
                                                        handleDateChange={(value) => handleDatChange(value, "validFrom")}
                                                        fieldName="validFrom"
                                                    />
                                                    <div className='error'>{error.validFrom}</div>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                    <label>Valid To <span className="error">*</span></label>
                                                    <DatePickerComponent
                                                        dateField={formData.validTo}
                                                        handleDateChange={(value) => handleDatChange(value, "validTo")}
                                                        fieldName="validTo"
                                                    />
                                                    <div className='error'>{error.validTo}</div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            Services{" "}
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {formData?.serviceDetails && formData?.serviceDetails.map((item, index) => (
                                                <div className="row mb-15 fontFix" key={item.id}>
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                                <label>Service Type <span className="error">*</span></label>
                                                                <SelectMenu
                                                                    optionList={ServiceTypeList}
                                                                    handleChange={(value) => handleSrviceDetails(value, 'serviceType_Id', index)}
                                                                    value={item.serviceType_Id}
                                                                    placeholderText={'Select service..'}
                                                                    name='serviceType_Id'
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={false}
                                                                    isEditable={isEditable}
                                                                    classPrefix={'serviceType'}
                                                                />
                                                                <div className='error'>{error.serviceDetails[index]?.serviceType_Id}</div>
                                                            </div>

                                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                                <label>Transaction Type <span className="error">*</span></label>
                                                                <SelectMenu
                                                                    optionList={TransactionTypeList}
                                                                    handleChange={(value) => handleSrviceDetails(value, 'transactionType_Id', index)}
                                                                    value={item.transactionType_Id}
                                                                    placeholderText={'Select..'}
                                                                    name='transactionType_Id'
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={false}
                                                                    isEditable={isEditable}
                                                                    classPrefix={'transactionType'}
                                                                />
                                                                <div className='error'>{error.serviceDetails[index]?.transactionType_Id}</div>
                                                            </div>

                                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                                <label>Packing Type <span className="error">*</span></label>
                                                                <SelectMenu
                                                                    optionList={PackingTypeList}
                                                                    handleChange={(value) => handleSrviceDetails(value, 'packingType_Id', index)}
                                                                    value={item.packingType_Id}
                                                                    placeholderText={'Select..'}
                                                                    name='packingType_Id'
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={false}
                                                                    isEditable={isEditable}
                                                                    classPrefix={'packingType'}
                                                                />
                                                                <div className='error'>{error.serviceDetails[index]?.packingType_Id}</div>
                                                            </div>

                                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                                <label>Size <span className="error">*</span></label>
                                                                <SelectMenu
                                                                    optionList={SizeTypeList}
                                                                    handleChange={(value) => handleSrviceDetails(value, 'sizeType_Id', index)}
                                                                    value={item.sizeType_Id}
                                                                    placeholderText={'Select..'}
                                                                    name='sizeType_Id'
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={false}
                                                                    isEditable={isEditable}
                                                                    classPrefix={'sizeType'}
                                                                />
                                                                <div className='error'>{error.serviceDetails[index]?.sizeType_Id}</div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                                <label>Charge/Unit <span className="error">*</span></label>
                                                                <SelectMenu
                                                                    optionList={UnitTypeList}
                                                                    handleChange={(value) => handleSrviceDetails(value, 'unit_Id', index)}
                                                                    value={item.unit_Id}
                                                                    placeholderText={'Select..'}
                                                                    name='unit_Id'
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={false}
                                                                    isEditable={isEditable}
                                                                    classPrefix={'unit_Id'}
                                                                />
                                                                <div className='error'>{error.serviceDetails[index]?.unit_Id}</div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                                <label>Billing Cycle <span className="error">*</span></label>
                                                                <SelectMenu
                                                                    optionList={BillingCycleTypeList}
                                                                    handleChange={(value) => handleSrviceDetails(value, 'billingCycle_Id', index)}
                                                                    value={item.billingCycle_Id}
                                                                    placeholderText={'Select..'}
                                                                    name='billingCycle_Id'
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={false}
                                                                    isEditable={isEditable}
                                                                    classPrefix={'billingCycle_Id'}
                                                                />
                                                                <div className='error'>{error.serviceDetails[index]?.billingCycle_Id}</div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                                <label>Amount ($)<span className="error">*</span></label>
                                                                <input
                                                                    className={`form-control amount`}
                                                                    type={"number"}
                                                                    name="amount"
                                                                    value={item.amount}
                                                                    onChange={(e) => handleSrviceDetails(e.target.value, 'amount', index)}
                                                                    onBlur={handleInputBlur}
                                                                    min={0}
                                                                    disabled={isEditable}
                                                                    onKeyDown={(evt) =>
                                                                        ["e", "E", "+", "-"].includes(evt.key) &&
                                                                        evt.preventDefault()
                                                                    }
                                                                />
                                                                <div className='error'>{error.serviceDetails[index]?.amount}</div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                                                                <label>Additional Charge ($)<span className="error">*</span></label>
                                                                <input
                                                                    className={`form-control additionalCharge`}
                                                                    type={"number"}
                                                                    name="additionalCharge"
                                                                    value={item.additionalCharge}
                                                                    onChange={(e) => handleSrviceDetails(e.target.value, 'additionalCharge', index)}
                                                                    onBlur={handleInputBlur}
                                                                    min={0}
                                                                    disabled={isEditable}
                                                                    onKeyDown={(evt) =>
                                                                        ["e", "E", "+", "-"].includes(evt.key) &&
                                                                        evt.preventDefault()
                                                                    }
                                                                />
                                                                <div className='error'>{error.serviceDetails[index]?.additionalCharge}</div>
                                                            </div>
                                                            <div className='col-1 iconBtns col mb-3'>
                                                                <button
                                                                    className="addplusicon"
                                                                    onClick={addServiceRateCard}
                                                                    disabled={isEditable}
                                                                >
                                                                    <i className="fa fa-plus-circle fsIcon" aria-hidden="true"></i>
                                                                </button>
                                                                {formData?.serviceDetails?.length > 1 &&
                                                                    <button
                                                                        className="addplusicon removeParcel"
                                                                        onClick={(e) => removeServiceRateCard(index)}
                                                                        disabled={isEditable}
                                                                    >
                                                                        <i className="fa fa-minus-circle fsIcon" aria-hidden="true"></i>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="row addressForm"></div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ServiceRateCard;