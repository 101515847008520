import * as React from "react";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./RecentHistoryDialog.css";
import { fetchRecentBatchHistory, getMergedPdf } from "../../store/actions/batch.action";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

export function RecentHistoryDialog(props) {
  const { userDetails } = props;
  const [pageIndex, setPageIndex] = useState(1);
  const [batchList, setBatchList] = useState([]);
  const { recentBatchHistory, recentBatchHistoryStatus } = useSelector((state) => state?.batchReducer);
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchRecentBatch();
  }, []);

  useEffect(() => {
    if (recentBatchHistory?.totalRecords) {
      setTotalPages(Math.ceil(recentBatchHistory?.totalRecords / 10));
    }
  }, [recentBatchHistory]);

  useEffect(() => {
    if (recentBatchHistory?.list && recentBatchHistory?.list.length > 0) {
      setBatchList([...new Set([...batchList, ...recentBatchHistory?.list])]);
    }
  }, [recentBatchHistory]);

  const fetchRecentBatch = (page = false) => {
    let data = {
      pageIndex: page ? page : pageIndex,
      pageSize: 10,
      sortColumn: '',
      sortOrder: 'ASCENDING',
      sellerId: userDetails?.Value?.sellerId,
      companyId: userDetails?.Value?.companyId,
      empId: userDetails?.Value?.employeeId
    }
    dispatch(fetchRecentBatchHistory(data));
  }

  useEffect(() => {
    if (pageIndex > 1) {
      fetchRecentBatch();
    }
  }, [pageIndex]);

  const loadMoreData = () => {
    if (totalPages !== pageIndex) {
      setPageIndex(pageIndex + 1);
    }
  }

  const handleDownloadPdf = async (item) => {
    let mergedRes = await Promise.resolve(getMergedPdf(item.orderNumbersList));
    if (mergedRes && mergedRes.data && mergedRes.data.Status === 200) {
      if (mergedRes.data.Entity) {
        window.open(mergedRes.data.Entity);
      }
    }
  }

  const handleReloadList = () => {
    setBatchList([]);
    setPageIndex(1);
    fetchRecentBatch(1);
  }

  const renderData = () => {
    let content;
    if (recentBatchHistoryStatus === 'pending') {
      content = <p>Loading...</p>;
    } else if (recentBatchHistoryStatus === 'success' && !recentBatchHistory?.list?.length) {
      content = <p>No Record Found...</p>;
    } else {
      content = <></>;
    }
    return content;
  }

  return (
    <div>
      <Modal {...props} className="modalDeleteWrapper" size="lg">
        <Modal.Header closeButton>
          <div className="modal-main-div">
            <p className="modal-m-text">Recent Batch Print History</p>
          </div>
        </Modal.Header>
        <Modal.Body className="selectSvcModal">
          <div className="recentBatchList fontFix">
            {renderData()}
            <>
              {batchList?.length > 0 &&
                <InfiniteScroll
                  dataLength={recentBatchHistory?.totalRecords}
                  next={loadMoreData}
                  hasMore={totalPages === pageIndex ? false : true}
                  loader={<p>Loading...</p>}
                  height={300}
                >
                  <Table className="table table-height cus-batch-table">
                    <thead className="tbl-btch">
                      <tr className="table-rw-batch">
                        <th width={'10%'}>
                          <button onClick={handleReloadList} type="button" className="btn btn-light reloadBtn"><span className="bi bi-printer"><i className="fa fa-refresh" aria-hidden="true"></i></span></button>
                        </th>
                        <th width={'50%'}>
                          <div className="">
                            Time Printed
                          </div>
                        </th>
                        <th>
                          <div className="formLabel csv_label">
                            Book Numbers
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {batchList?.length > 0 && batchList.map((item, index) => (
                        <tr className="table-rw-batch" key={`${item.orderBatchId}`}>
                          <td>
                            <button type="button" className="btn btn-light" onClick={() => handleDownloadPdf(item)}><span className="bi bi-printer"><i className="fa fa-print" aria-hidden="true"></i></span></button>
                          </td>
                          <td>
                            <div>{item.orderCount} shipments</div>
                            <div>{moment(item.createdDate).format('MM-DD-YYYY | h:mm:ss A')}</div>
                          </td>
                          <td>
                            {item.orderNumbersList && item.orderNumbersList.map((order) => (
                              <p key={order}>{order}</p>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              }
            </>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
}
