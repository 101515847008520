import { csvListService } from '../../services'
import { createintegrationService } from '../../services/createintegration.service';
import { csvIntegrationConstant } from '../constants';

export const getCsvDataAction = (createdBy) => {
    return () => {
        return csvListService.getCsvData(createdBy);
    }
}
export const getCsvAllIntegrationAction = (data) => {
    return () => {
        return csvListService.getCsvAllIntegration(data);
    }
}
export const UpdateSkipDuplicateAddressCheckFlag = (event, autGenerateId) => {
    return () => {
        return csvListService.UpdateSkipDuplicateAddressCheckFlag(event, autGenerateId);
    }
}
export const fileUpload = (formdata) => {
    return () => {
        return csvListService.fileUpload(formdata);
    }
}
export const getIntegrationTemplateUrl = (downloadParams) => {
    return () => {
        return csvListService.getIntegrationTemplateUrlService(downloadParams);
    }
}

const getCsvColumnListSuccess = (response) => ({
    type: csvIntegrationConstant.GET_COLUMN_LIST_SUCCESS,
    payload: response,
});
const getCsvColumnListPeding = () => ({
    type: csvIntegrationConstant.GET_COLUMN_LIST_PENDING,
});
const getCsvColumnListFailed = () => ({
    type: csvIntegrationConstant.GET_COLUMN_LIST_FAILURE,
});

export const getCsvColumnList = (moduleId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(getCsvColumnListPeding());
            return csvListService.getCsvColumnListService(moduleId).then(
                (result) => {
                    dispatch(getCsvColumnListSuccess(result.data.EntityList));
                    return resolve(result.EntityList);
                },
                (error) => {
                    dispatch(getCsvColumnListFailed())
                    return reject(error);
                }
            );
        });
    }
}

export const checkIntegrationName = (integrationName) => {
    return () => {
        return createintegrationService.checkDuplicateIntegration(integrationName);
    }
}
export const mappingListBackDetails = (data) => ({
    type: csvIntegrationConstant.MAPPING_LIST_BACK_DETAILS,
    payload: data,
});

export const resetCsvColumnList = () => ({
    type: csvIntegrationConstant.RESET_COLUMNS_MAPPING_LIST,
    payload: [],
})