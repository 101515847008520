import moment from "moment";
import { allowedDocExtensions, allowedExtensions, emailRegex, SHIPVISION_AI_CONFIG } from "../constant";
import { useSpeechSynthesis } from "react-speech-kit";
import _ from "lodash";

const serviceType = [
  { id: 1, value: "CREDIT" },
  { id: 2, value: "DEBIT" },
  { id: 3, value: "ADJUSTED" },
];

export const getTransactionType = (num) =>
  serviceType.find((res) => res.id === num);

export const formatNumberWithCommas = (num) => {
  let toFixed;
  toFixed = Number(num).toFixed(2);
  return toFixed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const filterActive = (selectedData, resp) => {
  let updateSelected = [];
  if (selectedData.length && resp.length) {
    resp.forEach((item) => {
      let findRecord = selectedData.filter(
        (select) => select.orderId === item.orderId
      );
      if (findRecord.length) {
        item.isChecked = findRecord[0].isChecked;
        updateSelected.push(item);
      }
    });
  }
  return updateSelected;
};

export const handleInputValueTypes = (e, alphabeticKeys) => {
  const key = e.key;
  const isBackspace = key === "Backspace";
  const isTab = key === "Tab";
  const isSpace = key === " ";
  const isArrowKey = /^(Arrow)/.test(key);

  if (
    (!key?.match(alphabeticKeys) && !isBackspace && !isSpace && !isTab) &&
    !isArrowKey
  ) {
    return e?.preventDefault();
  }
};

export const fundTransactionFilter = [
  {
    label: "SHIPPING LABEL",
    value: 2
  },
  {
    label: "WALLET RECHARGE",
    value: 1
  },
  {
    label: "MISC-CREDIT",
    value: 4
  },
  {
    label: "MISC-DEBIT",
    value: 5
  },
  {
    label: "WALLET TOP-UP",
    value: 6
  },
]

export const validDecimalNum = /^[0-9]*(\.[0-9]{0,3})?$/;

export const saveModelObject = {
  id: 0,
  integrationname: "",
  isactiveintegration: true,
  isskipaddresspreverification: true,
  issendconfirmationemail: true,
  isincludepackagelipinemail: true,
  includepackageslipinemaildesc: "",
  isheaderrowtoimport: true,
  isautgenerateorderid: true,
  isskipduplicatecheck: true,
  isactive: true,
  createdBy: "",
  modifiedBy: "",
  csvuploadcolumnsid: 0,
  sellerName: "",
  csvintegrationcolumnsmapping: [
    {
      integrationid: 0,
      orderid: "",
      orderdate: "",
      ordernumber: "",
      shippingservicerelated: "",
      ordergroup: "",
      packagelength: "",
      packagewidth: "",
      packageheight: "",
      orderweight: "",
      shippingtotal: "",
      ponumber: "",
      printorder: "",
      shippingreference: "",
      shippingreference2: "",
      departmentnumber: "",
      senderprefix: "",
      sendername: "",
      senderfirstname: "",
      senderlastname: "",
      sendercompany: "",
      senderaddressline1: "",
      senderaddressline2: "",
      senderaddressline3: "",
      sendercity: "",
      senderstate: "",
      senderzip: "",
      sendercountrycode: "",
      senderemail: "",
      senderphone: "",
      receiverprefix: "",
      receivername: "",
      receiverfirstname: "",
      receiverlastname: "",
      receivercompany: "",
      receiveraddressline1: "",
      receiveraddressline2: "",
      receiveraddressline3: "",
      receivercity: "",
      receiverstate: "",
      receiverzip: "",
      receivercountrycode: "",
      receiveremail: "",
      receiverphone: "",
      returnprefix: "",
      returnname: "",
      returnfirstname: "",
      returnlastname: "",
      returncompany: "",
      returnaddressline1: "",
      returnaddressline2: "",
      returnaddressline3: "",
      returncity: "",
      returnstate: "",
      returnzip: "",
      returncountrycode: "",
      returnemail: "",
      returnphone: "",
      productid: "",
      productsku: "",
      producttitle: "",
      productprice: "",
      productquantity: "",
      productshippingweight: "",
      producttotalonorder: "",
      productattribute: "",
      producteccn: "",
      producthtsnumber: "",
      productvariantname: "",
      productvariantvalue: "",
      vatioss: "",
      vatiosstaxidissuercountrycode: "",
      marketplaceintegrationid: "",
      marketplacename: ""
    },
  ],
  bulkUploadModuleId: 0
};

export const convertIntoKilo = (grams) => {
  return grams / 1000;
};

export const convertToLb = (ounce) => {
  return ounce / 16;
};

export const convertToCm = (inch) => {
  return parseFloat(Number(inch * 2.54).toFixed(3));
}

export const convertToInch = (cm) => {
  return parseFloat(Number(cm / 2.54).toFixed(3));
};

export const validateEmail = (email) => {
  return email && emailRegex.test(email);
}

export const validateZipCode = (zipCode) => {
  const zipCodeRegex = /^\d{5}(-\d{4})?$/;
  return zipCodeRegex.test(zipCode);
};

export const validatePhoneNumber = (phoneNumber) => {
  return phoneNumber && phoneNumber.replace(/\D/g, "").length < 10;
};

export const validateZipCodeLength = (zipCode) => {
  return zipCode && zipCode.length < 4;
};


export const dateDifference = (startDate, endDate) => {
  return moment(startDate).diff(moment(endDate), 'minutes');
}

export const phoneMask = [
  // { mask: '000-000-0000' },
  { mask: '+(0) 000-000-0000' },
  { mask: '+(00) 000-000-0000' },
  { mask: '+(000) 000-000-0000' },
  { mask: '+(0000) 000-000-0000' },
  { mask: '+(00000) 000-000-0000' },
];

export const getSelectList = (data, value, label) => {
  let list = data.map(item => ({ ...item, value: item[value], label: item[label] }));
  return list;
}

export const filterFromList = (list, filterId) => {
  let filteredData = list.filter(item => item.id === filterId);
  return filteredData;
}

export const convertToFixed = (num, fixed) => {
  let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

export const renderTableRow = (span, message, align = 'center') => {
  return (
    <tr>
      <td colSpan={span} align={align}>{message}</td>
    </tr>
  )
}

export const mergeColumns = (columnList) => {
  const mergedObject = {};
  columnList.forEach(column => {
    mergedObject[column.ColumnName] = column.ColumnValueName;
  });
  return [mergedObject];
}

export const appendFilesToUploadData = (uploadData, files, key) => {
  if (files) {
    for (let i = 0; i < files.length; i++) {
      uploadData.append(key, files[i]);
    }
  }
  return uploadData;
};

export const isUrl = string => {
  try { return Boolean(new URL(string)); }
  catch (e) { return false; }
}

export const findConfiguration = (configKey, configList) => {
  let findConfig = configList.filter((item) => item.configKey === configKey)[0];
  return findConfig.configValue;
}

export const findBoxesChecked = (elems) => {
  let cloneElems = JSON.parse(JSON.stringify(elems));
  let checkedItems = [];
  cloneElems?.map((item => {
    return item?.consignmentElements?.filter((box) => {
      if (box.isChecked) {
        checkedItems.push(box);
      }
    });
  }));
  return checkedItems;
}

export async function convertFilesToBase64(files) {
  const base64Array = [];
  for (const file of files) {
    const base64String = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result.split(',')[1]); // Extract base64 part
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
    base64Array.push({ name: file.name, file: base64String, id: 0, extension: `.${file.name.split('.')[1]}` });
  }
  return base64Array;
}

export const findCount = (newData) => {
  let consignments = [];
  newData.forEach(function (brand) {
    brand.consignmentElements = brand.consignmentElements.map(function (subBrand) {
      consignments.push(subBrand);
      return subBrand
    })
  });
  return consignments.length;
}

export const createElemPayload = (carrierTypeId, consignmentElements) => {
  if (carrierTypeId === 1) {
    return consignmentElements;
  } else if (carrierTypeId === 2) {
    let returnBoxes = consignmentElements[0].consignmentElements;
    return returnBoxes;
  } else if (carrierTypeId === 3) {
    let items = consignmentElements[0].consignmentElements[0].consignmentElements;
    return items;
  }
}

export const findIndexWithChecked = (data) => {
  let index = 0;
  for (const parentObj of data) {
    const consignmentElements = parentObj.consignmentElements;
    for (const element of consignmentElements) {
      if (element.isChecked) {
        return index;
      }
    }
    index++;
  }
  return -1;
}

export const transformedData = (originalData) => {
  let data = originalData
    .filter(item => item.isChecked && item.id > 0)
    .map(item => {
      return {
        consignmentType: item.consignmentTypeId,
        id: item.id ? item.id : 0
      };
    });
  return data
};

const handleStatusReturn = (consignmentStatusIdReceived, consignmentStatusId, defaultConsignmentStatusId, elements) => {
  let status;
  let elementsLength;
  elementsLength = elements.length
  if (consignmentStatusIdReceived && consignmentStatusIdReceived !== elementsLength) {
    status = 4;
  } else if (consignmentStatusIdReceived === elementsLength) {
    status = 3;
  } else if (consignmentStatusId) {
    status = 2;
  } else {
    status = defaultConsignmentStatusId.consignmentStatusId;
  }
  return status;
}

export const findElementStatus = (elements, formData, consignmentTypeId) => {
  let elementsItem = [...elements];
  let consignmentStatusId = 0;
  let consignmentStatusIdReceived = 0;
  if (elementsItem.length) {
    consignmentStatusId = elementsItem.filter((pallet) => Number(pallet.consignmentStatusId) === 2).length;
    consignmentStatusIdReceived = elementsItem.filter((pallet) => Number(pallet.consignmentStatusId) === 3).length;
  }
  if (consignmentStatusId || consignmentStatusIdReceived) {
    return handleStatusReturn(consignmentStatusIdReceived, consignmentStatusId, formData, elements);
  }

  return formData.consignmentStatusId;
};

export const downloadPDF = (pdf) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = "file.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export const handleImageUpload = (e, toast, error, setErrors, setImage, images, isMulti = false) => {
  let files = [...e.target.files];
  try {
    if (files.length > 0 && files.length <= 5) {
      let filterFiles = files.filter((file, index) => {
        const extension = file.name.split(".").slice(-1).pop();
        const fileSize = Math.round(file.size / 1024);
        if (
          !allowedExtensions.includes(extension ? extension.toLowerCase() : "")
        ) {
          files.splice(index, 1);
          toast.error("Unsupported File format", 1000);
          return false;
        } else if (fileSize > 1024) {
          files.splice(index, 1);
          e.target.value = "";
          toast.info("Each picture size should be less than 1MB", 1000);
          return false;
        }
        return file;
      });

      setErrors({ ...error, image: "" });
      if (isMulti) {
        setImage([...images, ...filterFiles]);
      } else {
        setImage(filterFiles);
      }
    } else if (files.length > 5) {
      toast.info("Only 5 images can be upload at one time", 1000);
      e.target.value = "";
    } else {
      e.target.value = "";
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleDocsUpload = (e, toast, error, setErrors, setFile, attachmentFile = []) => {
  let files = [...e.target.files];
  try {
    if (files.length > 0 && files.length <= 5) {
      let filterFiles = files.filter((file, index) => {
        const extension = file.name.split(".").slice(-1).pop();
        const fileSize = Math.round(file.size / 1024);
        if (
          !allowedDocExtensions.includes(
            extension ? extension.toLowerCase() : ""
          )
        ) {
          files.splice(index, 1);
          toast.error("Unsupported File format", 1000);
          return false;
        } else if (fileSize > 1024) {
          files.splice(index, 1);
          e.target.value = "";
          toast.info("Each file size should be less than 1MB", 1000);
          return false;
        }
        return file;
      });
      setErrors({ ...error, file: "" });
      setFile([...attachmentFile, ...filterFiles]);
    } else if (files.length > 5) {
      toast.info("Only 5 images can be upload at one time", 1000);
      e.target.value = "";
    } else {
      e.target.value = "";
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleMapping = (columnList, formData) => {
  let columnListData = [...columnList];
  let formDataObj = { ...formData };
  let map = columnListData.map((item) => {
    let findIndex = formDataObj.barcodeintegrationcolumnsmappingbyname.findIndex((col) => col.ColumnName === item.columnName);
    return {
      ...item,
      value: findIndex > -1 ? formDataObj.barcodeintegrationcolumnsmappingbyname[findIndex].ColumnValueName : '',
    }
  });
  return map;
};

export const handleColumnsLogic = (list, columnList) => {
  let columnsListData = [...columnList];
  let mapData = columnsListData.map((item) => {
    let findItem = list.findIndex(col => col.name === item.columnName);
    return {
      ...item,
      value: findItem > -1 ? item.value : ''
    }
  });
  return mapData;
};

export const useTextToSpeech = () => {
  const { speak } = useSpeechSynthesis();

  const handleSpeak = (item) => {
    speak({ text: item });
  };

  return {
    handleSpeak,
  };
};

export const checkForKeyWithTrueValue = (data, keyToCheck) => {
  return data[keyToCheck] === true;
};

export const getUserRoleName = (roleList, roleName, roleId) => {
  if (roleList && roleId) {
    let filterByName = roleList.filter((item) => item.id === roleId.id);
    if (filterByName[0].name === roleName) {
      return true;
    } else {
      return false;
    }
  }
}

export const handleInfoTooltip = (type, quantity) => {
  let baseMessage = '';
  let additionalMessage = '';

  if (type === 'Pallet') {
    baseMessage = 'This field defines how many boxes are there in this Pallet.';
    if (Number(quantity)) {
      additionalMessage = ` In this case, the Pallet is having ${quantity} number of boxes.`;
    }
  } else if (type === 'Box') {
    baseMessage = 'This field defines the number of different SKUs present in your box. E.g. - Box1 has Water Bottle and glasses, then this field should have value as 2.';
    if (Number(quantity)) {
      additionalMessage = ` In this case, the Box is having ${quantity} number of different SKUs.`;
    }
  } else if (type === 'Item') {
    baseMessage = 'This field defines the total number of quantity of single SKU. Like 50 Water bottles.';
    if (Number(quantity)) {
      additionalMessage = ` In this case, the quantity of item is ${quantity}.`;
    }
  }

  return baseMessage + additionalMessage;
};

export const findItems = (pallet) => {
  let consignments = [];
  pallet.consignmentElements.forEach(function (brand) {
    brand.consignmentElements = brand.consignmentElements && brand.consignmentElements.forEach(function (subBrand) {
      if (subBrand.shippingStatusId > 0) {
        consignments.push(subBrand)
      }
    })
  });
  return consignments;
}

export const renderConsignmentType = (consignmentTypeId, isQuantity) => {
  const id = Number(consignmentTypeId);

  if (isQuantity) {
    switch (id) {
      case 1:
        return 'Quantity (Number of Pallet)';
      case 2:
        return 'Quantity (Number of Box)';
      case 3:
        return `Quantity (Total qty of this SKU).`;
      default:
        return 'Quantity';
    }
  } else {
    switch (id) {
      case 1:
        return '- Boxes (Number of Boxes in this Pallet)';
      case 2:
        return ' (Number of Different SKUs in this Box)';
      default:
        return '';
    }
  }
}

export const renderTooltip = (consignmentTypeId, subQuantity) => {
  switch (consignmentTypeId) {
    case 1:
      let message1 = "This field defines how many boxes are there in this Pallet.";
      if (subQuantity) {
        message1 += ` In this case, the Pallet is having ${subQuantity} number of boxes.`;
      }
      return message1;
    case 2:
      let message2 = "This field defines the number of different SKUs present in your box. E.g. - Box1 have Water Bottle and glasses, then this field should have value as 2.";
      if (subQuantity) {
        message2 += ` In this case, the Box is having ${subQuantity} number of different SKUs.`;
      }
      return message2;
    default:
      let message = "This field defines the total number of quantity of single SKU. Like 50 Water bottles.";
      if (subQuantity) {
        message += ` In this case, the quantity of item is ${subQuantity}.`;
      }
      return message;
  }
};

export const addIsImportedFlag = (dataList) => {
  return dataList.map((attachments) => {
    return {
      ...attachments,
      isImported: true
    }
  });
}

export const quantityTextRender = (quantity) => {
  let baseMessage = 'This field defines the total number of quantity of single SKU. Like 50 Water bottles.';
  let additionalMessage = '';

  if (quantity) {
    additionalMessage = `In this case, the quantity of item is ${quantity}.`
  }
  return baseMessage + additionalMessage;
}
export const handleMultiSelect = (value, fieldName, filterData, setfilterData, companyfieldName, sellerfieldName, fetchSellers) => {
  setfilterData({
    ...filterData,
    [fieldName]: value,
  });
  if (fieldName === companyfieldName) {
    const removedCompanies = filterData[companyfieldName].filter(
      (company) => !value.some((selected) => selected.Id === company.Id)
    );

    let companyData = _.map(value, "Id");
    fetchSellers(companyData);

    let updatedSellerIdList = filterData[sellerfieldName].filter(
      (seller) => !removedCompanies.some((company) => company.Id === Number(seller.companyIds))
    );

    setfilterData({
      ...filterData,
      [fieldName]: value,
      sellerIdList: updatedSellerIdList
    });

    if (!value.length) {
      setfilterData({
        ...filterData,
        [fieldName]: value,
        sellerIdList: []
      });
    }
  }
};

export const filterInMultiArray = (listToFilter, list) => {
  const result = listToFilter?.filter(c => list.some(s => s.id === c.id));
  return result;
}

export const checkSellersInCompanyList = (companyList, sellerList) => {
  let returnForm = companyList?.every(company => {
    // Check if there exists at least one seller with matching companyIds
    return sellerList?.some(seller => Number(seller.companyIds) === Number(company.Id));
  });
  return returnForm;
}

export const currentYear = new Date().getFullYear();

export const checkPermission = (featureId, subFeatureId, permissionId, moduleType, employeePermissions) => {
  let cloneEmployeePermissions = _.cloneDeep(employeePermissions)
  let feature = cloneEmployeePermissions?.length && cloneEmployeePermissions.find(item => item.featureId === featureId && item.permissionHave);
  if (!feature) return false;

  if (moduleType === 1) return true;

  if (moduleType >= 2) {
    let subFeature = feature.SubFeatures?.find(subFeature => subFeature.subFeatureId === subFeatureId && subFeature.permissionHave);
    if (!subFeature) return false;

    if (moduleType === 2) return true;

    if (moduleType === 3) {
      let permission = subFeature.Permissions?.find(permission => permission.permissionId === permissionId && permission.permissionHave);
      return !!permission;
    }
  }

  return false;
}

export const importProduct = (data) => {
  let productData = data.map((item) => {
    return {
      ...item,
      name: item.productName,
      productSKU: item.productSku,
      productSKUCopy: item.productSku,
      availableQuantity: item.quantity,
      weightUnitId: item.unit,
      dimensionUnitId: item.dimensionUnit,
      productId: item.id,
      id: 0,
      label: item.productSku,
      value: item.id,
    }
  });
  return productData;
}

export const transformData = (data) => {
  let result = {};

  data.forEach(feature => {
    result[feature.featureCode] = {
      permissionHave: feature.permissionHave,
      SubFeatures: {}
    };

    feature.SubFeatures.forEach(subFeature => {
      result[feature.featureCode].SubFeatures[subFeature.subFeatureCode] = {
        permissionHave: subFeature.permissionHave
      };
    });
  });

  return result;
}

export const findIsHostShipAi = () => {
  let hostName = window.location.hostname;
  if (hostName.includes(SHIPVISION_AI_CONFIG)) {
    return true;
  }
  return false;
}

export const findPermissionByRole = (role, permissions, roleList) => {
  if (roleList.length) {
    let findRoleByName = roleList.filter(item => item.name === role);
    return permissions.filter(item => item.roleId === findRoleByName[0].id && item.permissionHave).length > 0;
  }
}

export const mergeArrays = (array1, array2, key, keepKeysFromArray1 = []) => {
  return array2.map(item2 => {
    // Find corresponding item from array1
    let item1 = array1.find(item1 => item1[key] === item2[key]);

    if (item1) {
      // Copy specific keys from array1 to item2 if they exist
      keepKeysFromArray1.forEach(prop => {
        if (item1.hasOwnProperty(prop)) {
          item2[prop] = item1[prop];
        }
      });

      // Merge sub-features if they exist
      if (item1.SubFeatures && item2.SubFeatures) {
        item2.SubFeatures = mergeArrays(item1.SubFeatures, item2.SubFeatures, 'subFeatureId', keepKeysFromArray1);
      }
    }

    return item2;
  });
}

export const consignmentsTypes = {
  1: 'Pallet',
  2: 'Box',
  3: 'Item'
};

export const handleDocUploadBase64 = (e, toast) => {
  let files = [...e.target.files];
  try {
    if (files.length > 0 && files.length <= 5) {
      let filterFiles = files.filter((file, index) => {
        const extension = file.name.split(".").slice(-1).pop();
        const fileSize = Math.round(file.size / 1024);

        if (!allowedDocExtensions.includes(extension ? extension.toLowerCase() : "")) {
          files.splice(index, 1);
          toast.error("Unsupported File format", 1000);
          return false;
        } else if (fileSize > 1024) {
          files.splice(index, 1);
          e.target.value = "";
          toast.info("Each picture size should be less than 1MB", 2000);
          return false;
        }
        return file;
      });
      return filterFiles;
    } else if (files.length > 5) {
      toast.info("Only 5 images can be upload at one time", 1000);
      e.target.value = "";
    } else {
      e.target.value = "";
    }
  } catch (err) {
    console.log(err);
  }
}

const validateItems = (items, invalidRecords) => {
  items.forEach((item) => {
    if (item.consignmentTypeId === 3 && (!item.incomingQuantity || item.incomingQuantity === "0")) {
      invalidRecords.push({
        name: item.name,
        consignmentTypeId: item.consignmentTypeId,
        incomingQuantity: item.incomingQuantity,
      });
    }
  });
};

// Process based on consignmentTypeId
const processRecords = (records, invalidRecords) => {
  records.forEach((record) => {
    if (record.consignmentElements && record.consignmentElements.length) {
      record.consignmentElements.forEach((box) => {
        if (box.consignmentElements && box.consignmentElements.length) {
          validateItems(box.consignmentElements, invalidRecords);
        }
      });
    } else {
      validateItems(record.consignmentElements || []);
    }
  });
};

export const findInvalidRecords = (records, consignmentTypeId) => {
  const invalidRecords = [];
  // Helper function to validate items
  if (consignmentTypeId === 1 || consignmentTypeId === 2) {
    processRecords(records || [], invalidRecords);
  } else if (consignmentTypeId === 3) {
    validateItems(records || [], invalidRecords);
  }
  return invalidRecords;
};

export const filterConsignments = (elements) => {
  return elements.some((element) =>
    !element.name ||
    !element.productSKU ||
    Number(element.shippingStatusId) === 0
  );
}

export const updateSelectedList = (listItem, selectedData) => {
  return listItem.map(item => {
    const match = selectedData.find(data => data.columnId === item.id);
    if (match) {
      return { ...item, mapId: match.columnValue }; // Add `orderid` if there's a match
    }
    return item; // Return the original item if no match
  });
}

export const integrationDataPayload = (tableRows, csvColumnList) => {
  let result = csvColumnList
    .filter(item1 => tableRows.some(item2 => item1.mapId && item1.mapId === item2.mapId))
    .map(item1 => {
      let match = tableRows.find(item2 => item2.mapId === item1.mapId);
      return {
        ColumnName: item1.Name,
        ColumnValueName: match.name
      };
    });
  return result;
}

export const transformServiceDetails = (data, id) => {
  const transformedDetails = data.serviceDetails.map(detail => ({
    id: 0,
    details_Id: 0,
    serviceType_Id: detail.serviceType_Id?.id,
    transactionType_Id: detail.transactionType_Id?.id,
    packingType_Id: detail.packingType_Id?.id,
    sizeType_Id: detail.sizeType_Id?.id,
    unit_Id: detail.unit_Id?.id,
    billingCycle_Id: detail.billingCycle_Id?.id,
    amount: detail.amount,
    additionalCharge: detail.additionalCharge,
    isServiceActive: true,
  }));

  return {
    isActive: true,
    id: Number(id),
    selectedCompany_Id: data.selectedCompany_Id.Id,
    selectedSeller_Id: data.selectedSeller_Id.id,
    seller_Id: data.seller_Id,
    validFrom: data.validFrom,
    validTo: data.validTo,
    serviceDetails: transformedDetails,
  };
};