import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteOrder,
    fetchAddressFromZipCode,
    getAllCountries,
    getAllPackagesByServiceId,
    getAllServices,
    getSignatureOptionList,
    getStates,
    updateAddress
} from '../../../store/actions';
import moment from 'moment';
import _ from 'lodash';
import { toast } from "react-toastify";
import AlertDialogModal from '../../common/AlertDialog';
import "./AddressWidget.css";
import Parcels from './ParcelsComponent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOrderDetailsById } from '../../../store/actions/batch.action';
import Input from 'react-phone-number-input/input';
import { batchModule, featureSubmodulePermission, orderManagement, permissionPrintLabel, permssionDelete, permssionEdit } from '../../../constant';
import { checkPermission } from '../../../config/utils';

const UpdateAddressWidget = (props) => {
    const { selectedData, setSelectedData, createdBy, fetchAllBatches } = props;
    const dispatch = useDispatch();
    const [order, setOrder] = useState({});
    const [orderCopy, setOrderCopy] = useState({});
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [dateError, setDateError] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [selectedCarrier, setSelectedCarrier] = useState([]);
    const [selectedPackage, setSelectedPackages] = useState({});
    const [displayAddressSettings, setDisplayAddressSettings] = useState({
        showAddressShort: true,
        editReturnAddress: true,
        editToAddress: true,
    });
    const services = useSelector((state) => state.shipment?.services);
    const packages = useSelector((state) => state.shipment?.packages);
    const USSates = useSelector((state) => state.shipment?.states);
    const countries = useSelector((state) => state.shipment?.countries);
    const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);

    useEffect(() => {
        if (order.carrierCompanyId && services?.length) {
            let getCarrier = services.filter((item) => item.carrierCompanyId === Number(order.carrierCompanyId));
            setSelectedCarrier(getCarrier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order.carrierCompanyId, services]);

    useEffect(() => {
        if (order.receiverCountryID) {
            if (Number(order.receiverCountryID) !== 218) {
                dispatch(getAllServices(true, false));
            } else {
                if ((order.sState === "AA" || order.sState === "AE" || order.sState === "AP")) {
                    dispatch(getAllServices(false, true));
                } else {
                    dispatch(getAllServices(false, false));
                }
            }
        }
    }, [order])

    useEffect(() => {
        if (!USSates?.length) {
            dispatch(getStates(218));
        }
        if (!countries?.length) {
            dispatch(getAllCountries());
        }
    }, [createdBy]);

    useEffect(() => {
        let findDiff = _.isEqual(orderCopy, order);
        setIsFormEdited(findDiff);
    }, [order]);

    useEffect(() => {
        if (selectedData?.length === 1) {
            getSelectedOrderById();
        }
    }, [selectedData]);

    const getSelectedOrderById = async () => {
        let dataRes = await getOrderDetailsById(selectedData[0]?.orderId);
        if (dataRes?.data?.Status === 200) {
            let res = { ...dataRes?.data?.Entity };
            if (res?.shipmentDate) {
                let currentDate = new Date();
                let selectedDate = new Date(res?.shipmentDate);
                selectedDate.setHours(0, 0, 0, 0);
                currentDate.setHours(0, 0, 0, 0);
                if (moment(selectedDate).isBefore(currentDate)) {
                    setDateError('Shipment Date cannot be in the past')
                } else {
                    setDateError('')
                }
            } else {
                res.shipmentDate = new Date();
            }

            if (!res?.parcels?.length) {
                res.parcels = [{
                    createdBy: createdBy?.Value?.employeeId,
                    createdDate: new Date().toISOString(),
                    modifiedBy: 0,
                    modifiedDate: new Date().toISOString(),
                    isActive: true,
                    id: 0,
                    shipmentID: 0,
                    box: 0,
                    weight: null,
                    ounces: null,
                    length: null,
                    width: null,
                    height: null,
                    sku: "",
                    cnName: "",
                    enName: "",
                    price: 0,
                    num: 0,
                    material: "",
                    use: "",
                    cWeight: 0,
                    hsCode: "",
                    battery: "",
                }]
            }
            setOrderCopy(JSON.parse(JSON.stringify(res)));
            setOrder(JSON.parse(JSON.stringify(res)));
        }
    }

    useEffect(() => {
        if (order.serviceID) {
            selectServicePackage(order.serviceID);
            dispatch(getSignatureOptionList(order.serviceID));
        }
    }, [order.serviceID]);

    const selectServicePackage = (value) => {
        dispatch(getAllPackagesByServiceId(value));
    }

    const handleToggleFromAddress = () => {
        setDisplayAddressSettings({
            ...displayAddressSettings,
            showAddressShort: !displayAddressSettings.showAddressShort
        })
    };

    const handleEditAddressTo = () => {
        setDisplayAddressSettings({
            ...displayAddressSettings,
            editReturnAddress: !displayAddressSettings.editReturnAddress
        });
    };

    const handleEditReturnTo = () => {
        setDisplayAddressSettings({
            ...displayAddressSettings,
            editToAddress: !displayAddressSettings.editToAddress
        });
    };

    useEffect(() => {
        if (packages.length && order.packageId) {
            let findPackage = packages.filter((item) => item.id === Number(order.packageId));
            setSelectedPackages(findPackage[0]);
        }
    }, [order.packageId, packages]);

    useEffect(() => {
        if (selectedPackage?.shouldAutofill) {
            let copyShipObj = { ...order };
            let updateParcel = copyShipObj.parcels.map((item, index) => {
                return {
                    ...item,
                    length: selectedPackage.length,
                    width: selectedPackage.width,
                    height: selectedPackage.height,
                };
            });
            copyShipObj.parcels = updateParcel;
            setOrder(copyShipObj);
        }
    }, [selectedPackage]);

    const handleReturnAddressChecked = (e, prop) => {
        const { checked } = e.target;
        let orderObj = { ...order };
        orderObj[prop] = checked;
        setOrder(orderObj);
    }

    const handleShipDate = (value) => {
        let currentDate = new Date();
        let selectedDate = new Date(value);
        selectedDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        if (moment(selectedDate).isBefore(currentDate)) {
            setDateError('Shipment Date cannot be in the past')
        } else {
            setDateError('')
        }
    }

    const updateUserInput = (e, prop) => {
        const { value } = e.target;
        let orderObj = { ...order };

        if (prop === 'shipmentDate') {
            handleShipDate(value);
        }
        if (prop === 'carrierCompanyId' && value === '0') {
            orderObj.serviceID = 0;
            orderObj.packageId = 0;
        }

        if (prop === 'serviceID') {
            orderObj.serviceName = selectedCarrier[0]?.services.filter((item) => item.id === Number(value))[0].serviceName;
            orderObj[prop] = value;
            orderObj.packageId = 0
        }
        if (prop === 'packageId') {
            orderObj.packageName = packages.filter((item) => item.id === Number(value))[0]?.packageName || 0;
        }

        if (prop === 'signatureId') {
            orderObj[prop] = parseInt(value);
        } else if (prop === 'carrierCompanyId') {
            orderObj[prop] = value;
            orderObj.carrierCompanyName = services.filter((item) => item.carrierCompanyId === Number(value))[0]?.carrierCompanyName;
        } else if (prop === 'retrunCountryID') {
            let allCountries = [...countries];
            let countryIndex = allCountries.findIndex(
                (item) => item.id.toString() == value
            );
            orderObj.rCountry = countries[countryIndex].iSO;
            orderObj.returnphone = `+(${countries[countryIndex].phoneCountryCode}) `;
            orderObj[prop] = value;
        } else if (prop === 'receiverCountryID') {
            let allCountries = [...countries];
            let countryIndex = allCountries.findIndex(
                (item) => item.id.toString() == value
            );
            orderObj.fCountry = countries[countryIndex].iSO;
            orderObj.sTel = `+(${countries[countryIndex].phoneCountryCode}) `;
            orderObj[prop] = value;
        } else if (prop === 'senderCountryID') {
            let allCountries = [...countries];
            let countryIndex = allCountries.findIndex(
                (item) => item.id.toString() == value
            );
            orderObj.fTel = `+(${countries[countryIndex].phoneCountryCode}) `;
            orderObj.fCountry = countries[countryIndex].iSO;
            orderObj[prop] = value;
        } else {
            if (prop === 'shipmentDate') {
                orderObj[prop] = moment(value).utc(true).format();
            } else {
                orderObj[prop] = value;
            }
        }
        setOrder(orderObj);
    };

    const handleAddressSave = async (addressType) => {
        let orderObj = { ...order };
        let validParcels = orderObj.parcels && orderObj.parcels.filter((item) => item.ounces || item.weight || item.length || item.width || item.height);
        orderObj.parcels = validParcels;
        orderObj.retrunCountryID = parseInt(order.retrunCountryID);
        orderObj.carrierCompanyId = parseInt(order.carrierCompanyId);
        orderObj.serviceID = parseInt(order.serviceID);
        orderObj.packageId = parseInt(order.packageId);
        let res = await updateAddress(orderObj);

        if (res?.data?.Status === 200) {
            if (addressType === 'returnAddress' || addressType === 'addressTo') {
                setDisplayAddressSettings({
                    ...displayAddressSettings,
                    showAddressShort: true,
                    editReturnAddress: true,
                    editToAddress: true,
                });
            }
            props.getAllRecords(() => {
                toast.success("Orders Updated Successfully");
            });
        }
    };

    const handleDeleteOrder = () => {
        setModalShow(!modalShow);
    };

    const handleYesDeleteAlert = async () => {
        let deleteOrders = [selectedData[0].orderId];
        let data = {
            id: deleteOrders,
            modifiedBy: createdBy?.Value?.employeeId,
            createdBy: createdBy?.Value?.employeeId,
            sellerId: createdBy?.Value?.sellerId,
            companyId: createdBy?.Value?.companyId,
        }
        let deleteRes = await deleteOrder(data);

        if (deleteRes?.data?.Status === 200) {
            toast.success("Order Deleted Successfully");

            setSelectedData([]);
            setModalShow(!modalShow);
            props.getAllRecords();
            fetchAllBatches();
        }
    }

    const handleReceiverObj = (orderObj, address) => {
        orderObj.serviceName = "";
        orderObj.serviceID = 0;
        orderObj.packageName = "";
        orderObj.receiverCountryID = countries.filter((country) => country.name === address?.Country)[0].id || orderObj.receiverCountryID;
        orderObj.sCountry = countries.filter((country) => country.name === address?.Country)[0].iSO;
        orderObj.sState = address.Country === 'United States' ? USSates.filter((state) => state.StateName === address?.State)[0].StateCode : address?.State;
        orderObj.sCity = address.City || orderObj.sCity;
        return orderObj;
    }
    const handleSender = (orderObj, address) => {
        orderObj.senderCountryID = countries.filter((country) => country.name === address?.Country)[0].id || orderObj.senderCountryID;
        orderObj.fCountry = countries.filter((country) => country.name === address?.Country)[0].iSO;
        orderObj.fState = address.Country === 'United States' ? USSates.filter((state) => state.StateName === address?.State)[0].StateCode : address?.State;
        orderObj.fCity = address.City || orderObj.fCity;
        return orderObj;
    }
    const handleReturnAddress = (orderObj, address) => {
        orderObj.retrunCountryID = countries.filter((country) => country.name === address?.Country)[0].id || orderObj.retrunCountryID;
        orderObj.returnstate = countries.filter((country) => country.name === address?.Country)[0].iSO;
        orderObj.returnstate = address.Country === 'United States' ? USSates.filter((state) => state.StateName === address?.State)[0].StateCode : address?.State;
        orderObj.returncity = address.City || orderObj.fCity;
        return orderObj;
    }
    const fetchAddress = async (zipCode, type) => {
        let responseAddress = await Promise.resolve(fetchAddressFromZipCode(zipCode));
        if (responseAddress?.data?.Status === 200) {
            let address = responseAddress?.data?.Entity;
            if (address && address?.Country) {
                let orderObj = { ...order };
                if (type === 'receiver') {
                    orderObj = handleReceiverObj(orderObj, address);
                } else if (type === 'sender') {
                    orderObj = handleSender(orderObj, address);
                } else if (type === 'returnAddress') {
                    orderObj = handleReturnAddress(orderObj, address);
                }
                setOrder(orderObj);
            }
        }
    }

    return (
        <div className='editAddressBox widgetBox noOrderSelected'>
            <h2>Edit Selected Order</h2>
            {/* {order.orderID && */}
            <div className='addressBox fontFix'>
                <div className='addressFrom'>
                    {displayAddressSettings.showAddressShort ? (
                        <div className='addressFromCollapsed addressBar mb-2' onClick={handleToggleFromAddress}>
                            <div className='custom-first-input'><span className='custom-pad'>From </span><span><button className='editShowAddressShort' onClick={handleToggleFromAddress}><i className="fa fa-fw fa fa-edit editIcon custom-edit-btn"></i></button></span></div> <div className='overFlowEllipsis'>{order.fName} {order.fCompany} {order.fAdd1}</div>
                            <div className=''></div>
                        </div>
                    ) : (
                        <div className='showAddress mb-2'>
                            <div className='displayFlex addressBar custom-pad custom-btn-bag'>From <button onClick={handleToggleFromAddress}><i className="fa fa-fw fa fa-edit editIcon custom-edit-btn"></i></button></div>
                            <div className='row mt-3'>
                                <div className='col-7'>
                                    <input
                                        type='checkbox'
                                        checked={order?.senderResidential}
                                        name="senderResidential"
                                        onChange={(e) => handleReturnAddressChecked(e, "senderResidential")}
                                    /> Residential
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>Name</div>
                                <div className='col-12'>
                                    <input type='text' className='fNameInput form-control' value={order.fName} onChange={(e) => updateUserInput(e, "fName")} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>Company</div>
                                <div className='col-12'>
                                    <input type='text' className='fCompanyInput form-control' value={order.fCompany} onChange={(e) => updateUserInput(e, "fCompany")} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>Address 1</div>
                                <div className='col-12'>
                                    <input
                                        type='text'
                                        className='fAdd1Input form-control'
                                        value={order.fAdd1}
                                        onChange={(e) => updateUserInput(e, "fAdd1")}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className=''>Address 2</div>
                                    <input
                                        type='text'
                                        className='fAdd2Input form-control'
                                        value={order.fAdd2}
                                        onChange={(e) => updateUserInput(e, "fAdd2")}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className=''>Address 3</div>
                                    <div className=''>
                                        <input
                                            type='text'
                                            className='fAdd3Input form-control'
                                            value={order.fAdd3}
                                            onChange={(e) => updateUserInput(e, "fAdd3")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>Country</div>
                                <div className='col-12'>
                                    <select
                                        className="form-select1 custom-drp-down form-control"
                                        onChange={(e) => {
                                            updateUserInput(e, "senderCountryID");
                                        }}
                                        value={order.senderCountryID}
                                    >
                                        {countries?.map((country, countryIndex) => {
                                            return (
                                                <option
                                                    value={country.id}
                                                    key={country.id}
                                                >
                                                    {country.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {/* <input type='text' value={order.sCountry} onChange={(e) => updateUserInput(e, "sTel")} /> */}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>City</div>
                                <div className='col-12'>
                                    <input className='form-control' type='text' id="fCityInput" value={order.fCity} onChange={(e) => updateUserInput(e, "fCity")} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>State</div>
                                <div className='col-12'>
                                    {order.senderCountryID == 218 ? (
                                        <select
                                            className='custom-drp-down form-control'
                                            value={order.fState}
                                            onChange={(e) => {
                                                updateUserInput(e, "fState");
                                            }}
                                        >
                                            <option value="">Select</option>
                                            {USSates &&
                                                USSates.length > 0 &&
                                                USSates.map((state, stateIndex) => (
                                                    <option
                                                        value={state.StateCode}
                                                        key={state.StateCode}
                                                    >
                                                        {state.StateCode} {state.StateName}
                                                    </option>
                                                ))}
                                        </select>
                                    ) : (
                                        <input className='form-control' type='text' value={order.fState} onChange={(e) => updateUserInput(e, "fState")} />
                                    )}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>Zip</div>
                                <div className='col-12'>
                                    <input className='form-control' id="fPostcodeInput" type='text' value={order.fPostcode} onChange={(e) => updateUserInput(e, "fPostcode")}
                                        onBlur={(e) => fetchAddress(e.target.value, 'sender')}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>Phone</div>
                                <div className='col-12'>
                                    <Input
                                        id="fTelInput"
                                        value={order.fTel}
                                        name="fTel"
                                        onChange={(e) =>
                                            updateUserInput({ target: { value: e } }, "fTel")
                                        }
                                        className={`form-control`}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>Email</div>
                                <div className='col-12'>
                                    <input id="fEmailInput" className='form-control' type='text' value={order.fEmail} onChange={(e) => updateUserInput(e, "fEmail")} />
                                </div>
                            </div>

                        </div>
                    )}

                    <div className='returnTo'>
                        <div className='addressBar mb-2'>
                            <div className='custom-pad'>Return To</div>
                            <div className='custom-btn-bag'><button className='returnToBtn' onClick={handleEditAddressTo}><i className="fa fa-fw fa fa-edit editIcon custom-edit-btn"></i></button></div>
                        </div>
                        <div className='showAddress mt-3'>
                            {displayAddressSettings.editReturnAddress ? (
                                <>
                                    <div>{order.returnname}</div>
                                    <div>{order.returnaddressline1}</div>
                                    <div>{order.returncity} {order.returnstate} {order.returnphone}</div>
                                </>
                            ) : (
                                <>
                                    <input checked={order.isReturnAddress} className='isReturnAddressInput' type='checkbox' onChange={(e) => handleReturnAddressChecked(e, "isReturnAddress")} /> Use return to address
                                    <div className='row'>
                                        <div className='col-12'>Name</div>
                                        <div className='col-12'>
                                            <input className='form-control' id="returnnameInput" type='text' value={order.returnname} onChange={(e) => updateUserInput(e, "returnname")} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Company</div>
                                        <div className='col-12'>
                                            <input className='form-control' id="returncompanyInput" type='text' value={order.returncompany} onChange={(e) => updateUserInput(e, "returncompany")} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Email</div>
                                        <div className='col-12'>
                                            <input className='form-control' id="returnemailInput" type='text' value={order.returnemail} onChange={(e) => updateUserInput(e, "returnemail")} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Address 1</div>
                                        <div className='col-12'>
                                            <input
                                                className='form-control'
                                                type='text'
                                                id="returnaddressline1Input"
                                                value={order.returnaddressline1}
                                                onChange={(e) => updateUserInput(e, "returnaddressline1")}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className=''>Address 2</div>
                                            <input
                                                className='form-control'
                                                type='text'
                                                id="returnaddressline2Input"
                                                value={order.returnaddressline2}
                                                onChange={(e) => updateUserInput(e, "returnaddressline2")}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className=''>Address 3</div>
                                            <input
                                                className='form-control'
                                                type='text'
                                                value={order.returnaddressline3}
                                                id="returnaddressline3Input"
                                                onChange={(e) => updateUserInput(e, "returnaddressline3")}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Country</div>
                                        <div className='col-12'>
                                            <select
                                                className={`form-select1 custom-drp-down form-control`}
                                                onChange={(e) => {
                                                    updateUserInput(e, "retrunCountryID");
                                                }}
                                                value={order.retrunCountryID}
                                                id="returnCountrySelectInput"
                                            >
                                                <option value={0}>Select Country</option>
                                                {countries?.map((country) => {
                                                    return (
                                                        <option
                                                            value={country.id}
                                                            key={country.id}
                                                        >
                                                            {country.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row custom-zip'>
                                        <div className='col-12'>
                                            <div className=''>Zip</div>
                                            <input
                                                className='returnzipInput form-control'
                                                type='text' value={order.returnzip}
                                                onChange={(e) => updateUserInput(e, "returnzip")}
                                                onBlur={(e) => fetchAddress(e.target.value, 'returnAddress')}
                                            />
                                        </div>
                                    </div>
                                    <div className='row custom-zip'>
                                        <div className='col-12'>
                                            <div className=''>State</div>
                                            {order.retrunCountryID == 218 ? (
                                                <select
                                                    value={order.returnstate}
                                                    onChange={(e) => {
                                                        updateUserInput(e, "returnstate");
                                                    }}
                                                    className="returnstateInput form-control"
                                                >
                                                    <option value="">Select</option>
                                                    {USSates &&
                                                        USSates.length > 0 &&
                                                        USSates.map((state, index) => (
                                                            <option
                                                                value={state.StateCode}
                                                                key={state.StateCode}
                                                            >
                                                                {state.StateCode} {state.StateName}
                                                            </option>
                                                        ))}
                                                </select>
                                            ) : (
                                                <input className='form-control' type='text' value={order.returnstate} onChange={(e) => updateUserInput(e, "returnstate")} />
                                            )}
                                        </div>
                                    </div>

                                    <div className='row custom-zip'>
                                        <div className='col-12'>
                                            <div className=''>City</div>
                                            <input className='form-control cityInput' type='text' value={order.returncity} onChange={(e) => updateUserInput(e, "returncity")} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Phone</div>
                                        <div className='col-12'>
                                            <Input
                                                value={order.returnphone}
                                                id="returnphoneInput"
                                                name="returnphone"
                                                onChange={(e) =>
                                                    updateUserInput({ target: { value: e } }, "returnphone")
                                                }
                                                className={`form-control`}
                                            />
                                        </div>
                                    </div>
                                    <button id="saveReturnAddress" className='btn cus-seconday-bg-btn custom-zip' onClick={() => handleAddressSave('returnAddress')}>Save Address Changes</button>
                                </>
                            )}
                        </div>
                    </div>

                    <div className='returnTo mb-2'>
                        <div className='addressBar'>
                            <div className='custom-pad'>To</div>
                            <div className='custom-btn-bag'><button className='toAddressBtn' onClick={handleEditReturnTo}><i className="fa fa-fw fa fa-edit editIcon custom-edit-btn"></i></button></div>
                        </div>
                        <div className='showAddress mt-3'>
                            {displayAddressSettings.editToAddress ? (
                                <>
                                    <div>{order.sName}</div>
                                    <div>{order.sAdd1}</div>
                                    <div>{order.sCity} {order.sState} {order.sPostcode}</div>
                                </>
                            ) : (
                                <>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <input
                                                type='checkbox'
                                                checked={order.receiverResidential}
                                                onChange={(e) => handleReturnAddressChecked(e, "receiverResidential")}
                                                name="receiverResidential"
                                            /> Residential
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Name</div>
                                        <div className='col-12'>
                                            <input
                                                value={order.sName}
                                                className="form-control"
                                                onChange={(e) => updateUserInput(e, "sName")}
                                                type='text'
                                                id="sNameInput"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Company</div>
                                        <div className='col-12'>
                                            <input
                                                className="form-control"
                                                type={'text'}
                                                onChange={(e) => updateUserInput(e, "sCompany")}
                                                value={order.sCompany}
                                                id="sCompanyInput"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Email</div>
                                        <div className='col-12'>
                                            <input
                                                className="form-control"
                                                type={'text'}
                                                onChange={(e) => updateUserInput(e, "sEmail")}
                                                value={order.sEmail}
                                                id="sEmailInput"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Address 1</div>
                                        <div className='col-12'>
                                            <input
                                                type='text'
                                                value={order.sAdd1}
                                                onChange={(e) => {
                                                    updateUserInput(e, "sAdd1");
                                                }}
                                                id="sAdd1Input"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className=''>Address 2</div>
                                            <input
                                                type='text'
                                                value={order.sAdd2}
                                                onChange={(e) => {
                                                    updateUserInput(e, "sAdd2");
                                                }}
                                                id="sAdd2Input"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className=''>Address 3</div>
                                            <input
                                                type='text'
                                                value={order.sAdd3}
                                                onChange={(e) => {
                                                    updateUserInput(e, "sAdd3");
                                                }}
                                                id="sAdd3Input"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>Country</div>
                                        <div className='col-12'>
                                            <select
                                                className={`form-select1 form-control`}
                                                onChange={(e) => {
                                                    updateUserInput(e, "receiverCountryID");
                                                }}
                                                value={order.receiverCountryID}
                                                id="receiverCountryIDInput"
                                            >
                                                {countries?.map((country) => {
                                                    return (
                                                        <option
                                                            value={country.id}
                                                            key={country.id}
                                                        >
                                                            {country.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className=''>Zip</div>
                                            <input
                                                type='text'
                                                value={order.sPostcode}
                                                onChange={(e) => {
                                                    updateUserInput(e, "sPostcode");
                                                }}
                                                onBlur={(e) => fetchAddress(e.target.value, 'receiver')}
                                                className="form-control"
                                                id="sPostcodeInput"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className=''>State</div>
                                            {order.receiverCountryID == 218 ? (
                                                <select
                                                    value={order.sState}
                                                    onChange={(e) => {
                                                        updateUserInput(e, "sState");
                                                    }}
                                                    className="form-control"
                                                    id="sStateInput"
                                                >
                                                    <option value="">Select</option>
                                                    {USSates &&
                                                        USSates.length > 0 &&
                                                        USSates.map((state, index) => (
                                                            <option
                                                                value={state.StateCode}
                                                                key={state.StateCode}
                                                            >
                                                                {state.StateCode} {state.StateName}
                                                            </option>
                                                        ))}
                                                </select>
                                            ) : (
                                                <input
                                                    className={`form-control sState`}
                                                    type='text'
                                                    value={order.sState}
                                                    onChange={(e) => {
                                                        updateUserInput(e, "sState");
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className=''>City</div>
                                            <input
                                                className={`form-control`}
                                                type='text'
                                                value={order.sCity}
                                                id="sCityInput"
                                                onChange={(e) => {
                                                    updateUserInput(e, "sCity");
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12'>Phone</div>
                                        <div className='col-12'>
                                            <Input
                                                value={order.sTel}
                                                name="sTel"
                                                onChange={(e) =>
                                                    updateUserInput({ target: { value: e } }, "sTel")
                                                }
                                                id="sTelInput"
                                                className={`form-control`}
                                            />
                                        </div>
                                    </div>
                                    <button className='btn  cus-seconday-bg-btn' onClick={() => handleAddressSave('addressTo')}>Save Address Changes</button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='returnTo servicesWrapper'>
                        <div className='row'>
                            <div className='col-12'>
                                {services?.length > 0 && (
                                    <select
                                        value={order.carrierCompanyId}
                                        onChange={(e) => {
                                            updateUserInput(e, "carrierCompanyId");

                                        }}
                                        id="carrierCompanyIDSelect"
                                        className='form-control'
                                    >
                                        <option value={0}>Select Carrier...</option>
                                        {services?.map((carrier) => (
                                            <option key={carrier?.carrierCompanyId} value={carrier?.carrierCompanyId}>{carrier?.carrierCompanyName}</option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className='col-12'>
                                <select
                                    value={order.serviceID}
                                    onChange={(e) => {
                                        updateUserInput(e, "serviceID");
                                    }}
                                    id="serviceIDSelect"
                                    className='form-control'
                                >
                                    <option value={0}>Select Service...</option>
                                    {order.carrierCompanyId && selectedCarrier.length > 0 && selectedCarrier[0]?.services?.map((service) => (
                                        <option key={service?.id} value={service?.id}>{service?.serviceName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-12'>
                                <select
                                    value={order.packageId}
                                    onChange={(e) => {
                                        updateUserInput(e, "packageId");
                                    }}
                                    disabled={!order.serviceID}
                                    className='form-control'
                                    id="packageIdSelect"
                                >
                                    <option value={0}>Select package Type...</option>
                                    {packages && packages.map((item, index) => (
                                        <option key={item?.id} value={item.id}>{item.packageName}</option>
                                    ))}
                                </select>
                            </div>

                            <Parcels selectedPackage={selectedPackage} setOrder={setOrder} order={order} createdBy={createdBy} />
                        </div>
                    </div>
                    <div className='servicesWrapper'>
                        <div className='row'>
                            <div className='col-12 custom-input-padding'>
                                <div className='mobMb-10'>Content Description</div>
                                <div>
                                    <input
                                        className='form-control contentDescriptionInput'
                                        type='text'
                                        value={order.contentDescription}
                                        onChange={(e) => {
                                            updateUserInput(e, "contentDescription");
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-12 custom-input-padding'>
                                <div className='mobMb-10'>Shipment Date</div>
                                <div>
                                    {order.shipmentDate &&
                                        <DatePicker
                                            dateFormat="MM-dd-yyyy"
                                            className={dateError ? 'form-control mb-0 dateField error' : 'form-control dateField'}
                                            selected={new Date(order.shipmentDate)}
                                            onChange={(date) => updateUserInput({ target: { value: date } }, "shipmentDate")}
                                        />
                                    }

                                    <p className='error dateError mt-0 mb-0'>{dateError !== "" && dateError}</p>
                                </div>
                            </div>
                            <div className='col-12 custom-input-padding'>
                                <div className='mobMb-10'>Order Number</div>
                                <div>
                                    <input
                                        value={order.ordernumber}
                                        className="form-control ordernumberInput"
                                        onChange={(e) => {
                                            updateUserInput(e, "ordernumber");
                                        }}
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className='col-12 custom-input-padding'>
                                <div className='mobMb-10'>Shipper Reference ({order.shippingreference?.length}/70)</div>
                                <div>
                                    <input
                                        value={order.shippingreference}
                                        className="form-control shippingreference"
                                        onChange={(e) => {
                                            updateUserInput(e, "shippingreference");
                                        }}
                                        type="text"
                                        maxLength={70}
                                    />
                                </div>
                            </div>
                            {!checkPermission(orderManagement, batchModule, permissionPrintLabel, featureSubmodulePermission, employeePermissions) && (
                                <div className='col-12 inlineFlexPrintCost'>
                                    <input
                                        type="checkbox"
                                        checked={
                                            order?.printLabelCost
                                        }
                                        className="mr-1 printLabelCost"
                                        name="printLabelCost"
                                        onChange={(e) => handleReturnAddressChecked(e, "printLabelCost")}
                                        id="printLabelCost"
                                    />
                                    <label for="printLabelCost" className="">Print Cost</label>
                                </div>
                            )}
                            <div className='col-12 actions'>
                                {checkPermission(orderManagement, batchModule, permssionEdit, featureSubmodulePermission, employeePermissions) && (
                                    <button id='saveOrderBtn' className='btn cus-seconday-bg-btn' onClick={() => handleAddressSave('completeForm')} disabled={isFormEdited || dateError}>Save Changes</button>)}
                                {checkPermission(orderManagement, batchModule, permssionDelete, featureSubmodulePermission, employeePermissions) && (
                                    <button id='deleteOrderBtn' className='btn cus-seconday-bg-btn' onClick={handleDeleteOrder}>Delete Order</button>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* } */}
            <AlertDialogModal
                modalClass={'modalDeleteWrapper'}
                modalShow={modalShow}
                handleCancelDeleteAlert={handleDeleteOrder}
                handleYesDeleteAlert={handleYesDeleteAlert}
            />
        </div >
    );
}

export default UpdateAddressWidget;