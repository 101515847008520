export const incomingConstant = {
    GET_YEAR_LIST_PENDING: 'GET_YEAR_LIST_PENDING',
    GET_YEAR_LIST_SUCCESS: 'GET_YEAR_LIST_SUCCESS',
    GET_YEAR_LIST_FAILED: 'GET_YEAR_LIST_FAILED',
    GET_DAILY_PACKAGE_COUNT_PENDING: 'GET_DAILY_PACKAGE_COUNT_PENDING',
    GET_DAILY_PACKAGE_COUNT_SUCCESS: 'GET_DAILY_PACKAGE_COUNT_SUCCESS',
    GET_DAILY_PACKAGE_COUNT_FAILED: 'GET_DAILY_PACKAGE_COUNT_FAILED',
    GET_PRODUCT_DETAILS_PENDING: 'GET_PRODUCT_DETAILS_PENDING',
    GET_FILTER_DAILY_PRODUCT_COUNT_PENDING: 'GET_FILTER_DAILY_PRODUCT_COUNT_PENDING',
    GET_FILTER_DAILY_PRODUCT_COUNT_SUCCESS: 'GET_FILTER_DAILY_PRODUCT_COUNT_SUCCESS',
    GET_FILTER_DAILY_PRODUCT_COUNT_FAILED: 'GET_FILTER_DAILY_PRODUCT_COUNT_FAILED',
    GET_PRODUCT_DETAILS_SUCCESS: 'GET_PRODUCT_DETAILS_SUCCESS',
    GET_PRODUCT_DETAILS_FAILED: 'GET_PRODUCT_DETAILS_FAILED',
    GET_DAILY_PRODUCT_COUNT_PENDING: "GET_DAILY_PRODUCT_COUNT_PENDING",
    GET_DAILY_PRODUCT_COUNT_SUCCESS: 'GET_DAILY_PRODUCT_COUNT_SUCCESS',
    GET_DAILY_PRODUCT_COUNT_FAILED: 'GET_DAILY_PRODUCT_COUNT_FAILED',
    GET_PRODUCT_PROCESS_TYPE_LIST_SUCCESS: 'GET_PRODUCT_PROCESS_TYPE_LIST_SUCCESS',
    GET_PRODUCT_STATUS_LIST_SUCCESS: 'GET_PRODUCT_STATUS_LIST_SUCCESS',
    GET_PRODUCT_CONDITION_LIST_SUCCESS: 'GET_PRODUCT_CONDITION_LIST_SUCCESS',
    GET_PRODUCT_WAREHOUSE_LIST_SUCCESS: 'GET_PRODUCT_WAREHOUSE_LIST_SUCCESS',
    GET_FILTER_DAILY_PACKAGE_COUNT_PENDING: 'GET_FILTER_DAILY_PACKAGE_COUNT_PENDING',
    GET_FILTER_DAILY_PACKAGE_COUNT_SUCCESS: 'GET_FILTER_DAILY_PACKAGE_COUNT_SUCCESS',
    GET_FILTER_DAILY_PACKAGE_COUNT_FAILED: 'GET_FILTER_DAILY_PACKAGE_COUNT_FAILED',
    GET_SOURCELIST_SUCCESS: 'GET_SOURCELIST_SUCCESS',
    GET_PRODUCT_YEAR_LIST_PENDING: "GET_PRODUCT_YEAR_LIST_PENDING",
    GET_PRODUCT_YEAR_LIST_SUCCESS: "GET_PRODUCT_YEAR_LIST_SUCCESS",
    GET_PRODUCT_YEAR_LIST_FAILED: "GET_PRODUCT_YEAR_LIST_FAILED",
    GET_PACKAGE_TYPE_LIST_SUCCESS: 'GET_PACKAGE_TYPE_LIST_SUCCESS',
    GET_PACKAGE_DETAILS_PENDING: 'GET_PACKAGE_DETAILS_PENDING',
    GET_PACKAGE_DETAILS_FAILED: 'GET_PACKAGE_DETAILS_FAILED',
    GET_PACKAGE_DETAILS_SUCCESS: 'GET_PACKAGE_DETAILS_SUCCESS',
    GET_ALL_CARRIER_LIST_PENDING: 'GET_ALL_CARRIER_LIST_PENDING',
    GET_ALL_CARRIER_LIST_FAILED: 'GET_ALL_CARRIER_LIST_FAILED',
    GET_ALL_CARRIER_LIST_SUCCESS: 'GET_ALL_CARRIER_LIST_SUCCESS',
    GET_ALL_STATUS_LIST_SUCCESS: 'GET_ALL_STATUS_LIST_SUCCESS',
    INCOMING_SELECTED_YEAR: 'INCOMING_SELECTED_YEAR',
    PRODUCT_SELECTED_YEAR: 'PRODUCT_SELECTED_YEAR',
    PRODUCT_BACK_DETAILS: 'PRODUCT_BACK_DETAILS',
    PACKAGE_BACK_DETAILS: 'PACKAGE_BACK_DETAILS',
    PACKAGE_YEAR_BACK_DETAILS: 'PACKAGE_YEAR_BACK_DETAILS',
    PRODUCT_YEAR_BACK_DETAILS: 'PRODUCT_YEAR_BACK_DETAILS',
    RESET_PRODUCT_DATA: 'RESET_PRODUCT_DATA',
}